export default class Authenticator {
	private _tokenType: string;
	private _accessToken: string;

	public constructor()
	{
		this._accessToken = localStorage.getItem("auth:accessToken") ?? "";
		this._tokenType = localStorage.getItem("auth:tokenType") ?? "";
	}

	public UseTokens(tokenType: string, accessToken: string): void {
		localStorage.setItem("auth:tokenType", tokenType);
		localStorage.setItem("auth:accessToken", accessToken);

		this._tokenType = tokenType;
		this._accessToken = accessToken;
	}

	public Authenticate(request: XMLHttpRequest): void {
		if(this._tokenType && this._accessToken) {
			request.setRequestHeader("Authorization", this._tokenType + " " + this._accessToken);
		}
	}
}
