const defaultStrings = {
	welcome: "Welcome !",
	Unauthorized: "Unauthorized",
	INVALID_CREDENTIALS: "Invalid login and/or password",
	MISSING_GUESTS: "Missing guests",
	NO_PLANNING: "No planning for this user at the moment",
	INVALID_PASSWORD: "Invalid old password",
	WEAK_PASSWORD: "Password is too weak",
	EMPTY_FIELDS: "Some fields are required",
	INVALID_SELECTED_MEAL_ITEMS: "Missing elements for some meals",
	GENERIC_HTTP_ERROR: "No data available",
	INCORRECT_ENTRY: "Incorrect entry",
	login: "Login",
	welcome_title: "Welcome on My Eat Well",
	welcome_text1: "The planner to organise your menu weeks according to your preferences.",
	welcome_text2: "For you and your family. Suitable for all diets.",
	welcome_text3: "Plan over several weeks, add to your shopping list and you're done! Manage the food preferences of your family as well as your regular guests.",
	login_connection: "Connection",
	login_remember_me: "Remember me",
	login_error_invalid_credentials: "Invalid login and/or password",
	login_validation_error: "Login and/or password are empty",
	login_login: "Login",
	login_password: "Password",
	logout: "Logout",
	forgot_password: "Forgot password ?",
	forgot_validation_error: "Email field is empty",
	forgot_password_message: "Enter the email address associated with your account",
	forgot_password_email_message: "An email will be sent to you with a link to retrieve your password, if an account exists with the email entered",
	back_to_login: "Back to login page",
	reset_password: "Reset password",
	password_change: "Password change",
	email: "Email",
	password: "Password",
	new_password: "New password",
	confirm_password: "Confirm password",
	error_reset_password: "Passwords do not match",
	need_help: "Need help ?",
	submit: "Submit",
	CGU: "CGU",
	online_service: "Our online services",
	legal_information: "Legal Information",
	cookies_management: "Cookies management",
	data_protection: "Protection des données",
	next: "Next",
	plannification: "Planner",
	menus: "Menus",
	foyer: "Family",
	favoris: "Bookmark",
	courses: "Shopping List",
	account_parameters: "Parameters",
	week_plannification: "Plan your weeks",
	organize_week: "Organise your menu weeks according to your preferences",
	choose_your_start_date: "Choose the start date to begin your planning.",
	week: "Week",
	week_end: "week-end",
	your_weeks: "Your weeks",
	person: "person",
	persons: "persons",
	family_member: "Family",
	regular_member: "Regular member",
	guests: "Guests",
	guests2: "Guests",
	monday: "Monday",
	thuesday: "Thuesday",
	wednesday: "Wednesday",
	thursday: "Thursday",
	friday: "Friday",
	saturday: "Saturday",
	sunday: "Sunday",
	Lunch: "Lunch",
	Dinner: "Dinner",
	starter: "Starter",
	main: "Main",
	cheese: "Cheese",
	dessert: "Dessert",
	bread: "Bread",
	accompaniment: "Meal accompaniment",
	plannification_button_1: "Planning for the next week",
	plannification_button_2: "Use this plan for the ##NB_WEEKS## weeks",
	plannification_button_3: "Generate one week",
	plannification_button_4: "See the menus",
	date_unavailable: "Start date unavailable, please use the planner to choose one",
	menu_unavailable: "No menu available for this week",
	menu_informations: "Informations",
	delete: "Delete",
	activate: "Activate",
	validate_week: "Validate the week",
	see_previous_week: "See previous week",
	see_next_week: "See next week",
	from: "From",
	to: "to",
	refresh: "Refresh",
	FamilyMember: "Family member",
	RegularGuest: "Regular guest",
	add_person_household: "Add a person to the household",
	add_person_guest: "Add a guest",
	view_eating_habits: "See the eating habits",
	Man: "Man",
	Woman: "Woman",
	year_old: "yeas old",
	meters: "meters",
	kg: "lb",
	physical_activity: "Physical activity",
	Low: "Low",
	Medium: "Medium",
	High: "High",
	back_to_household: "Back to household",
	diabete_type: "Type of diabetes",
	allergens_title: "Allergens and food intolerances",
	foods_exclusions: "Food exclusions",
	favourite_recipes: "Favourite recipes",
	validated_weeks: "Validated weeks",
	add_on: "Add on",
	born_on: "Born on",
	menu_type_week: "Typical weekly menu",
	menu_type_weekend: "Typical weekend menu",
	name: "Name",
	title_change_password: "Change your password",
	old_password: "Actual password",
	edit_password: "Edit password",
	confirm_password_change: "Your password has been changed",
	edit_member: "Edit a member",
	attach: "Attach",
	add_member: "Add a member",
	profile_photo: "Profile photo",
	validate: "Validate",
	message_send: "Your message has been sent.",
	first_name: "First name",
	last_name: "Last name",
	birth_date: "Birth date",
	weight: "Weight",
	height: "Height",
	pregnant: "Pregnant",
	breastfeeding: "Breastfeeding",
	yes: "Yes",
	no: "No",
	shopping_list: "Shopping list",
	shopping_list_unavailable: "List de course non disponible pour cette semaine",
	print: "Print",
	add_product: "Add a product",
	your_preferences: "Your preferences",
	no_bio: "No organic products",
	some_bio: "Some organic products",
	much_as_possible_bio: "As much as possible",
	meal_kits_available: "Meals available in kits or ready-made meals",
	planned_for: "Planned for",
	preparation: "Preparation",
	cooking: "Cooking",
	total_cost: "Total cost",
	difficulty: "Difficulty",
	see_bread_portions: "See the bread portions",
	recipe: "Recipe",
	portions: "Portions",
	nutritional_information: "Nutritional info",
	total_time: "Total time",
	cost: "Cost",
	note_recipe: "Note the recipe",
	add_note: "Add a note",
	ingredients: "Ingrédients",
	calories_apport: "Calorie per guest",
	nutritional_declaration: "Nutritional declaration per 100 g of the recipe",
	energy: "Energy",
	lipid: "Fat (g)",
	fatty_acid: "including saturated fatty acids (g)",
	glucid: "Glucids (g)",
	sugar: "including sugars (g)",
	fiber: "Dietary fibres (g)",
	protein: "Proteins (g)",
	salt: "Salt (mg)",
};

export default defaultStrings;
