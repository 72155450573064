import * as React from "react";
import useStrings from "src/localization/Localization";
import { useHistory } from "react-router-dom";

import ButtonCustom from "src/components/bases/ButtonCustom";
import { useCheckMobileScreen } from "src/helpers/fonctions";

export interface HomePageProps { }

const HomePage: React.FunctionComponent<HomePageProps> = (props) => {
    const _strings = useStrings();
    const history = useHistory();
    const mobileScreen = useCheckMobileScreen();
    const sreenHeight = window.innerHeight;

    function render(): JSX.Element {

        return (
            <div style={{ margin: "10px 5%" }}>
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: "100%" }}>
                        <div style={{ textAlign: 'left', width: "50%" }}>
                            <img src="images/logo-menu-top@3x.jpg"
                                srcSet="images/logo-menu-top@2x.jpg 2x,images/logo-menu-top@3x.jpg 3x"
                                className="logo-menu-top" />
                        </div>
                        <div style={{ textAlign: 'right', width: "50%" }}>
                            <ButtonCustom style={{ width: "auto", backgroundColor: "#fd694d", color: "white", textTransform: "initial" }} label={_strings.login} onClick={() => { history.push('/login') }}></ButtonCustom>
                        </div>
                    </div>
                    <span className={"dot_green"} style={{ top: (sreenHeight-570) + 'px'  }}>
                    </span>
                    <span className={"dot_orange"}></span>
                    <div style={{ display: 'flex', flexDirection: (mobileScreen ? 'column' : 'row') }}>
                        <div style={{ alignSelf: 'center', width: ( mobileScreen ? 'inherit' : "45%") }}>
                            <div style={{ fontSize: "32px", fontWeight: 800 }}>
                                <p>{_strings.welcome_title}</p>
                            </div>
                            <div style={{ fontSize: "20px", textAlign: 'justify' }}>
                                <p>{_strings.welcome_text1}</p>
                                <p>{_strings.welcome_text2}</p>
                                <p>{_strings.welcome_text3}</p>
                            </div>
                            <div style={{ display: 'flex'}}>
                                <ButtonCustom style={{ width: "auto", backgroundColor: "#fd694d", color: "white", textTransform: "initial" }} label={_strings.login} onClick={() => { history.push('/login') }}></ButtonCustom>
								<ButtonCustom style={{ width: "auto", backgroundColor: "#fd694d", color: "white", textTransform: "initial" }} label={'Contact'} onClick={() => { history.push('/contact') }}></ButtonCustom>
							</div>

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-end" }}>

								<a href="https://play.google.com/store/apps/details?id=com.my_eatwell&gl=FR" target={"_blank"}><img src="images/home/google-play.svg" /></a>
							</div>


                        </div>
                        <div style={{ marginLeft: "3%" }}>
                            <div>
                                <span><img src="images/home/homePage.png" /></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return render();
};

export default HomePage;
