import * as React from "react";
import Footer from "src/components/bases/Footer";
import MenuButton from "src/components/bases/MenuButton";

const CookiesPage: React.FunctionComponent = (props) => {

    return (
        <div>
            <MenuButton></MenuButton>

            <section id="cookies" className="marginPages">
                <header><h3>Protection des données et Cookies</h3></header>
                <br />
                <div className="container-fluid">
                    <div>
                        Cette politique est publiée dans le respect du Règlement général de protection des données (RGPD), à la Directive ePrivacy, ainsi qu'à la loi n° 78-17 du 6 janvier 1978 dite Informatique et Libertés.
                    </div>
                    <br />
                    <div>
                        <header><h4>Politique de protection des données à caractère personnel et des données de santé</h4></header>
                        <div>
                            Soucieux de votre santé, l’équipe My GoodLife sécurise au mieux vos données à caractère personnel et vos données de santé. My GoodLife s’engage à établir une relation de confiance basée sur la sécurité de nos systèmes d’informations, la protection des données à caractère personnel et une collecte de vos données personnelles limitées au strict nécessaire (Minimisation des données).
                        </div>
                    </div>
                    <br />
                    <div>
                        <header><h4>Bases légales des traitements de données personnelles</h4></header>
                        <div>
                            Les traitements mis en œuvre par le formulaire de contact est basé sur l’intérêt légitime.
                            <br />
                            La création d’un compte client et les services de l’application liés au planning nutritionnel ont pour base légale le contrat.
                        </div>
                    </div>
                    <br />
                    <div>
                        <header><h4>Durée de conservation des données</h4></header>
                        <div>
                            La conservation des informations de compte est de 6 mois après la suppression de celui-ci.
                        </div>
                    </div>
                    <br />
                    <div>
                        <header><h4>Formulaire de contact</h4></header>
                        <div>
                            Pour permettre une étude attentionnée de vos demandes, celles-ci font l’objet d’un enregistrement et d’un traitement informatique. Par respect du principe de minimisation seul votre courriel est nécessaire. <br />
                            Seules les personnes dûment habilitées par My GoodLife ont accès aux informations confiées lors de la prise de contact. La durée de conservation des informations saisies n’excède pas 6 mois.
                        </div>
                    </div>
                    <br />
                    <div>
                        <header><h4>Droit des personnes concernées</h4></header>
                        <div>
                            Pour toute information ou exercice de vos droits concernant la collecte, le traitement ou l’utilisation de vos données, vous pouvez contacter le délégué à la protection des données (DPO) : <br />
                            <ul>
                                <li>
                                    <span style={{ fontWeight: "bold" }}>Par courrier à l’adresse suivante : </span>
                                    <br />
                                    <div>
                                        My GoodLife <br />
                                        A l’attention du délégué à la protection des données (DPO) <br />
                                        Quai 8.2 Bâtiment E1 <br />
                                        31, rue d'Armagnac <br />
                                        33800 BORDEAUX
                                    </div>
                                </li>
                                <li>
                                    <span style={{ fontWeight: "bold" }}>Par mail : <a href="mailto:dpo@my-goodlife.fr">dpo@my-goodlife.fr</a> </span>
                                    <br />
                                    <div>
                                        La réglementation en vigueur sur l’exercice des droits des personnes inclus vos droits d’accès, de rectification, de suppression, d’opposition, limitation et portabilité le cas échéant. <br />
                                        Afin de garantir la confidentialité et la sécurité de vos données, la société My GoodLife pourra procéder à une vérification de votre identité.
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <br />
                    <div>
                        <header><h4>Confidentialité du Mot de passe</h4></header>
                        <div>
                            Vous êtes responsable de la confidentialité du mot de passe que vous avez choisi pour accéder à votre compte sur notre Application. <br />
                            Vous vous engagez à conserver ce mot de passe secret et à ne le communiquer à personne.
                        </div>
                    </div>
                    <br />
                    <div>
                        <header><h4>Cookies</h4></header>
                        <div>
                            <span style={{ fontStyle: "italic" }}>Qu'est-ce qu'un "cookie" ?</span> <br />
                            <div>
                                Un "cookie" est une suite d'informations, généralement de petite taille et identifié par un nom, qui peut être transmis à votre navigateur par un site web sur lequel vous vous connectez. Votre navigateur web le conservera pendant une certaine durée, et le renverra au serveur web chaque fois que vous vous y reconnecterez. Les cookies ont de multiples usages : ils peuvent servir à mémoriser votre identifiant client auprès d'un site marchand, le contenu courant de votre panier d'achat, un identifiant permettant de tracer votre navigation pour des finalités statistiques ou publicitaires, etc.
                            </div>
                        </div>
                        <br />
                        <div>
                            <span>Cookies nécessaires au fonctionnement du site internet</span> <br />
                            <div>
                                <table style={{ width: "50%" }}>
                                    <thead>
                                        <tr>
                                            <th>Cookies</th>
                                            <th>Finalité</th>
                                            <th>Durée de conservation</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>PHPSESSID</td>
                                            <td>Cookie de session</td>
                                        </tr>
                                        <tr>
                                            <td>language</td>
                                            <td>Rappel de la langue de l’utilisateur</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br />
                        <div>
                            <header><h4>Cookies pour le ciblage publicitaire</h4></header>
                            <div>
                                Actuellement, My Eat Well n’utilise pas de cookies de ciblage publicitaire.
                            </div>
                        </div>
                    </div>
                    <br />
                    <div>
                        <header><h4>Les destinataires des données</h4></header>
                        <div>
                            Les données peuvent être transférées à nos prestataires/sous-traitants (hébergement Microsoft Azure) qui sont soumis à nos règles. Ils interviennent dans la réalisation des services liés à My GoodLife.
                        </div>
                    </div>
                    <br />
                    <div>
                        <header><h4>Transfert de données Hors Union Européenne</h4></header>
                        <div>
                            Aucune de vos données ne sera transférée en dehors de la zone Européenne.
                        </div>
                    </div>
                    <br />
                    <div>
                        <header><h4>Les données à caractères personnelles des mineurs</h4></header>
                        <div>
                            Le service My GoodLife peut prendre en charge des mineurs, il appartient aux parents de décider si leur enfant est autorisé à utiliser le service My GoodLife.
                        </div>
                    </div>
                </div>
                <br /><br />
            </section>

            <Footer></Footer>
        </div>
    );
}

export default CookiesPage;