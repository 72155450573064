
// Page visibility changed

import { useEffect } from "react";
import { DeclareState } from "./State";

const hasDocument = typeof document !== 'undefined';
const vendorEvents = [
    {
        hidden: 'hidden',
        event: 'visibilitychange',
        state: 'visibilityState',
    },
    {
        hidden: 'webkitHidden',
        event: 'webkitvisibilitychange',
        state: 'webkitVisibilityState',
    },
    {
        hidden: 'mozHidden',
        event: 'mozvisibilitychange',
        state: 'mozVisibilityState',
    },
    {
        hidden: 'msHidden',
        event: 'msvisibilitychange',
        state: 'msVisibilityState',
    },
    {
        hidden: 'oHidden',
        event: 'ovisibilitychange',
        state: 'oVisibilityState',
    },
];

const visibility = (() => {
    if (hasDocument && Boolean(document.addEventListener)) {
		for (let i = 0; i < vendorEvents.length; i++) {
			const event = vendorEvents[i];
			if (event.hidden in document) {
				return event;
			}
		}
	}
    // otherwise it's not supported
    return null;
})();

const getHandlerArgs = () => {
    if (!visibility) {
		return true;
	}
    const { hidden } = visibility;
    return !document[hidden];
};

const usePageVisibility = () => {
	const initiallyVisible: boolean = getHandlerArgs();
	const _isVisible = DeclareState<boolean>(initiallyVisible);

    useEffect(() => {
        if (hasDocument && Boolean(document.addEventListener) && visibility) {
            const handler = () => {
				const currentlyVisible: boolean = getHandlerArgs();
				// console.log("PageVisibility=" + currentlyVisible);
				_isVisible.Update(currentlyVisible);
            };

			// console.log("PageVisibility subscribing");
            document.addEventListener(visibility.event, handler);

            return () => {
				// console.log("PageVisibility unsubscribing");
                document.removeEventListener(visibility.event, handler);
            };
		}

		return () => {};
    }, []);

    return _isVisible.content;
};

export default usePageVisibility;
