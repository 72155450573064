import { PlannificationActionTypes, PlannificationState, PlannificationStartDateState, PlannificationIsSaveState, Plannifications_StartDateActionTypes, Plannifications_SetSaveActionTypes, Plannifications_LoadingActionTypes, LOADING_ACTION } from "./types";
import { CATEGORY_RAPID, CATEGORY_ECO, CATEGORY_TAKE_AWAY, CATEGORY_GOURMET, STRUCTURE_STARTER, STRUCTURE_MAIN, STRUCTURE_CHEESE, STRUCTURE_DESSERT } from "./types";
import { SET_ENABLED_DAY, REMOVE_PLANNING_WEEK_STATE, ADD_GUESTS, ADD_PERSON_DAY, ADD_START_DATE, COPY_WEEK, SET_PLANNING_SAVE } from "./types";

import { Meal } from "src/api/dtos/menu/MenuProfile";

// const InitialState: PlannificationState = {
//     [0]: {
//         [0]: {
//             ["lunch"]: {
//                 rapid: false,
//                 eco: false,
//                 takeAway: false,
//                 gourmet: false,
//                 starter: false,
//                 main: false,
//                 cheese: false,
//                 dessert: false,
//             }
//         }
//     }
// };

export function PlannificationDaysReducers(state: PlannificationState /*= InitialState*/, action: PlannificationActionTypes): PlannificationState | null {

    if (action.type == REMOVE_PLANNING_WEEK_STATE) {
        return null;
    }

    let categoryState = {
        rapid: false,
        eco: false,
        takeAway: false,
        gourmet: false,
        starter: false,
        main: false,
        cheese: false,
        dessert: false,
        isEnabled: true,
        nbAdults: 0,
        nbChildren: 0,
        persons: {},
    }

    if (state && state[action.week] && state[action.week][action.day] && state[action.week][action.day][action.lunchType]) {
        categoryState = state[action.week][action.day][action.lunchType];
    }

    let available = {
        rapid: categoryState.rapid,
        eco: categoryState.eco,
        takeAway: categoryState.takeAway,
        gourmet: categoryState.gourmet,
        starter: categoryState.starter,
        main: categoryState.main,
        cheese: categoryState.cheese,
        dessert: categoryState.dessert,
        isEnabled: categoryState.isEnabled,
        nbAdults: categoryState.nbAdults,
        nbChildren: categoryState.nbChildren,
        persons: categoryState.persons,
    }

    const renderSwitch = () => {
        switch (action.type) {
            case (CATEGORY_RAPID):
                available.rapid = action.valueChange != undefined ? action.valueChange : !available.rapid;
                break;
            case CATEGORY_ECO:
                available.eco = action.valueChange != undefined ? action.valueChange : !available.eco;
                break;
            case CATEGORY_TAKE_AWAY:
                available.takeAway = action.valueChange != undefined ? action.valueChange : !available.takeAway;
                break;
            case CATEGORY_GOURMET:
                available.gourmet = action.valueChange != undefined ? action.valueChange : !available.gourmet;
                break;
            case STRUCTURE_STARTER:
                available.starter = action.valueChange != undefined ? action.valueChange : !available.starter;
                break;
            case STRUCTURE_MAIN:
                available.main = action.valueChange != undefined ? action.valueChange : !available.main;
                break;
            case STRUCTURE_CHEESE:
                available.cheese = action.valueChange != undefined ? action.valueChange : !available.cheese;
                break;
            case STRUCTURE_DESSERT:
                available.dessert = action.valueChange != undefined ? action.valueChange : !available.dessert;
                break;
            case ADD_PERSON_DAY:
                available.persons = (action.persons ? action.persons : available.persons);
                break;
            case ADD_GUESTS:
                available.nbAdults = (action.nbAdults ? action.nbAdults : available.nbAdults);
                available.nbChildren = (action.nbChildren ? action.nbChildren : available.nbChildren);
                break;
            case SET_ENABLED_DAY:
                available.isEnabled = action.valueChange != undefined ? action.valueChange : !available.isEnabled;
            // default:
            //     available.main = true;
            //     break
        }
    }

    let copyWeek: PlannificationState = { ...state };
    let copyTmp = (JSON.parse(JSON.stringify(copyWeek)));
    if (action.type == COPY_WEEK && action.iteration) {
        if (state && state[action.week]) {
            for (let i = 1; i <= action.iteration; i++) {
                copyWeek[action.week + i] = copyTmp[action.week];
                for (let j = 1; j <= 7; j++) {
                    copyWeek[action.week + i][j][Meal.Lunch].isEnabled = true;
                    copyWeek[action.week + i][j][Meal.Lunch].main = true;
                    copyWeek[action.week + i][j][Meal.Dinner].isEnabled = true;
                    copyWeek[action.week + i][j][Meal.Dinner].main = true;
                }
            }
        }
    }

    // return null;
    if (action.day != (undefined || null)) {
        renderSwitch();

        let w, d, l = {};
        if (state && state[action.week]) {
            w = state[action.week];
        }
        if (state && state[action.week] && state[action.week][action.day]) {
            d = state[action.week][action.day];
        }
        if (state && state[action.week] && state[action.week][action.day] && state[action.week][action.day][action.lunchType]) {
            l = state[action.week][action.day][action.lunchType];
        }
        // if (state && state[action.week] && state[action.week][action.day] && state[action.week][action.day][action.lunchType] && state[action.week][action.day][action.lunchType].persons) {
        //     p = state[action.week][action.day][action.lunchType].persons;
        // }
        return {
            ...state,
            [action.week]: {
                ...w,
                [action.day]: {
                    ...d,
                    [action.lunchType]: {
                        ...l,
                        rapid: available.rapid,
                        eco: available.eco,
                        takeAway: available.takeAway,
                        gourmet: available.gourmet,
                        starter: available.starter,
                        main: available.main,
                        cheese: available.cheese,
                        dessert: available.dessert,
                        isEnabled: available.isEnabled,
                        nbAdults: available.nbAdults,
                        nbChildren: available.nbChildren,
                        persons: available.persons,
                    }
                }
            }

        };

    } else {
        if (copyWeek) {
            return {
                ...copyWeek
            }
        }
        if (state == (undefined || null)) {
            return null;
        }
        return state;
    }

}

const InitialStateStartDate: PlannificationStartDateState = {
    start_date: null
};

export function PlannificationStartDateReducers(state: PlannificationStartDateState = InitialStateStartDate, action: Plannifications_StartDateActionTypes): PlannificationStartDateState | null {

    switch (action.type) {
        case REMOVE_PLANNING_WEEK_STATE:
            return {
                ...state,
                start_date: null,
            }
        case ADD_START_DATE:
            let start_date = (action.start_date ? action.start_date : state.start_date);
            return {
                ...state,
                start_date: start_date,
            }
        default:
            return state;
    }
}

const InitialStateIsSave: PlannificationIsSaveState = {
    is_save: false
};

export function PlannificationIsSaveReducers(state: PlannificationIsSaveState = InitialStateIsSave, action: Plannifications_SetSaveActionTypes): PlannificationIsSaveState {

    switch (action.type) {
        case REMOVE_PLANNING_WEEK_STATE:
            return {
                ...state,
                is_save: false,
            }
        case SET_PLANNING_SAVE:
            return {
                ...state,
                is_save: action.is_save,
            }
        default:
            return state;
    }
}

export function PlannificationLoadingReducers(state = false, action: Plannifications_LoadingActionTypes) {
    switch (action.type) {
        case LOADING_ACTION:
            return action.status;
        default:
            return state;
    }

}