import StaticDataProfile from "../static_data/GetStaticDataResponse"
import { WeekProfile } from "./UserPreferencesRequest";

export default interface UserProfile {
	email: string,
	first_name: string,
	last_name: string,
	guests: GuestsProfile[] | null,
	food_category_exclusions: StaticDataProfile[] | [],
	week_dinner: WeekProfile | null,
	week_lunch: WeekProfile | null,
	weekend_lunch: WeekProfile | null,
	weekend_dinner: WeekProfile | null,
}

export interface GuestsProfile {
	id: number,
	picture_file: string,
	first_name: string,
	last_name: string,
	gender: Gender,
	birth_date: string,
	height: number,
	weight: number,
	physical_activity_level: PhysicalActivityLevel,
	is_pregnant: boolean,
	is_breast_feeding: boolean,
	group: GuestGroup,
	allergens: Allergens[] | null
}

export interface Allergens {
	id: number,
	name: string
}

export enum Gender {
	Man = "Man",
	Woman = "Woman"
}

export enum PhysicalActivityLevel {
	Low = "Low",
	Medium = "Medium",
	High = "High"
}

export enum GuestGroup {
	FamilyMember = "FamilyMember",
	RegularGuest = "RegularGuest"
}
