import { CHANGE_LANGUAGE, ConfigurationActionTypes, ConfigurationState } from "./types";

const InitialState: ConfigurationState = {
    language: ""
};

export function ConfigurationReducers(state: ConfigurationState = InitialState, action: ConfigurationActionTypes) : ConfigurationState {
    switch(action.type) {
        case CHANGE_LANGUAGE:
            return {
                ...state,
                language: action.language
            }
		default:
			return state;
    }
}
