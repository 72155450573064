import Footer from "src/components/bases/Footer";
import MenuButton from "src/components/bases/MenuButton";
import React from "react";
import ButtonCustom from "src/components/bases/ButtonCustom";
import { TextField, Stack } from "@mui/material";
import useStrings from "src/localization/Localization";

interface ContactFormState {
	name: string;
	email: string;
	subject: string;
	message: string;
}

const ContactForm: React.FC = () => {
	const _strings = useStrings();
	const [validated, setValidated] = React.useState(true);
	const [formStatus, setFormStatus] = React.useState(false);
	const [values, setValues] = React.useState<ContactFormState>({
		name: "",
		email: "",
		subject: "",
		message: "",
	});

	const handleChange =
		(prop: keyof ContactFormState) =>
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setValues({ ...values, [prop]: event.target.value });
		};

	function validateForm() {
		return (
			values.name.length > 0 &&
			values.email.length > 0 &&
			values.subject.length > 0 &&
			values.message.length > 0
		);
	}
	async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		// let verify = await GoogleRecaptchaSiteVerify(recaptchaServerKey, token);
		if (validateForm() /*&& verify*/) {
			const { name, email, subject, message } = values;
    const mailtoLink = `mailto:i.derraz@my-goodlife.fr?subject=${encodeURIComponent(
      subject
    )}&body=Name: ${encodeURIComponent(name)}%0D%0AEmail: ${encodeURIComponent(
      email
    )}%0D%0AMessage: ${encodeURIComponent(message)}`;
    window.location.href = mailtoLink;
			setValidated(true);
			setFormStatus(true);
			// _dispatch<any>(SendEmailContact(values));
		} else {
			setValidated(false);
		}
	}

	return (
		<div>
			<MenuButton></MenuButton>
			<div className="container-fluid">
				<div className="container">
					<h2 className="title">Contactez nous</h2>
					<Stack
						spacing={2}
						component="form"
						autoComplete="off"
						onSubmit={(e: React.FormEvent<HTMLFormElement>) =>
							handleSubmit(e)
						}
					>
						<TextField
							required
							error={!validated}
							id="outlined-basic"
							label="Name"
							variant="outlined"
							onChange={handleChange("name")}
							helperText={
								!validated ? _strings.INCORRECT_ENTRY : ""
							}
							value={values.name}
						/>
						<TextField
							required
							error={!validated}
							id="outlined-basic"
							label="Email"
							type="email"
							variant="outlined"
							onChange={handleChange("email")}
							helperText={
								!validated ? _strings.INCORRECT_ENTRY : ""
							}
							value={values.email}
						/>
						<TextField
							required
							error={!validated}
							id="outlined-basic"
							label="Sujet"
							variant="outlined"
							onChange={handleChange("subject")}
							helperText={
								!validated ? _strings.INCORRECT_ENTRY : ""
							}
							value={values.subject}
						/>
						<TextField
							required
							error={!validated}
							id="outlined-multiline-static"
							label="Message"
							multiline
							rows={6}
							onChange={handleChange("message")}
							helperText={
								!validated ? _strings.INCORRECT_ENTRY : ""
							}
							value={values.message}
						/>


							<div
								className="alert alert-success"
								style={{
									display: formStatus ? "block" : "none",
								}}
							>
								{formStatus ? _strings.message_send : ""}
							</div>
							<div
							style={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<ButtonCustom
								style={{ backgroundColor: "#8fc740" }}
								label={"Envoyer"}
								disabled={!validateForm()}
								type="submit"
							></ButtonCustom>
						</div>
					</Stack>
				</div>
			</div>
			<Footer></Footer>
		</div>
	);
};

export default ContactForm;
