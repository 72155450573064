import { AsyncAction } from "../types";
import ServiceLocator from "../../services/ServiceLocator";
import { HandleError, HandleSuccess, ResetError } from "../page/actions";
import GetStaticDataResponse from "src/api/dtos/static_data/GetStaticDataResponse";
import { API_STATIC_DATA_LOADED, StaticDataActionTypes } from "./types";

export function ApiGetStaticData(): AsyncAction {
    
    return async dispatch => {
        dispatch(ResetError());
        let response = await ServiceLocator.MyEatWellService.GetStaticData();
        if (response.is_success) {
            dispatch(StaticDataLoaded(response));
            dispatch(HandleSuccess());
        }
        else {
            dispatch(HandleError(response));
        }
    }
}

export function StaticDataLoaded(response: GetStaticDataResponse) : StaticDataActionTypes {
    return {
        type: API_STATIC_DATA_LOADED,
        allergens: response.data.allergens,
        food_category_exclusions: response.data.food_category_exclusions
        // static_data: response.data
    };
}