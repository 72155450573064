import { ACTIVE_PRODUCT_SHOPPING_LIST, ADD_PRODUCT_SHOPPING_LIST, REMOVE_PRODUCT_SHOPPING_LIST, ShoppingListActionTypes, ShoppingListState } from "./types";

const InitialState: ShoppingListState = {
    [0]: {
        foodsManually: new Array(),
        foods: new Array()
    }
};

export function ShoppingListReducers(state: ShoppingListState = InitialState, action: ShoppingListActionTypes): ShoppingListState {

    let c = {};
    if (state && state[action.id_category]) {
        c = state[action.id_category];
    }
    let fa = [] as any[];
    let f = [] as any[];
    if (state[action.id_category] && state[action.id_category].foodsManually) {
        fa = state[action.id_category].foodsManually
    }
    if (state[action.id_category] && state[action.id_category].foods) {
        f = state[action.id_category].foods
    }

    switch (action.type) {
        case ADD_PRODUCT_SHOPPING_LIST:
            // state[action.id_category].foodsManually = action.foods;
            // return state;
            return {
                ...state,
                [action.id_category]: {
                    ...c,
                    foodsManually: action.foods,
                    foods: { ...f }
                }
            }
        case REMOVE_PRODUCT_SHOPPING_LIST:
            //  let i = Object.values(fa).findIndex(data => data.id === action.id_food);
            // if (i !== -1) {
            //     Object.values(state[action.id_category].foodsManually).splice(i, 1);
            // }
            // state[action.id_category].foodsManually = action.foods;
            // return state;
            return {
                ...state,
                [action.id_category]: {
                    ...c,
                    foodsManually: action.foods,
                    foods: { ...f }
                }
            }
        case ACTIVE_PRODUCT_SHOPPING_LIST:
            if (f) {
                if (!f[action.food_id]) {
                    f[action.food_id] = {
                        id: action.food_id,
                        is_in_shopping_list: f[action.food_id] && f[action.food_id].is_in_shopping_list ? !f[action.food_id].is_in_shopping_list : false
                    }
                } else {
                    f[action.food_id].is_in_shopping_list = !f[action.food_id].is_in_shopping_list;
                }
            }

            return {
                ...state,
                [action.id_category]: {
                    ...c,
                    foodsManually: { ...fa },
                    foods: { ...f }
                }
            }
        default:
            //return InitialState;
            return state;
    }

}