export interface AuthState {
    RefreshToken: string,
    AccessToken: string,
    ExpirationDate: Date,
    TokenType: string,
    IsLogged: boolean,
    RememberedLogin: string,
    IsReset: boolean,
    IsForgot: boolean
}

export const AUTH_LOGGED_IN = "AUTH_LOGGED_IN";
export const AUTH_LOGGED_OUT = "AUTH_LOGGED_OUT";
export const AUTH_REMEMBER_ME = "AUTH_REMEMBER_ME";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";

interface AuthLoggedInAction {
    type: typeof AUTH_LOGGED_IN,
    accessToken: string,
    refreshToken: string,
    expirationDate: Date,
    tokenType: string
}

interface AuthLoggedOutAction {
    type: typeof AUTH_LOGGED_OUT
}

interface AuthRememberMeAction {
    type: typeof AUTH_REMEMBER_ME,
    login: string,
}

interface ForgotPasswordAction {
    type: typeof FORGOT_PASSWORD,
    forgot: boolean,
}

interface ResetPasswordAction {
    type: typeof RESET_PASSWORD,
    reset: boolean,
}


export type AuthActionTypes = AuthLoggedInAction | AuthLoggedOutAction | AuthRememberMeAction;

export type ResetActionTypes = ForgotPasswordAction | ResetPasswordAction;