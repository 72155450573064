import MenuProfile, { ElementsMenu } from "src/api/dtos/menu/MenuProfile";

export const API_MENU_LOADED = "API_MENU_LOADED";
export const REMOVE_MENU_STATE = "REMOVE_MENU_STATE";
export const API_MEAL_REFRESH = "API_MEAL_REFRESH";
export const LOADING_ACTION = "LOADING_ACTION";

export interface MenuState {
    menu: MenuProfile[],
    loading: boolean,
    // refresh: [][][],
}

interface MealRefreshAction {
    type: typeof API_MEAL_REFRESH,
    mealTime: string,
    part: string,
    dayNumber: number,
    meal: ElementsMenu
}
interface MenuLoadedAction {
    type: typeof API_MENU_LOADED,
    menu: MenuProfile[]
}

interface MenuRemoveStateAction {
    type: typeof REMOVE_MENU_STATE,
}

interface Menu_LoadingStatusAction {
    type: typeof LOADING_ACTION,
    status: boolean,
}

export type MenuActionTypes = MenuLoadedAction | MenuRemoveStateAction | MealRefreshAction | Menu_LoadingStatusAction;