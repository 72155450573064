import { StaticDataProfile } from "src/api/dtos/static_data/GetStaticDataResponse";

export const API_STATIC_DATA_LOADED = "API_CONFIGURATION_LOADED";


export interface StaticDataState {
    allergens: StaticDataProfile[],
    food_category_exclusions: StaticDataProfile[]
}

interface StaticDataLoadedAction {
    type: typeof API_STATIC_DATA_LOADED,
    allergens: StaticDataProfile[],
    food_category_exclusions: StaticDataProfile[]
}

export type StaticDataActionTypes = StaticDataLoadedAction;