import UserProfile from "src/api/dtos/user/UserProfile";

export interface UserProfileState {
	user: UserProfile,
	isLoaded: boolean,
}

export const API_USER_PROFILE_LOADED = "API_USER_PROFILE_LOADED";

interface ApiUserProfileLoadedAction {
    type: typeof API_USER_PROFILE_LOADED,
    user: UserProfile
}

export type ApiUserProfileActionTypes = ApiUserProfileLoadedAction;
