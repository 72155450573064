import { addDays, format } from 'date-fns'

import ServiceLocator from "../../services/ServiceLocator";
import { HandleError, HandleSuccess, ResetError } from "../page/actions";
import { AsyncAction } from "../types";
import { SetPlanningIsSave } from "./days/actions";


import { getClosestDayOfWeek } from "../../helpers/fonctions";
import { ApiGetMenuGenerate } from '../menu/actions';
import { LOADING_ACTION } from './days/types';

export function SavePlanning(dateStart: Date, nbWeek: number, data: object | null): AsyncAction {
    return async dispatch => {
        dispatch(ResetError());
        dispatch(LoadingAction(true));

        // const mysqlDateStart = dateStart.toLocaleDateString();
        // const dayNumberToStart = dateStart.getDay();
        let dataInputList = new Array();
        let startDateWeek = dateStart;
        if (data) {
            Object.entries(data).forEach(([numWeek, days]) => {
                let dataDayList = new Array();
                if (numWeek as unknown as number >= 2) {
                    startDateWeek = addDays(startDateWeek, 7);
                }
                Object.entries(days).forEach(([numDay, typeMeals]) => {
                    const dateS = getClosestDayOfWeek(numDay as unknown as number, startDateWeek);
                    const mysqlDateStart = format(dateS, "yyyy-MM-dd");
                    Object.entries(typeMeals as object).forEach(([typeMeal, categories]) => {
                        let dayInput = {};
                        dayInput['date'] = mysqlDateStart;
                        // dayInput['menuOrigin'] = "French";
                        // dayInput['isValidated'] = false;
                        // const upper = typeMeal.charAt(0).toUpperCase() + typeMeal.substring(1);
                        dayInput['meal'] = typeMeal;
                        Object.entries(categories as object).forEach(([category, value]) => {
                            switch (category) {
                                case "starter":
                                    dayInput['has_starter'] = value as boolean;
                                    break;
                                case "main":
                                    dayInput['has_main'] = value as boolean;
                                    break;
                                case "cheese":
                                    dayInput['has_cheese'] = value as boolean;
                                    break;
                                case "dessert":
                                    dayInput['has_dessert'] = value as boolean;
                                    break;
                                case "rapid":
                                    dayInput['is_quick'] = value as boolean;
                                    break;
                                case "eco":
                                    dayInput['is_economic'] = value as boolean;
                                    break;
                                case "takeAway":
                                    dayInput['is_take_away'] = value as boolean;
                                    break;
                                case "gourmet":
                                    dayInput['is_gourmet'] = value as boolean;
                                    break;
                                case "isEnabled":
                                    dayInput['is_enabled'] = value as boolean;
                                    break;
                                case "nbAdults":
                                    let adults = (value != undefined ? value : 0);
                                    dayInput['added_adults'] = adults;
                                    break;
                                case "nbChildren":
                                    let children = (value != undefined ? value : 0);
                                    dayInput['added_children'] = children;
                                    break;
                                case "persons":
                                    let len = Object.keys(value).length;
                                    dayInput['guest_ids'] = ((len == 0) ? [] : value);
                                    break;
                            }

                        });
                        dataDayList.push(dayInput);
                    });
                });

                let r = {
                    "start_date": format(getClosestDayOfWeek(1, startDateWeek), "yyyy-MM-dd"),
                    "planning_meals": dataDayList
                }
                dataInputList.push(r);
            });
        }

        let is_success = new Array();
        let index = 0;
        let response;
        for await (const element of dataInputList) {
            if(index == 0) {
                response = await ServiceLocator.MyEatWellService.SavePlanning(element);
            } else {
                response = await ServiceLocator.MyEatWellService.SavePlanningWeeks(element);
            }
            if (response.is_success) {
                is_success[index] = true;
            }else{
                break;
            }

            index++;
        }

        if (is_success.length == dataInputList.length) {
            dispatch(HandleSuccess('Sauvegarde de la planification avec succès'));
            dispatch(SetPlanningIsSave(true));
            
            dispatch(ApiGetMenuGenerate());
        }else{
            if(response) {
                dispatch(HandleError(response));
                dispatch(LoadingAction(false));
            }
        }

    };
}

export function LoadingAction(status: boolean) {
    return {
        type: LOADING_ACTION,
        status: status,
    }
}
