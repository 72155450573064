import { RecipesResponse } from "src/api/dtos/recipes/GetRecipesResponse";

export const API_RECIPES_LOADED = "API_RECIPES_LOADED";
export const REMOVE_RECIPES = "REMOVE_RECIPES";
export const LOADING_STATUS = "LOADING_STATUS"
export interface RecipesState {
    [foodId: number]: RecipesResponse
}

interface RecipesLoadedAction {
    type: typeof API_RECIPES_LOADED | typeof LOADING_STATUS,
    id_food: number,
    recipe: RecipesResponse,
    loadImage?: boolean
}

interface RemoveRecipesAction {
    type: typeof REMOVE_RECIPES
}

export type RecipesActionTypes = RecipesLoadedAction | RemoveRecipesAction;