import { useEffect} from "react";
import usePageFocus from "./PageFocus";
import usePageVisibility from "./PageVisibility";


export function ExecuteOnLoad(method: () => any, refreshOnPageDisplayed: boolean): void {

	// const isMounted = useRef(true);

	if (refreshOnPageDisplayed) {
		const isVisible = usePageVisibility();
		const isFocus = usePageFocus();

		useEffect(() => {
			// let effectReturns: void | (() => void) = () => { };
			// if (isMounted.current) {
			// 	isMounted.current = false;
			// } else {
			// 	effectReturns = method();
			// }
			// if (effectReturns && typeof effectReturns === 'function') {
			// 	effectReturns;
			// }

			if(isVisible || isFocus) {
				method();
			}
			
		}, [isVisible, isFocus]);
	}
	else {
		useEffect(() => {
			method();
		}, []);
	}
}
