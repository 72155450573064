import * as React from "react";
import { FromStore } from "src/helpers/Store";
import { Redirect } from "react-router";

export interface LoginGateProps { 
	children: React.ReactNode
}

const LoginGate: React.FunctionComponent<LoginGateProps> = props => {
	const _isLogged = FromStore(state => state.auth.IsLogged);

	if (_isLogged === false) {
		return <Redirect to="/" />
	}

	return <div style={{height: "100%"}}>{props.children}</div>;
}

export default LoginGate;
