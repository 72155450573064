import { RecipesResponse } from "src/api/dtos/recipes/GetRecipesResponse";
import { API_RECIPES_LOADED, REMOVE_RECIPES, RecipesActionTypes, RecipesState } from "./types";

const InitialState: RecipesState = {
    [0]: {} as RecipesResponse
};

export function RecipesReducers(state: RecipesState = InitialState, action: RecipesActionTypes): RecipesState | null {

    if (action.type == REMOVE_RECIPES) {
        return null;
    }

    let f = {};
    if (state && action.id_food && state[action.id_food]) {
        f = state[action.id_food];
    }

    switch (action.type) {
        case API_RECIPES_LOADED:
            if (state && state[action.id_food]) {
                return state;
            } else {
                return {
                    ...state,
                    [action.id_food]: {
                        ...f,
                        name: action.recipe.name,
                        steps: action.recipe.steps,
                        characteristics:
                            action.recipe.characteristics ?
                                {
                                    preparation_time: action.recipe.characteristics.preparation_time,
                                    cooking_time: action.recipe.characteristics.cooking_time,
                                    cost: action.recipe.characteristics.cost,
                                    difficulty: action.recipe.characteristics.difficulty,
                                }
                                : null,
                        nutritional_information:
                            action.recipe.nutritional_information ?
                                {
                                    energy: action.recipe.nutritional_information.energy,
                                    lipid: action.recipe.nutritional_information.lipid,
                                    fatty_acid: action.recipe.nutritional_information.fatty_acid,
                                    glucid: action.recipe.nutritional_information.glucid,
                                    sugar: action.recipe.nutritional_information.sugar,
                                    fiber: action.recipe.nutritional_information.fiber,
                                    protein: action.recipe.nutritional_information.protein,
                                    salt: action.recipe.nutritional_information.salt,
                                }
                                : null,
                        loadImage: action.loadImage ? action.loadImage : false,
                    }
                }
            }
        // case LOADING_STATUS:
        //     if (state && state[action.id_food]) {
        //         state[action.id_food].loadImage = action.loadImage;

        //         return {
        //             ...state
        //         };
        //     }
        default:
            return state;
    }
}