
import * as React from 'react';

import { useDispatch } from "react-redux";
import { FromStore } from "src/helpers/Store";
import useStrings from "src/localization/Localization";

import { AddCategoryDay, AddGuest, AddPersonDay, SetEnabledDay } from "src/stores/plannification/days/actions";
import { CATEGORY_RAPID, CATEGORY_ECO, CATEGORY_TAKE_AWAY, CATEGORY_GOURMET, STRUCTURE_STARTER, STRUCTURE_MAIN, STRUCTURE_CHEESE, STRUCTURE_DESSERT } from "src/stores/plannification/days/types";

import IconButtonSvgIcon from "src/components/bases/IconButtonSvgIcon";
import AvatarLetter from "src/components/bases/AvatarLetter"
import { Avatar } from '@mui/material';

import { Card, Row } from "react-bootstrap";

import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Link, IconButton, Box, Button/*, TextField*/ } from "@mui/material";
import { Form, InputGroup } from "react-bootstrap";
import { Meal } from 'src/api/dtos/menu/MenuProfile';
import { WeekProfile } from "src/api/dtos/user/UserPreferencesRequest";
import UserProfile from "src/api/dtos/user/UserProfile";

import { ApiEditUserPreferences, ApiGetUserProfile} from 'src/stores/apis/user/actions';

type CardPlanningDayProps = {
    dayName: string,
    headerBgColor?: string,
    dayNumber: number,
    typeLunch: string,
    weekNumber: number,
    isEmpty?: boolean,
    from?: string,
};

const CardPlanningDay: React.FunctionComponent<CardPlanningDayProps> = props => {
    const _dispatch = useDispatch();
    const _strings = useStrings();
    const _categories = FromStore(state => state.plannification.weeks);
    const _user = FromStore(state => state.apis.profile);    

    let food_category_exclusions = new Array() as number[];
    if (_user.isLoaded) {
        _user.user.food_category_exclusions.map((element, index) => {
            food_category_exclusions.push(element['id']);
        })
    }

    const defaultPref = {
        is_enabled: true,
        added_adults: 0,
        added_children: 0,
        has_starter: false,
        has_main: true,
        has_cheese: false,
        has_dessert: false,
        is_quick: false,
        is_economic: false,
        is_take_away: false,
        is_gourmet: false,
        guest_ids: [],
        isEmpty: false, // initialiser la propriété isEmpty à false
         
    } as WeekProfile;

    const [preference, setPreference] = React.useState({} as UserProfile);
    const [structure, setStructure] = React.useState({"Lunch": defaultPref, "Dinner": defaultPref});

    const _persons = FromStore(state => state.apis.profile.user.guests);
    let guestList = new Array();
    if (_persons) {
        Object.entries(_persons).forEach(([index, guestInfo]) => {
            if (!guestList[guestInfo.group]) {
                guestList[guestInfo.group] = new Array();
            }
            // guestInfo.picture_file = guestInfo.picture_file ? Buffer.from(guestInfo.picture_file, 'base64').toString() : "";
            guestList[guestInfo.group].push(guestInfo);
        });
    }

    const [nbPersonAvatar, setNbPersonAvatar] = React.useState(0);
    const [nbAdult, setNbAdult] = React.useState(0);
    const [nbChildren, setNbChildren] = React.useState(0);
    const [activeAvatar, setActiveAvatar] = React.useState([] as boolean[]);
    const [personsToAdd, setPersonToAdd] = React.useState([] as number[]);

    const ClockIcon = <IconButtonSvgIcon width={18} height={18} source="/images/planning/clock.svg" />
    const ClockIconActive = <IconButtonSvgIcon width={18} height={18} source="/images/planning/clock-hover.svg" />

    const EuroIcon = <IconButtonSvgIcon width={16} height={18} source="/images/planning/euro.svg" />
    const EuroIconActive = <IconButtonSvgIcon width={18} height={18} source="/images/planning/euro-hover.svg" />

    const ShoppingBagIcon = <IconButtonSvgIcon width={17} height={18} source="/images/planning/shopping-bag.svg" />
    const ShoppingBagIconActive = <IconButtonSvgIcon width={17} height={18} source="/images/planning/shopping-bag-hover.svg" />

    const GourmetIcon = <IconButtonSvgIcon width={18} height={18} source="/images/planning/chef.svg" />
    const GourmetIconActive = <IconButtonSvgIcon width={18} height={18} source="/images/planning/chef-hover.svg" />

    React.useEffect(() => {
        // setTimeout( function() {
            _dispatch(ApiGetUserProfile());

            let dataToLoadInit = {} as WeekProfile;

            if (structure[props.typeLunch] && structure[props.typeLunch].is_enabled && props.from === undefined) {

                let newStructure = structure[props.typeLunch];
                newStructure.has_main = true;
                setStructure({...structure, [props.typeLunch]: newStructure});
                _dispatch(AddCategoryDay(STRUCTURE_MAIN, props.weekNumber, props.dayNumber, props.typeLunch));
    
                if (_user.isLoaded) {
                    let data: {} | null = null;
                    if (_categories && Object.entries(_categories).length === 0) {
                        if ([1, 2, 3, 4, 5].indexOf(props.dayNumber) >= 0) {
                            if (props.typeLunch == Meal.Lunch) {
                                data = _user.user.week_lunch;
                            } else if (props.typeLunch == Meal.Dinner) {
                                data = _user.user.week_dinner;
                            }
                        }
                        if ([6, 7].indexOf(props.dayNumber) >= 0) {
                            if (props.typeLunch == Meal.Lunch) {
                                data = _user.user.weekend_lunch;
                            } else if (props.typeLunch == Meal.Dinner) {
                                data = _user.user.weekend_dinner;
                            }
                        }
                    } else {
                        if (_categories && _categories![props.weekNumber] && _categories![props.weekNumber][props.dayNumber] && _categories![props.weekNumber][props.dayNumber][props.typeLunch]) {
                            data = _categories![props.weekNumber][props.dayNumber][props.typeLunch];
                        }
                    }
    
                    if (data) {
                        Object.entries(data as object).forEach(([category, value]) => {
                            switch (category) {
                                case "is_quick": case "rapid":
                                    newStructure.is_quick = value;
                                    _dispatch(AddCategoryDay(CATEGORY_RAPID, props.weekNumber, props.dayNumber, props.typeLunch, value));
                                    break;
                                case "is_economic": case "eco":
                                    newStructure.is_economic = value;
                                    _dispatch(AddCategoryDay(CATEGORY_ECO, props.weekNumber, props.dayNumber, props.typeLunch, value));
                                    break;
                                case "is_take_away": case "takeAway":
                                    newStructure.is_take_away = value;
                                    _dispatch(AddCategoryDay(CATEGORY_TAKE_AWAY, props.weekNumber, props.dayNumber, props.typeLunch, value));
                                    break;
                                case "is_gourmet": case "gourmet":
                                    newStructure.is_gourmet = value;
                                    _dispatch(AddCategoryDay(CATEGORY_GOURMET, props.weekNumber, props.dayNumber, props.typeLunch, value));
                                    break;
                                case "has_starter": case "starter":
                                    newStructure.has_starter = value;
                                    _dispatch(AddCategoryDay(STRUCTURE_STARTER, props.weekNumber, props.dayNumber, props.typeLunch, value));
                                    break;
                                case "has_main": case "main":
                                    newStructure.has_main = value;
                                    _dispatch(AddCategoryDay(STRUCTURE_MAIN, props.weekNumber, props.dayNumber, props.typeLunch, value));
                                    break;
                                case "has_cheese": case "cheese":
                                    newStructure.has_cheese = value;
                                    _dispatch(AddCategoryDay(STRUCTURE_CHEESE, props.weekNumber, props.dayNumber, props.typeLunch, value));
                                    break;
                                case "has_dessert": case "dessert":
                                    newStructure.has_dessert = value;
                                    _dispatch(AddCategoryDay(STRUCTURE_DESSERT, props.weekNumber, props.dayNumber, props.typeLunch, value));
                                    break;
                                case "guest_ids": case "persons":
                                    newStructure.guest_ids = value;
                                    _dispatch(AddPersonDay(props.weekNumber, props.dayNumber, props.typeLunch, value));
                                    break;
                                case "added_adults": case "nbAdults":
                                    newStructure.added_adults = value;
                                    _dispatch(AddGuest(props.weekNumber, props.dayNumber, props.typeLunch, value, newStructure.added_children));
                                    break;
                                case "added_children": case "nbChildren":
                                    newStructure.added_children = value;
                                    _dispatch(AddGuest(props.weekNumber, props.dayNumber, props.typeLunch, newStructure.added_adults, value));
                                    break;
                            }
    
                            setStructure({...structure, [props.typeLunch]: newStructure});
                            dataToLoadInit = newStructure;
                        });
                    }
                }
            } else {
                dataToLoadInit = _user.user[props.typeLunch];
            }
            console.log(props.typeLunch, dataToLoadInit);
            
            if(dataToLoadInit) {
                setNbPersonAvatar(dataToLoadInit.guest_ids ? Object.keys(dataToLoadInit.guest_ids).length : 0);
                setNbAdult((dataToLoadInit.added_adults > 0 ? dataToLoadInit.added_adults : 0));
                setNbChildren((dataToLoadInit.added_children > 0 ? dataToLoadInit.added_children : 0));
                let personAdd = personsToAdd;
                let avatar = activeAvatar;
                if(dataToLoadInit.guest_ids) {
                    for (let i = 0; i < Object.keys(dataToLoadInit.guest_ids).length; i++) {
                        avatar[dataToLoadInit.guest_ids[i]] = true;
                        personAdd[dataToLoadInit.guest_ids[i]] = dataToLoadInit.guest_ids[i];
                    }
                }
                setActiveAvatar(avatar);
                setPersonToAdd(personAdd);                

                let preferenceTMP = _user.user;                
                if(_user.isLoaded) {
                    if (props.from) {
                        let d: WeekProfile | null = defaultPref;
                        d = _user.isLoaded && _user.user[props.typeLunch] != null ? _user.user[props.typeLunch] as WeekProfile : defaultPref;
                        setPreference({...preference, [props.typeLunch]: d});
                        preferenceTMP[props.typeLunch] = d;
                    } else {
                        if ([1, 2, 3, 4, 5].indexOf(props.dayNumber) >= 0) {
                            if (props.typeLunch == Meal.Lunch) {
                                preferenceTMP["week_lunch"] = _user.user["week_lunch"];
                            } else if (props.typeLunch == Meal.Dinner) {
                                preferenceTMP["week_dinner"] = _user.user["week_dinner"];
                            }
                        }
                        
                    }
                    window.sessionStorage.setItem('planningPreference', JSON.stringify(preferenceTMP));
                }
                
                
            }

        // }, 1000);

        const data = window.sessionStorage.getItem('planningPreference');
        if(data !== null ) {
            let dataParse = JSON.parse(data);
            setPreference(dataParse);

            if ([1, 2, 3, 4, 5].indexOf(props.dayNumber) >= 0) {
                if (props.typeLunch == Meal.Lunch) {
                    setStructure({...structure, [props.typeLunch]: dataParse.week_lunch});
                } else if (props.typeLunch == Meal.Dinner) {
                    setStructure({...structure, [props.typeLunch]: dataParse.week_dinner});
                }
            }    
         
            if ([6, 7].indexOf(props.dayNumber) >= 0) {
                if (props.typeLunch == Meal.Lunch) {
                    setStructure({...structure, [props.typeLunch]: dataParse.weekend_lunch});
                } else if (props.typeLunch == Meal.Dinner) {
                    setStructure({...structure, [props.typeLunch]: dataParse.weekend_dinner});
                }
            }
        } 
            
    }, []);

    function handleActivePerson(event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLSpanElement>, personId: number) {
        event.preventDefault();

        let avatarPerson = activeAvatar;
        avatarPerson[personId] = !avatarPerson[personId];
        setActiveAvatar(avatarPerson);
        let pToAdd = personsToAdd;
        if (avatarPerson[personId] === true) {
            setNbPersonAvatar(nbPersonAvatar + 1);
            pToAdd[personId] = personId;
            setPersonToAdd(pToAdd);
        } else {
            setNbPersonAvatar(nbPersonAvatar - 1);
            pToAdd.splice(pToAdd.indexOf(personId), 1);
            setPersonToAdd(pToAdd);
        }

        let persons = pToAdd.filter((item, index) => pToAdd.indexOf(item) === index);

        if (props.from && preference) {
            let newPreference = preference;
            if(newPreference[props.typeLunch]) {
                newPreference[props.typeLunch].guest_ids = persons;
                setPreference({...preference, [props.typeLunch]: newPreference[props.typeLunch] });
                _dispatch(ApiEditUserPreferences(food_category_exclusions, newPreference.week_lunch, newPreference.week_dinner, newPreference.weekend_lunch, newPreference.weekend_dinner));
            }
        } else {
            let newStructure = structure[props.typeLunch];
            newStructure.guest_ids = persons;
            setStructure({...structure, [props.typeLunch]: newStructure});
            _dispatch(AddPersonDay(props.weekNumber, props.dayNumber, props.typeLunch, persons))
        }

        _dispatch(ApiGetUserProfile());
    }

    // function handleOtherPersonClick(event: React.MouseEvent<HTMLInputElement>) { //{ target: HTMLInputElement }) {
    // const handleOtherPersonClick = (event: React.SyntheticEvent) => {
    function handleOtherPersonClick(event: React.ChangeEvent<HTMLInputElement>) {
        // const handleOtherPersonClick = (event:React.WheelEvent<HTMLInputElement>)  => {
        event.preventDefault();

        const target = event.target as HTMLInputElement;
        const value = target.valueAsNumber;
        const invite = (event.target.validity.valid) ? value : nbAdult;
        // const invite = value;
        let adult = nbAdult;
        let children = nbChildren;
        if (target.id == 'adults') {
            setNbAdult(invite);
            adult = invite;
        }
        if (target.id == 'children') {
            setNbChildren(invite);
            children = invite;
        }

        if (props.from) {
            let newPreference = preference; //_user.user;
            newPreference[props.typeLunch].added_adults = adult;
            newPreference[props.typeLunch].added_children = children;
            setPreference({...preference, [props.typeLunch]: newPreference[props.typeLunch]});

            _dispatch(ApiEditUserPreferences(food_category_exclusions, newPreference.week_lunch, newPreference.week_dinner, newPreference.weekend_lunch, newPreference.weekend_dinner));
        } else {
            let structureTMP = structure;
            structureTMP[props.typeLunch].added_adults = adult;
            structureTMP[props.typeLunch].added_children = children;
            setStructure({...structure, [props.typeLunch]: structureTMP[props.typeLunch] });
            _dispatch(AddGuest(props.weekNumber, props.dayNumber, props.typeLunch, adult, children));
        }

        _dispatch(ApiGetUserProfile());
    }

    function handleCategoryClicked(event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLSpanElement>) {
        event.preventDefault();

        let dataToLoadTMP = {} as WeekProfile;
        if(props.from) {
            dataToLoadTMP = preference[props.typeLunch];
        } else {
            dataToLoadTMP = structure[props.typeLunch];
        }
        switch (event.currentTarget.ariaLabel) {
            case "rapid":
                dataToLoadTMP.is_quick = !dataToLoadTMP.is_quick;
                if (!props.from) {
                    _dispatch(AddCategoryDay(CATEGORY_RAPID, props.weekNumber, props.dayNumber, props.typeLunch));
                }
                break;
            case 'eco':
                dataToLoadTMP.is_economic = !dataToLoadTMP.is_economic;
                if (!props.from) {
                    _dispatch(AddCategoryDay(CATEGORY_ECO, props.weekNumber, props.dayNumber, props.typeLunch));
                }
                break;
            case 'takeAway':
                dataToLoadTMP.is_take_away = !dataToLoadTMP.is_take_away;
                if (!props.from) {
                    _dispatch(AddCategoryDay(CATEGORY_TAKE_AWAY, props.weekNumber, props.dayNumber, props.typeLunch));
                }
                break;
            case 'gourmet':
                dataToLoadTMP.is_gourmet = !dataToLoadTMP.is_gourmet;
                if (!props.from) {
                    _dispatch(AddCategoryDay(CATEGORY_GOURMET, props.weekNumber, props.dayNumber, props.typeLunch));
                }
                break;
            case "starter":
                dataToLoadTMP.has_starter = !dataToLoadTMP.has_starter;
                if (!props.from) {
                    _dispatch(AddCategoryDay(STRUCTURE_STARTER, props.weekNumber, props.dayNumber, props.typeLunch));
                }
                break;
            case "main":
                dataToLoadTMP.has_main = !dataToLoadTMP.has_main;
                if (!props.from) {
                    _dispatch(AddCategoryDay(STRUCTURE_MAIN, props.weekNumber, props.dayNumber, props.typeLunch));
                }
                break;
            case "cheese":
                dataToLoadTMP.has_cheese = !dataToLoadTMP.has_cheese;
                if (!props.from) {
                    _dispatch(AddCategoryDay(STRUCTURE_CHEESE, props.weekNumber, props.dayNumber, props.typeLunch));
                }
                break;
            case "dessert":
                dataToLoadTMP.has_dessert = !dataToLoadTMP.has_dessert;
                if (!props.from) {
                    _dispatch(AddCategoryDay(STRUCTURE_DESSERT, props.weekNumber, props.dayNumber, props.typeLunch));
                }
                break;
        }

        if (props.from) {
            const data = window.sessionStorage.getItem('planningPreference');

            let newPrefrence = data ? JSON.parse(data) : _user.user;
            newPrefrence[props.typeLunch] = dataToLoadTMP;
            setPreference({...preference, [props.typeLunch]: dataToLoadTMP});
            console.log(props.typeLunch, dataToLoadTMP);

            _dispatch(ApiEditUserPreferences(food_category_exclusions, newPrefrence.week_lunch, newPrefrence.week_dinner, newPrefrence.weekend_lunch, newPrefrence.weekend_dinner));

            window.sessionStorage.setItem('planningPreference', JSON.stringify(newPrefrence));
        }

        _dispatch(ApiGetUserProfile());
    }

    function handleActivateMenu(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();

        let newStructure = structure[props.typeLunch];
        if(!newStructure) {
            newStructure = { "Lunch": { ...defaultPref }, "Dinner": { ...defaultPref } };
        } 

        newStructure.is_enabled =  !newStructure.is_enabled;
        newStructure.isEmpty = !newStructure.is_enabled;
        
          console.log(newStructure.isEmpty);

        
        setStructure(newStructure);
        _dispatch(SetEnabledDay(props.weekNumber, props.dayNumber, props.typeLunch, newStructure.is_enabled));
        console.log(newStructure);
        
        // let message = new Array();
        // if (props.elements?.elements) {
        //     Object.entries(props.elements?.elements).forEach(([index, elementInfo]) => {
        //         message.push(elementInfo.id + " => " + elementInfo.food_name);
        //     });
        // }
        // alert('Delete Meal : ' + props.elements?.date + "\n" + message.join());
    }

    const Accordion = styled((props: AccordionProps) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '& .MuiAccordionDetails-root': {
            padding: "2px 3px",
        },
        // '& .MuiOutlinedInput-root': {
        //     borderTopRightRadius: "4px",
        //     borderBottomRightRadius: "4px"
        // },
        // '& .MuiOutlinedInput-input': {
        //     padding: "0px",
        // }
    }));

    const AccordionSummary = styled((props: AccordionSummaryProps) => (
        <MuiAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            {...props}
        />
    ))(({ theme }) => ({
        padding: '5px',
        height: '40px',
        textAlign: 'center',
        '& .MuiAccordionSummary-content': {
            display: 'block',
        },
        // '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        //     transform: 'rotate(90deg)',
        // },
        // '& .MuiCollapse-wrapper': {
        //     position: 'relative',
        //     zIndex: '10',
        //     backgroundColor: 'lightgray'
        // }
    }));

    // const theme = createTheme({
    //     components: {
    //         MuiCollapse: {
    //             styleOverrides: {
    //                 // container: {},
    //                 wrapper: {
    //                     // backgroundColor: "lightgray",
    //                     // zIndex: 10,
    //                     // position: 'relative',
    //                 },
    //                 // wrapperInner: {}
    //             },
    //         },
    //     },
    // });

    const [expanded, setExpanded] = React.useState<string | false>('');
    const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };


    function render(): JSX.Element {        
        let dataToLoad: WeekProfile;
        if(props.from) {
            dataToLoad = preference[props.typeLunch];
        } else {
            dataToLoad = structure[props.typeLunch];
        }
        
        let totalPersons = 0;
        if(dataToLoad && (dataToLoad.guest_ids || dataToLoad.added_adults || dataToLoad.added_children)) {
            totalPersons = Object.keys(dataToLoad.guest_ids).length + dataToLoad.added_adults + dataToLoad.added_children;
        }

        // console.log(props.typeLunch, dataToLoad, totalPersons);
        // console.log(props.dayName, props.typeLunch, structure[props.typeLunch]);
        
        return (
            <Row style={{ margin: "1% 0px" }}>
                <Card style={{
                    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
                    padding: '0px', pointerEvents: (props.isEmpty || structure[props.typeLunch] && !structure[props.typeLunch].is_enabled ? 'none' : 'all'), 
                    opacity:  (props.isEmpty || structure["Dinner"] == undefined || (structure["Lunch"] == undefined && !structure[props.typeLunch].is_enabled) ? `0.3` : 'inherit'),
                }}
                >
                    {(props.headerBgColor !== undefined) ?
                        (<Card.Header style={{ backgroundColor: `${props.headerBgColor}`, color: (props.from ? "inherit" : "white"), textAlign: "center", fontWeight: 800, fontSize: "15px" }}>{props.dayName}</Card.Header>)
                        : null}    

                    <Card.Body style={{ padding: "0px 0px 5px 0px" }}>
                        <div>
                            {/* <ThemeProvider theme={theme}> */}
                            <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    {totalPersons + ' ' + ((totalPersons <= 1) ? `${_strings.person}` : `${_strings.persons}`)}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ fontSize: '13px' }}>
                                        <div>
                                            <div style={{ textAlign: 'center' }}>{_strings.family_member}</div>
                                            <div style={{ textAlign: 'center' }}>
                                                {
                                                    (guestList != undefined && guestList['FamilyMember'] != undefined) ?
                                                        [...guestList['FamilyMember']].map((val: any, index: any) => {
                                                            var indexPerson = -1;
                                                            if (dataToLoad && dataToLoad.guest_ids) {
                                                                for (var i = 0; i < Object.keys(dataToLoad.guest_ids).length; i++) {
                                                                    if (dataToLoad.guest_ids[i] === val.id) {
                                                                        indexPerson = i;
                                                                        break;
                                                                    }
                                                                }
                                                            }

                                                            if (val.picture_file) {
                                                                return (<Avatar key={index} variant="rounded" onClick={(e) => { handleActivePerson(e, val.id) }} className={(dataToLoad && dataToLoad.guest_ids && dataToLoad.guest_ids[indexPerson]) ? 'avatar_active' : 'avatar_inactive'} style={{ top: "6px" }} sx={{ display: "inline-flex", width: "30px", height: "30px", margin: "2px 2px" }} alt={`${val.last_name} ${val.first_name}`} src={val.picture_file} />);
                                                            } else {
                                                                return (<AvatarLetter variant="rounded" className={(dataToLoad && dataToLoad.guest_ids[indexPerson]) ? 'avatar_active' : 'avatar_inactive'} key={index} fullName={`${val.last_name} ${val.first_name}`} onClick={(e) => { handleActivePerson(e, val.id) }} />);
                                                            }
                                                        })
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{ textAlign: 'center' }}>{_strings.regular_member}</div>
                                            <div style={{ textAlign: 'center' }}>
                                                {
                                                    (guestList != undefined && guestList['RegularGuest'] != undefined) ?
                                                        [...guestList['RegularGuest']].map((val: any, index: any) => {
                                                            var indexPerson = -1;
                                                            if (dataToLoad && dataToLoad.guest_ids) {
                                                                for (var i = 0; i < Object.keys(dataToLoad.guest_ids).length; i++) {
                                                                    if (dataToLoad.guest_ids[i] === val.id) {
                                                                        indexPerson = i;
                                                                        break;
                                                                    }
                                                                }
                                                            }

                                                            if (val.picture_file) {
                                                                return (<Avatar key={index} variant="rounded" onClick={(e) => { handleActivePerson(e, val.id) }} className={(dataToLoad && dataToLoad.guest_ids && dataToLoad.guest_ids[indexPerson]) ? 'avatar_active' : 'avatar_inactive'} style={{ top: "6px" }} sx={{ display: "inline-flex", width: "30px", height: "30px", margin: "2px 2px" }} alt={`${val.last_name} ${val.first_name}`} src={val.picture_file} />);
                                                            } else {
                                                                return (<AvatarLetter variant="rounded" className={(dataToLoad && dataToLoad.guest_ids && dataToLoad.guest_ids[indexPerson]) ? 'avatar_active' : 'avatar_inactive'} key={val.id} fullName={`${val.last_name} ${val.first_name}`} onClick={(e) => { handleActivePerson(e, val.id) }} />)
                                                            }
                                                        })
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{ textAlign: 'center' }}>{_strings.guests}</div>

                                            {/* <Box sx={{ display: 'flex', alignItems: 'flex-end', fontSize: "13px" }}>
                                                    <span className={"input-group-text"} style={{ textAlign: 'center' }}> Adultes </span>
                                                    <TextField
                                                        id="adults"
                                                        type="number"
                                                        onChange={handleOtherPersonClick}
                                                    />
                                                </Box> */}
                                            <Form.Group style={{ width: "fit-content", margin: 'auto' }}>
                                                <InputGroup>
                                                    <InputGroup.Text style={{ fontSize: "13px" }}>
                                                        Adultes
                                                    </InputGroup.Text>
                                                    <Form.Control type="number" pattern="[0-9]*" id='adults' value={nbAdult} min={0} max={10} onInput={handleOtherPersonClick}
                                                        /*onChange={handleOtherPersonClick} onWheel={handleOtherPersonClick}*/ />
                                                </InputGroup>
                                                <InputGroup>
                                                    <InputGroup.Text style={{ fontSize: "13px" }}>
                                                        Enfants
                                                    </InputGroup.Text>
                                                    <Form.Control type="number" pattern="[0-9]*" id='children' value={nbChildren} min={0} max={10} onInput={handleOtherPersonClick}  /*onWheel={handleOtherPersonClick}*/ />
                                                </InputGroup>
                                            </Form.Group>
                                            {/* <ButtonGroup variant="outlined" aria-label="outlined button group">
                                                <Button onClick={handleOtherPersonClick}>-</Button>
                                                <Button onClick={handleOtherPersonClick}>+</Button>
                                            </ButtonGroup> */}

                                        </div>
                                    </div>

                                </AccordionDetails>
                            </Accordion>
                            {/* </ThemeProvider> */}

                            <div style={{ borderTop: "solid 1px gray", textAlign: "center" }}>
                                <IconButton aria-label="rapid" onClick={(e) => { handleCategoryClicked(e) }}>
                                    {dataToLoad && dataToLoad.is_quick ? ClockIconActive : ClockIcon}
                                </IconButton>
                                <IconButton aria-label="eco" onClick={(e) => { handleCategoryClicked(e) }}>
                                    {dataToLoad && dataToLoad.is_economic ? EuroIconActive : EuroIcon}
                                </IconButton>
                                <IconButton aria-label="takeAway" onClick={(e) => { handleCategoryClicked(e) }}>
                                    {dataToLoad && dataToLoad.is_take_away ? ShoppingBagIconActive : ShoppingBagIcon}
                                </IconButton>
                                <IconButton aria-label="gourmet" onClick={(e) => { handleCategoryClicked(e) }}>
                                    {dataToLoad && dataToLoad.is_gourmet ? GourmetIconActive : GourmetIcon}
                                </IconButton>
                            </div>
                            <div className="planning-structure" style={{ borderTop: "solid 1px gray", textAlign: "center" }}>
                                <div>
                                    <Link underline="none" color="inherit" aria-label="starter" onClick={(e) => { handleCategoryClicked(e) }}>
                                        <span className={dataToLoad && dataToLoad.has_starter ? 'active' : ""}>{_strings.starter}</span>
                                    </Link>
                                </div>
                                <div>
                                    <Link underline="none" color="inherit" aria-label="main" style={{ pointerEvents: "none", opacity: "0.5" }} >
                                        <span className={dataToLoad && dataToLoad.has_main ? 'active' : ""}>{_strings.main}</span>
                                    </Link>
                                </div>
                                <div>
                                    <Link underline="none" color="inherit" aria-label="cheese" onClick={(e) => { handleCategoryClicked(e) }} >
                                        <span className={dataToLoad && dataToLoad.has_cheese ? 'active' : ""}>{_strings.cheese}</span>
                                    </Link>
                                </div>
                                <div>
                                    <Link underline="none" color="inherit" aria-label="dessert" onClick={(e) => { handleCategoryClicked(e) }}>
                                        <span className={dataToLoad && dataToLoad.has_dessert ? 'active' : ""}>{_strings.dessert}</span>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </Card.Body>
                </Card>


                <div style={{ textAlign: 'center', marginTop: "15px", visibility: (props.isEmpty || props.from ? "hidden" : "visible") }}>
                    <Box sx={{
                        "& .MuiButton-root": {
                            color: dataToLoad && dataToLoad.is_enabled ? 'red' : '#8fc740',
                            borderRadius: "25px",
                            border: dataToLoad && dataToLoad.is_enabled ? "2px solid red" : "2px solid #8fc740",
                            textTransform: "capitalize",
                            padding: "0 20px",
                            fontWeight: "200",
                        }
                    }}
                    >
                        <Button onClick={handleActivateMenu}>{dataToLoad && dataToLoad.is_enabled ? _strings.delete : _strings.activate}</Button>
                    </Box>
                </div>

            </Row >
        )
    }

    return render();
};

export default CardPlanningDay;