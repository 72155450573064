import { FromStore } from "src/helpers/Store";
import defaultStrings from "./default";
import frStrings from "./fr";
import Strings, { RawStrings } from "./types";
import getUserLocale from "./user-locale";

const localizationStrings = {
    default: defaultStrings,
	fr: frStrings,
};

function createStringObject(source: RawStrings, languageCode: string): Strings {
	return {
		...source,
		languageCode: languageCode,
		tr: (key) => stringsForKey(source, key),
		trOrEmpty: (key) => stringsForKeyDefault(source, key, ""),
		fromApiObject: (translationContent) => getTranslationFromApiObject(translationContent, languageCode),
	}
}

function getLanguageCode(locale: string) : string {
	if(!locale)
	{
		return "en";
	}

	if(locale.length > 2)
	{
		locale = locale.substring(0, 2);
	}

	if(locale === 'fr')
	{
		return "fr";
	}

	return "en";
}

function getRawStringForLocale(languageCode: string) : RawStrings {
    if(!languageCode)
    {
        return localizationStrings.default;
    }

    if(languageCode === 'fr')
    {
        return localizationStrings.fr;
	}

    return localizationStrings.default;
}

export default function useStrings(): Strings {
	let language = FromStore(x => x.configuration.language);
	if(language == "")
	{
		language = getUserLocale();
	}

	let languageCode = getLanguageCode(language);
	return createStringObject(getRawStringForLocale(languageCode), languageCode);
}

export function stringsForKey(strings: RawStrings, key: string): string {
	let value = strings[key];
	if(value == undefined)
	{
		return key;
	}
	return strings[key];
}

export function stringsForKeyDefault(strings: RawStrings, key: string, defaultValue: string): string {
	let value = strings[key];
	if(value == undefined)
	{
		return defaultValue;
	}
	return strings[key];
}

export function getTranslationFromApiObject(translationContent: object, languageCode: string): string {
	if(languageCode in translationContent) {
		return translationContent[languageCode];
	}
	//Gotta look at it again 
	// else if("en" in translationContent) {
	// 	return translationContent["en"];
	// }
	return "";
}
