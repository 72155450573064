import * as React from "react";
import useStrings from "src/localization/Localization";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ExecuteOnLoad } from 'src/helpers/Lifecycle';
import { FromStore } from "src/helpers/Store";
import { ApiGetUserProfile } from 'src/stores/apis/user/actions';
import { ApiGetRecipes } from 'src/stores/recipes/action';

import MenuButton from "src/components/bases/MenuButton";
import Footer from "src/components/bases/Footer";
import { Card, Col, Row } from "react-bootstrap";

import StarBorderIcon from '@mui/icons-material/StarBorder';
import CardMenuDetail from "src/components/bases/CardMenuDetail";
import { ElementsMenu/*, MealType*/ } from "src/api/dtos/menu/MenuProfile";
import { RecipesResponse } from "src/api/dtos/recipes/GetRecipesResponse";
import { useCheckMobileScreen } from "src/helpers/fonctions";

export interface MenuDetailPageProps { }


const MenuDetailPage: React.FunctionComponent<MenuDetailPageProps> = (props) => {
    const _strings = useStrings();
    const location = useLocation();
    const _dispatch = useDispatch();
    const mobileScreen = useCheckMobileScreen();

    const _recipes = FromStore(state => state.recipes);
    const historyState = location.state as [];
    let meals = historyState;

    let info = {
        preparation: 0,
        cuisson: 0,
        total_cost: 0,
        difficulty: 0,
        guests: new Array(),
        added_adults: 0,
        added_children: 0,
        // bread: {} as ElementsMenu,
        bread: {
            name: "",
            nutritional_information: {} as RecipesResponse["nutritional_information"],
            characteristics: {} as RecipesResponse["characteristics"],
            steps: "",
            portions: new Array(),
        },
    };

    ExecuteOnLoad(() => {  
        if (meals) {
            meals.map((element: ElementsMenu, index: number) => {
                if (_recipes == null || (_recipes && !_recipes[element.food_id])) {
                    _dispatch(ApiGetRecipes(element.food_id));
                }
            });
        }
    }, false);

    if (meals) {
        meals.map((element: ElementsMenu, index: number) => {

            let recipe = _recipes && _recipes![element.food_id];
            if (recipe && recipe.characteristics) {
                info.preparation += recipe.characteristics.preparation_time;
                info.cuisson += recipe.characteristics.cooking_time;
                info.total_cost = info.total_cost < recipe.characteristics.cost ? recipe.characteristics.cost : info.total_cost;
                info.difficulty = info.difficulty < recipe.characteristics.difficulty ? recipe.characteristics.difficulty : info.difficulty;
            }
            info.added_adults = element.added_adults;
            info.added_children = element.added_children;
            element.portions.forEach(el => info.guests.indexOf(el.guest_id) == -1 ? info.guests.push(el.guest_id) : null)
        });
    }

    ExecuteOnLoad(() => {
        _dispatch(ApiGetUserProfile());

        const selected = location.hash
        if (selected && selected.length > 0) {
            const elem = document.getElementById(selected.substring(1));
            elem && elem.scrollIntoView();
        }

    }, true);

    function createStars(nbStars: number) {
        let stars: any[] = [];
        [1, 2, 3].map((i, index) => (
            (i <= nbStars) ?
                stars.push(<StarBorderIcon key={index} fontSize="small" sx={{ color: "rgb(253, 105, 77)" }} />)
                :
                stars.push(<StarBorderIcon key={index} fontSize="small" />)
        ));
        return stars;
    }

    function render(): JSX.Element {

        let informations = [
            {
                title: _strings.preparation,
                note: <div style={{ color: "rgb(253, 105, 77)" }}>{info.preparation} mins</div>,
                image: <img src="/images/menu/icons/preparation_icon.png" width="30px" height="30px" />
            },
            {
                title: _strings.cooking,
                note: <div style={{ color: "rgb(253, 105, 77)" }}>{info.cuisson} mins</div>,
                image: <img src="/images/menu/icons/frying_pan_icon.png" width="30px" height="30px" />
            },
            {
                title: _strings.total_cost,
                note: createStars(info.total_cost),
                image: <img src="/images/menu/icons/cost_icon.png" width="30px" height="30px" />
            },
            {
                title: _strings.difficulty,
                note: createStars(info.difficulty),
                image: ""
            },
            {
                title: _strings.guests2,
                note: <div style={{ color: "rgb(253, 105, 77)" }}>{info.guests.length + info.added_adults + info.added_children}</div>,
                image: <img src="/images/menu/icons/guest_list_icon.png" width="30px" height="30px" />
            },
        ];

        return (
            <div>
                <MenuButton selected={1}></MenuButton>

                <div className="marginPages" >
                    <Row md={mobileScreen ? 2 : informations.length} style={{ margin: '2% 0' }} >
                        {
                            informations.map((info, index) => {
                                return (
                                    <Col key={index}>
                                        <Card style={{ boxShadow: "0 2px 15px 1px rgba(0, 0, 0, 0.1)", minWidth: '150px', minHeight: '150px', textAlign: 'center', fontWeight: "bold", display: "flex", justifyContent: "center", margin: "auto" }}>
                                            <div style={{ margin: "5%" }}>{info.image}</div>
                                            <div>{info.title}</div>
                                            <div>{info.note}</div>
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>

                    {
                        meals ?
                            meals.map((element: ElementsMenu, index: number) => {
                                // if (element.part == MealType.Bread) {
                                //     return
                                // }
                                return (
                                    <div id={String(element.food_id)} key={index}>
                                        <CardMenuDetail element={element} />
                                    </div>
                                )
                            })
                            : null

                    }
                </div>

                <Footer></Footer>
            </div>
        )
    }
    return render();
};
export default MenuDetailPage;