import * as React from 'react';
import { addDays, getISODay } from 'date-fns'

/**
 * https://stackoverflow.com/questions/54142402/how-to-find-the-nearest-day-of-the-week-with-date-fns
 * @param dayOfWeek 
 * @param fromDate 
 * @returns 
 */
 export function getClosestDayOfWeek(dayOfWeek: number, fromDate = new Date()) {
    // follow the getISODay format (7 for Sunday, 1 for Monday)
    // const dayOfWeekMap = {
    //     Mon: 1,
    //     Tue: 2,
    //     Wed: 3,
    //     Thur: 4,
    //     Fri: 5,
    //     Sat: 6,
    //     Sun: 7,
    // };

    // -7 means last week
    // dayOfWeekMap[dayOfWeek] get the ISODay for the desired dayOfWeek

    // e.g. If today is Sunday, getISODay(fromDate) will returns 7
    // if the day we want to find is Thursday(4), apart from subtracting one week(-7),
    // we also need to account for the days between Sunday(7) and Thursday(4)
    // Hence we need to also subtract (getISODay(fromDate) - dayOfWeekMap[dayOfWeek])
    const offsetDays = -(getISODay(fromDate) - dayOfWeek);

    return addDays(fromDate, offsetDays);
}

export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


export function useCheckMobileScreen() {
    const [width, setWidth] = React.useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (width <= 768);
}