import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router";
import { FromStore } from "src/helpers/Store";
import useStrings from "src/localization/Localization";
import { Login } from "src/stores/auth/actions";
import MessagesInPage from "src/components/bases/MessagesInPage";

import ButtonCustom from "src/components/bases/ButtonCustom";
import { Stack, TextField, Box, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export interface LoginPageProps { }

interface LoginPageState {
	username: string;
	password: string;
	rememberMe: boolean;
	hasError: boolean;
	errorText: string;
	showPassword: boolean;
}

const LoginPage: React.FunctionComponent<LoginPageProps> = props => {
	const _dispatch = useDispatch();
	const _strings = useStrings();
	let history = useHistory();
	const _savedLogin = FromStore(state => state.auth.RememberedLogin);
	const _apiError = FromStore(state => state.page.ErrorCode);
	const _isLogged = FromStore(state => state.auth.IsLogged);

	const [values, setValues] = React.useState<LoginPageState>({
		username: _savedLogin,
		password: '',
		rememberMe: true,
		hasError: false,
		errorText: "",
		showPassword: false,
	});

	const handleChange =
		(prop: keyof LoginPageState) => (event: React.ChangeEvent<HTMLInputElement>) => {
			setValues({ ...values, [prop]: event.target.value });
		};

	const handleClickShowPassword = () => {
		setValues({
			...values,
			showPassword: !values.showPassword,
		});
	};

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const [validated, setValidated] = React.useState(true);

	if (_isLogged) {
		return <Redirect to="/calendar" />;
	}

	if (values.hasError === false && _apiError) {
		setValidated(false);
		setValues({
			...values,
			hasError: true,
			errorText: _strings.login_error_invalid_credentials
		});
	}

	// function rememberMeChanged(event: React.ChangeEvent<HTMLInputElement>) {
	// 	_state.Update({ rememberMe: event.target.checked });
	// }

	function validateLoginForm() {
		return (
			values.username.length > 0 &&
			values.password.length > 0
		);
	}

	function handleLoginClicked(event: React.FormEvent<HTMLFormElement>) {
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
		}

		event.preventDefault();
		if (validateLoginForm()) {
			setValidated(true);
			setValues({
				...values,
				hasError: false,
				errorText: ""
			});
			_dispatch(Login(values.username, values.password, values.rememberMe));
		} else {
			setValidated(false);
			setValues({
				...values,
				hasError: true,
				errorText: _strings.login_validation_error
			});
		}
	}

	function render(): JSX.Element {
		return (
			<Box>
				<MessagesInPage />

				<h1 className="mt-5 mb-5 text-center text-danger">{_strings.welcome}</h1>

				<div style={{
					width: "50 %",
					maxWidth: "500px",
					margin: "20px auto",
					padding: "20px"
				}}>
					<Stack spacing={2} component="form" autoComplete="off" onSubmit={handleLoginClicked}>
						<TextField
							required
							error={!validated}
							id="login-email"
							label={_strings.email}
							type="email"
							helperText={!validated ? _strings.INCORRECT_ENTRY : ""}
							onChange={handleChange("username")}
						/>

						<TextField
							required
							error={!validated}
							id="login-password"
							label={_strings.password}
							type={values.showPassword ? 'text' : 'password'}
							autoComplete="off"
							helperText={!validated ? _strings.INCORRECT_ENTRY : ""}
							onChange={handleChange("password")}
							InputProps={{
								endAdornment: <InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{values.showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}}
						/>

						<ButtonCustom
							type="submit"
							disabled={!validateLoginForm}
							style={{ textTransform: "capitalize", backgroundColor: "#8fc740", color: "white", borderColor: "#8fc740", borderRadius: "25px", fontSize: "1rem" }}
							label={_strings.login_connection}>
						</ButtonCustom>

						<ButtonCustom
							style={{ backgroundColor: "white", color: "#6c757d", borderColor: "white", textTransform: "initial", justifyContent: "right" }}
							onClick={() => { history.push('/forgotpassword') }}
							label={_strings.forgot_password}>
						</ButtonCustom>

						<ButtonCustom
							style={{ backgroundColor: "white", color: "#6c757d", borderColor: "white", textTransform: "initial", justifyContent: "center", textDecoration: "underline" }}
							onClick={() => { }}
							label={_strings.need_help}>
						</ButtonCustom>

					</Stack>
				</div>
			</Box >
		);
	}

	return render();
};

export default LoginPage;