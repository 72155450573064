import * as React from "react";

import { ExecuteOnLoad } from "src/helpers/Lifecycle";
import { useDispatch } from "react-redux";
import useStrings from "src/localization/Localization";
import { FromStore } from "src/helpers/Store";
import { DeclareState } from "src/helpers/State";

import MenuButton from "src/components/bases/MenuButton";
import Footer from "src/components/bases/Footer";

import MenuWeek from "src/components/bases/MenuWeek";
import ButtonCustom from "src/components/bases/ButtonCustom";

import { Tabs, Tab, Box, Alert } from "@mui/material";
import { tabsClasses } from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
// import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CircularProgress from '@mui/material/CircularProgress';

// import { Meal } from "src/api/dtos/menu/MenuProfile";
import { SetPlanningIsSave } from "src/stores/plannification/days/actions";
import { ApiGetMeals } from "src/stores/menu/actions";
import * as DateFns from 'date-fns';
import { ApiGetUserProfile } from "src/stores/apis/user/actions";

type MenuPageProps = {};

const MenuPage: React.FunctionComponent<MenuPageProps> = props => {
    const _dispatch = useDispatch();
    const _strings = useStrings();

    const _loading = FromStore(state => state.menu ? state.menu.loading : false);

    // const _plannification = FromStore(state => state.plannification.weeks);
    // const _startDate = FromStore(state => state.plannification.start_date?.start_date);

    // let startDate = null as unknown as Date;
    // if (_startDate) {
    //     startDate = new Date(_startDate);
    // }
    const _stateDates = DeclareState({
        "currentDateFrom": DateFns.startOfWeek(new Date(), { weekStartsOn: 1 }),
        "currentDateTo": DateFns.endOfWeek(new Date(), { weekStartsOn: 1 }),
        // "listDateFrom": {0: DateFns.format(DateFns.startOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd")},
    });

    const _menu = FromStore(state => state.menu ? state.menu.menu : null);
    const [valueTab, setValueTab] = React.useState(0);
    // const initNbTab = _plannification ? Object.keys(_plannification!).length : 1;
    const [showNbTab/*, setShowNbTab*/] = React.useState(1);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setValueTab(newValue);
    };

    const handleNextTab = (event: React.SyntheticEvent) => {
        event.preventDefault();
        // let nextDate = DateFns.nextMonday(new Date(_stateDates.content.listDateFrom[valueTab]));
        // let ld = _stateDates.content.listDateFrom;
        // ld[valueTab+1] = DateFns.format(nextDate, "yyyy-MM-dd");
        // _stateDates.Update({ listDateFrom: ld  });

        // setValueTab(valueTab + 1);
        // setShowNbTab(showNbTab + 1);

        let nextDateFrom = DateFns.nextMonday(new Date(_stateDates.content.currentDateFrom));
        let nextDateTo = DateFns.nextSunday(new Date(_stateDates.content.currentDateTo));
        _stateDates.Update({
            currentDateFrom: nextDateFrom,
            currentDateTo: nextDateTo,
        });

        _dispatch(ApiGetMeals(DateFns.format(nextDateFrom, "yyyy-MM-dd"), DateFns.format(nextDateTo, "yyyy-MM-dd")));
    };

    const handlePreviousTab = (event: React.SyntheticEvent) => {
        // setValueTab(valueTab - 1);
        // setShowNbTab(showNbTab + 1);

        let previousDateFrom = DateFns.previousMonday(new Date(_stateDates.content.currentDateFrom));
        let previousDateTo = DateFns.previousSunday(new Date(_stateDates.content.currentDateTo));
        _stateDates.Update({
            currentDateFrom: previousDateFrom,
            currentDateTo: previousDateTo,
        });

        _dispatch(ApiGetMeals(DateFns.format(previousDateFrom, "yyyy-MM-dd"), DateFns.format(previousDateTo, "yyyy-MM-dd")));
    };

    ExecuteOnLoad(() => {
        _dispatch(ApiGetUserProfile());
    }, true);

    ExecuteOnLoad(() => {
        _dispatch(SetPlanningIsSave(false));
        _dispatch(ApiGetMeals(DateFns.format(_stateDates.content.currentDateFrom, "yyyy-MM-dd"), DateFns.format(_stateDates.content.currentDateTo, "yyyy-MM-dd")));
    }, false);

    let menuList = new Array();
    // let numWeek = 0;
    if (_menu) {
        Object.entries(_menu).forEach(([index, menuInfo]) => {
            let date = new Date(menuInfo.date);
            if (!menuList[menuInfo.meal]) {
                menuList[menuInfo.meal] = new Array();
            }
            let numDay = date.getDay();
            if (numDay == 0) {
                numDay = 7;
            }
            menuList[menuInfo.meal][numDay] = menuInfo;
        });
    }
    // if (_menu) {
    //     Object.entries(_menu).forEach(([index, menuInfo]) => {
    //         let numDay = new Date(menuInfo.date).getDay();
    //         if (numDay == 1 && (menuList[numWeek] && menuList[numWeek][Meal.Lunch] && menuList[numWeek][Meal.Dinner])) {
    //             numWeek++;
    //         }
    //         if (!menuList[numWeek]) {
    //             menuList[numWeek] = new Array();
    //         }
    //         if (!menuList[numWeek][menuInfo.meal]) {
    //             menuList[numWeek][menuInfo.meal] = new Array();
    //         }
    //         menuList[numWeek][menuInfo.meal].push(menuInfo);
    //     });
    // }

    function render(): JSX.Element {
        let render = null;
        // if (_menu == null || _menu.length == 0) {
        //     render = (
        //         <div style={{ margin: "35px 10%" }}>
        //             <Alert severity="info">{_strings.menu_unavailable}</Alert>
        //         </div>
        //     );
        // } else {
        render = (
            <div className={_loading ? 'opacity' : ''} >
                <TabContext value={valueTab.toString()}>
                    <Box style={{ margin: "35px 8% 0" }} sx={{
                        "& .MuiButtonBase-root.MuiTab-root": {
                            minHeight: "auto !important",
                            textTransform: "none"
                        },
                        "& > div": {
                            display: "inline-block",
                            minHeight: "max-content",
                            verticalAlign: "middle",
                            marginRight: "20px"
                        },
                        "& .MuiTabs-root": {
                            display: "inline-flex",
                            width: "80%",
                        },
                        "& .MuiTab-root.Mui-selected": {
                            color: "white !important",
                            backgroundColor: "#fd694d",
                            borderRadius: "5px"
                        },
                        "& .MuiTabs-indicator": {
                            backgroundColor: "transparent !important"
                        }
                    }}>
                        <div style={{ display: 'inline-flex' }}>{_strings.your_weeks} : </div>
                        <Tabs value={valueTab.toString()} variant="scrollable" scrollButtons="auto" onChange={handleChangeTab} className="tabList"
                            sx={{
                                [`& .${tabsClasses.scrollButtons}`]: {
                                    '&.Mui-disabled': { opacity: 0.3 },
                                },
                            }}

                        >
                            {
                                [...Array(showNbTab)].map((el, index) => (
                                    <Tab label={`${_strings.from} ${DateFns.format(_stateDates.content.currentDateFrom, "dd/MM/yyyy")} ${_strings.to} ${DateFns.format(_stateDates.content.currentDateTo, "dd/MM/yyyy")}`} /*label={_stateDates.content.listDateFrom[index]}*/ key={index} value={`${index}`} />
                                )
                                )
                            }
                        </Tabs>
                    </Box>
                    {
                        (_menu == null || _menu.length == 0) ?

                            <div style={{ margin: "35px 8%" }}>
                                <Alert severity="info">{_strings.menu_unavailable}</Alert>
                            </div>
                            :
                            [...Array(showNbTab)].map((el, index) => (
                                <TabPanel key={index} value={`${index}`}>
                                    <MenuWeek elements={menuList} /*weekNumber={index + 1}*/ /*dayToStart={startDate.getDay()}*/ />
                                </TabPanel>
                            ))
                    }
                </TabContext>

                <div style={{ margin: "0 8% 20px 8%", display: "flex", justifyContent: "flex-end" }}>
                    <ButtonCustom style={{ backgroundColor: "white", color: "#8fc740", borderColor: "#8fc740" }} onClick={(e) => handlePreviousTab(e)} label={_strings.see_previous_week}></ButtonCustom>
                    <ButtonCustom style={{ backgroundColor: "white", color: "#8fc740", borderColor: "#8fc740" }} onClick={(e) => handleNextTab(e)}/*onClick={(e) => { handleChangeTab(e, valueTab == 3 ? 0 : valueTab + 1) }}*/ label={_strings.see_next_week}></ButtonCustom>
                </div>
            </div>
        );

        return (
            <div>
                <MenuButton selected={1}></MenuButton>

                {
                    _loading ?
                        <div className="loader">
                            < CircularProgress style={{ color: "#fd694d" }
                            } />
                        </div >
                        : null
                }

                {render}

                <Footer></Footer>
            </div>
        );
    }

    return render();
};

export default MenuPage;