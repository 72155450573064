import * as React from "react";
import { FromStore } from "src/helpers/Store";
import useStrings from "src/localization/Localization";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ForgotPassword } from "src/stores/auth/actions";

import MessagesInPage from "src/components/bases/MessagesInPage";

import ButtonCustom from "src/components/bases/ButtonCustom";
import { Stack, TextField, Box  } from '@mui/material';

export interface ForgotPasswordPageProps {}

interface ForgotPasswordPageState {
	email: string;
	hasError: boolean;
	errorText: string;
}

const ForgotPasswordPage: React.FunctionComponent<ForgotPasswordPageProps> = props => {
	const _dispatch = useDispatch();
	const _strings = useStrings();
	let history = useHistory();
	const _savedLogin = FromStore(state => state.auth.RememberedLogin);
	// const [isSendingCode, setIsSendingCode] = React.useState(false);
	// const [open, setOpen] = React.useState(true);

	const [values, setValues] = React.useState<ForgotPasswordPageState>({
		email: _savedLogin,
		hasError: false,
		errorText: ""
    });

	const handleChange =
		(prop: keyof ForgotPasswordPageState) => (event: React.ChangeEvent<HTMLInputElement>) => {
			setValues({ ...values, [prop]: event.target.value });
		};
		
	function handleResetPasswordClicked(
		event: React.FormEvent<HTMLFormElement>
	) {
		event.preventDefault();
		// setIsSendingCode(true);
		try {
			if (validateForgotForm()) {
				setValues({
					...values,
					hasError: false,
				});
				_dispatch(ForgotPassword(values.email, _strings.forgot_password_email_message));
			} else {
				setValues({
					...values,
					hasError: true,
					errorText: _strings.forgot_validation_error
				});
			}
		} catch (error ) {
			setValues({
				...values,
				hasError: true,
				errorText: error
			});
			console.log(error);
			// setIsSendingCode(false);
		  }
	}

	function validateForgotForm() {
		return values.email.length > 0;
	}

	function render(): JSX.Element {
		return (
			<Box>
				<MessagesInPage></MessagesInPage>
				<h1 className="mb-5 text-center text-danger">
					{_strings.forgot_password}
				</h1>
				<div className="mb-3 mx-sm-5 text-center text-muted">
					{_strings.forgot_password_message}
				</div>

				<div style={{ display: "flex", justifyContent: "center" }}>
					<Stack spacing={2} component="form" autoComplete="off" onSubmit={handleResetPasswordClicked}>

						<TextField
							required
							id="forgot-email"
							label={_strings.email}
							type="email"
							onChange={handleChange("email")}
						/>

						<ButtonCustom 
							type="submit" 
							disabled={!validateForgotForm} 
							style={{ textTransform: "capitalize", backgroundColor: "#8fc740", color: "white", borderColor: "#8fc740", borderRadius:"25px", fontSize: "1rem" }} 
							label={_strings.submit}>
						</ButtonCustom>

						<ButtonCustom 
							style={{ backgroundColor: "white", color: "#8fc740", borderColor: "#8fc740", textTransform: "initial",  borderRadius:"25px", fontSize: "1rem" }} 
							onClick={() => { history.push('/login')}} 
							label={_strings.back_to_login}>
						</ButtonCustom>

					</Stack>
				</div>
			</Box>
		);
	}

	return render();
};

export default ForgotPasswordPage;
