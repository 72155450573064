declare global {
    interface Window {
        // IE navigator lanuage settings (non-standard)
        userLanguage: string;
        browserLanguage: string;
    }
}

export default function getUserLocale(): string {
    return window.navigator.language ||
        window.browserLanguage ||
        window.userLanguage;
}