
import Urls from "src/configuration/constants";
import ServiceLocator from "./ServiceLocator";
import EmptyApiResponse from "src/api/dtos/EmptyApiResponse";

// import GetPersonResponse from "src/api/dtos/planning/GetPersonResponse";
import { SavePlanningRequest, SavePlanningWeekRequest } from "src/api/dtos/planning/SavePlanningRequest";

import MenuGenerateResponse from "src/api/dtos/menu/MenuGenerateResponse";
import GetStaticDataResponse from "src/api/dtos/static_data/GetStaticDataResponse";
import { GuestsProfileRequest } from "src/api/dtos/user/GuestProfileRequest";
import GetRecipesResponse from "src/api/dtos/recipes/GetRecipesResponse";
import MenuPatchResponse from "src/api/dtos/menu/MenuPatchResponse";


export default class MyEatWellService {
    public GetRecipePicture(food_id:number): Promise<XMLHttpRequest> {
        return ServiceLocator.ApiClient.Get(Urls.GET_RECIPES_PICTURE+"/"+food_id);
    }

    public GetRecipes(food_id:number): Promise<GetRecipesResponse> {
        return ServiceLocator.ApiClient.Get<GetRecipesResponse>(Urls.GET_RECIPES+"/"+food_id);
    }

    public GetStaticData(): Promise<GetStaticDataResponse> {
        return ServiceLocator.ApiClient.Get<GetStaticDataResponse>(Urls.GET_CONFIGURATION);
    }

    public SavePlanning(request: SavePlanningRequest): Promise<EmptyApiResponse> {
        return ServiceLocator.ApiClient.Post(Urls.SAVE_PLANNING, request);
    }

    public SavePlanningWeeks(request: SavePlanningWeekRequest): Promise<EmptyApiResponse> {
        return ServiceLocator.ApiClient.Post(Urls.SAVE_PLANNING_WEEK, request);
    }

    public GetMenuGenerate(): Promise<MenuGenerateResponse> {
        return ServiceLocator.ApiClient.Post(Urls.GET_MENU_GENERATE, null);
    }

    public GetMeals(dateFrom?: string, dateTo?: string): Promise<MenuGenerateResponse> {
        if(dateFrom && dateTo) {
            return ServiceLocator.ApiClient.Get(Urls.GET_MEALS+"?from="+dateFrom+"&to="+dateTo);
        } else {
            return ServiceLocator.ApiClient.Get(Urls.GET_MEALS);
        }
    }

    public PatchMeal(idMeal: number): Promise<MenuPatchResponse> {
        return ServiceLocator.ApiClient.Patch(Urls.PATCH_MEALS+"/"+idMeal+"/redraw", "");
    }

    public AddGuest(request: GuestsProfileRequest): Promise<EmptyApiResponse> {
        return ServiceLocator.ApiClient.Post(Urls.ADD_GUEST, request);
    }

    public EditGuest(request: GuestsProfileRequest, id: number): Promise<EmptyApiResponse> {
        return ServiceLocator.ApiClient.Put(Urls.EDIT_GUEST+"/"+id, request);
    }

    public DeleteGuest(id: number): Promise<EmptyApiResponse> {
        return ServiceLocator.ApiClient.Delete(Urls.DELETE_GUEST+"/"+id, null);
    }


    // public ApiGetPerson(): Promise<GetPersonResponse[]> {
    //     return ServiceLocator.ApiClient.Get<GetPersonResponse[]>(Urls.GET_PERSONS);
    // }
}