import EmptyApiResponse from "src/api/dtos/EmptyApiResponse";
import { HANDLE_ERROR, HANDLE_SUCCESS, PageActionTypes, RESET_ERROR, RESET_MESSAGE } from "./types";

export function HandleError(response: EmptyApiResponse, message?: string) : PageActionTypes {
    return {
        type: HANDLE_ERROR,
        response: response,
		message: message,
    };
}

export function HandleSuccess(message?: string, duration: number = 3000) : PageActionTypes {
	return {
		type: HANDLE_SUCCESS,
		duration: duration,
		message: message,
	}
}

export function ResetError() : PageActionTypes {
    return {
        type: RESET_ERROR
    };
}

export function ResetMessage() : PageActionTypes {
	return {
		type: RESET_MESSAGE
	}
}
