import * as React from "react";
import { FromStore } from "src/helpers/Store";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ExecuteOnLoad } from "src/helpers/Lifecycle";
import useStrings from "src/localization/Localization";
import { DeclareState } from "src/helpers/State";
import * as DateFns from 'date-fns';

import { Row, Col, Card } from "react-bootstrap";
import { Select, MenuItem, SelectChangeEvent, IconButton } from '@mui/material';

import MenuButton from "src/components/bases/MenuButton";
import Footer from "src/components/bases/Footer";
import AvatarLetter from "src/components/bases/AvatarLetter";
import Avatar from '@mui/material/Avatar';
import ButtonCustom from "src/components/bases/ButtonCustom";
import { GuestsProfile, PhysicalActivityLevel } from "src/api/dtos/user/UserProfile";
import { GuestGroup, Allergens } from "src/api/dtos/user/UserProfile";
import { ApiEditGuest, ApiGetUserProfile } from "src/stores/apis/user/actions";
import { useCheckMobileScreen } from "src/helpers/fonctions";

export interface MemberDetailPageProps { }

interface CustomizedState {
    dataGuest: GuestsProfile
}

const MemberDetailPage: React.FunctionComponent<MemberDetailPageProps> = (props) => {

    const _staticData = FromStore(state => state.static_data);
    let history = useHistory();
    const location = useLocation();
    const _strings = useStrings();
    const _dispatch = useDispatch();
    const mobileScreen = useCheckMobileScreen();

    const allergensArray: number[] = [];
    const [allergensId, setAllergensId] = React.useState(allergensArray);
    const allergensActive: boolean[] = [];
    const [activeAllergen, setActiveAllergen] = React.useState(allergensActive);
    
    const historyState = location.state as CustomizedState;
    let dataGuest = {} as GuestsProfile;
    if (historyState != undefined) {
        dataGuest = historyState.dataGuest;
    }

    let guestAllergen = dataGuest.allergens as unknown as Allergens[];
    let activeAllergenList = new Array();

    


    ExecuteOnLoad(() => {
        _dispatch(ApiGetUserProfile());
    }, true);

    ExecuteOnLoad(() => {
        let allergensIdList: number[] = [];
        [..._staticData.allergens].map((val: any, index: any) => {
            activeAllergenList[val.id] = false;
            if (guestAllergen) {
                [...guestAllergen].map((valGuestAllergen: any, indexGuestAllergen: any) => {
                    if (valGuestAllergen.id === val.id) {
                        allergensIdList.push(val.id);
                        activeAllergenList[val.id] = true;
                    }
                })
            }
        });
        setActiveAllergen(activeAllergenList);
        setAllergensId(allergensIdList);

        _state.Update({
            activeAllergen: activeAllergenList
        });

    }, false);

    const _state = DeclareState({
        activeGuestGroupButton: {
            [GuestGroup.FamilyMember]: (dataGuest.group === GuestGroup.FamilyMember) ? true : false,
            [GuestGroup.RegularGuest]: (dataGuest.group === GuestGroup.RegularGuest) ? true : false,
        },
        activeAllergen: activeAllergen,
        physicalActivity: dataGuest.physical_activity_level,
        allergens: dataGuest.allergens,
        image: dataGuest.picture_file,//dataGuest.picture_file ? Buffer.from(dataGuest.picture_file, 'base64').toString() : "",
    });

    const handleGroupGuestClicked = (event: React.SyntheticEvent) => {
        event.preventDefault();

        let id = event.currentTarget.id;
        switch (id) {
            case GuestGroup.FamilyMember:
                _state.Update({
                    activeGuestGroupButton: {
                        [GuestGroup.FamilyMember]: true,
                        [GuestGroup.RegularGuest]: false,
                    }
                });
                break;
            case GuestGroup.RegularGuest: {
                _state.Update({
                    activeGuestGroupButton: {
                        [GuestGroup.FamilyMember]: false,
                        [GuestGroup.RegularGuest]: true,
                    }
                });
            }
        }

        let dataToSend = dataGuest;
        dataToSend.group = id as GuestGroup;

        _dispatch(ApiEditGuest(dataToSend));
    }

    const handlePhysicalActivityChange = (event: SelectChangeEvent) => {
        _state.Update({
            physicalActivity: event.target.value as PhysicalActivityLevel
        })

        let dataToSend = dataGuest;
        dataToSend.physical_activity_level = event.target.value as PhysicalActivityLevel;

        _dispatch(ApiEditGuest(dataToSend));
    }

    const handleAllergenClicked = (event: React.SyntheticEvent) => {
        event.preventDefault();

        let allergenId: number = Number(event.currentTarget.id);
        _state.Update({
            activeAllergen:
            {
                ..._state.content.activeAllergen,
                [allergenId]: !_state.content.activeAllergen[allergenId]
            }
        });

        let actAll = activeAllergen;
        actAll[allergenId] = !_state.content.activeAllergen[allergenId];
        setActiveAllergen(actAll);

        let allergensTab = allergensId;
        if (actAll[allergenId]) {
            allergensTab.push(allergenId);
        } else {
            const index = allergensTab.indexOf(allergenId);
            allergensTab.splice(index, 1);
        }
        setAllergensId(allergensTab.filter(e => e));

        _dispatch(ApiEditGuest(dataGuest, allergensTab.filter(e => e)));
    }

    function handleEditPerson(event: React.MouseEvent<Element>, id_guest: number | undefined) {
        event.stopPropagation();

        if (id_guest) {
            history.push({
                pathname: "/memberadd",
                state: { dataGuest: dataGuest }
            })
        }
    }

    function render(): JSX.Element {
        return (
            <div>
                <MenuButton selected={2}></MenuButton>

                <div className="marginPages" >
                    <Row md={2} style={{ alignItems: "flex-start" }} >
                        <Col style={{ width: (mobileScreen ? "100%" : "30%") }}>
                            <Card style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "2px", padding: "25px", boxShadow: "0 2px 15px 1px rgba(0, 0, 0, 0.03)" }}>

                                <Card.ImgOverlay style={{ padding: 0, left: '88%' }}>
                                    <IconButton style={{ borderRadius: '2px', margin: '5px', backgroundColor: 'rgba(255, 255, 255, 0.8)' }} onClick={(e: React.MouseEvent) => handleEditPerson(e, dataGuest.id)} >
                                        <img width="14px" height="14px" src="/images/edit_profile.png" />
                                    </IconButton>
                                </Card.ImgOverlay>

                                <div style={{ margin: "24px", marginBottom: "15px" }}>
                                    {
                                        _state.content.image ?
                                            <Avatar alt={`${dataGuest.last_name} ${dataGuest.first_name}`} src={_state.content.image} sx={{ width: 140, height: 140 }} />
                                            :
                                            <AvatarLetter fullName={`${dataGuest.first_name} ${dataGuest.last_name}`} className={""} style={{ width: "140px", height: "140px" }} />
                                    }
                                </div>

                                <div style={{ fontSize: "16px", fontWeight: "bold" }}>{dataGuest.first_name} {dataGuest.last_name}</div>
                                <div style={{ color: "#9c9393" }}>
                                    <span>{_strings.tr(dataGuest.gender)}</span>
                                    <span style={{ fontWeight: "bold", fontSize: "16px", margin: "0px 3px" }}>.</span>
                                    <span>{DateFns.differenceInYears(new Date(), new Date(dataGuest.birth_date))} {_strings.year_old}</span>
                                    <span style={{ fontWeight: "bold", fontSize: "16px", margin: "0px 3px" }}>.</span>
                                    <span>{(dataGuest.height / 100)} {_strings.meters}</span>
                                    <span style={{ fontWeight: "bold", fontSize: "16px", margin: "0px 3px" }}>.</span>
                                    <span>{dataGuest.weight} {_strings.kg}</span>
                                </div>

                                <Row md={6} style={{ cursor: "pointer", padding: 0, margin: "15px 8%", width: "-webkit-fill-available" }} >
                                    <span onClick={handleGroupGuestClicked} id={GuestGroup.FamilyMember} className={_state.content.activeGuestGroupButton[GuestGroup.FamilyMember] ? "guestGroupButtonActive" : "guestGroupButton"}>{_strings.tr(GuestGroup.FamilyMember)}</span>
                                    <span onClick={handleGroupGuestClicked} id={GuestGroup.RegularGuest} className={_state.content.activeGuestGroupButton[GuestGroup.RegularGuest] ? "guestGroupButtonActive" : "guestGroupButton"}>{_strings.tr(GuestGroup.RegularGuest)}</span>
                                </Row>

                                <div style={{ margin: "15px", textAlign: "center" }}>{_strings.physical_activity}</div>

                                <Select label={_strings.physical_activity}
                                    value={_state.content.physicalActivity}
                                    onChange={handlePhysicalActivityChange}
                                    style={{ width: "-webkit-fill-available", color: "#9c9393", textAlign: "center", boxShadow: "0 7px 12px 0 rgba(207, 208, 209, 0.35)", margin: "0px 8% 15px 8%" }}
                                    sx={{
                                        "& .MuiSelect-icon": {
                                            color: "#fd694d"
                                        }
                                    }} >
                                    {
                                        Object.keys(PhysicalActivityLevel).map((val: any, index: any) => {
                                            return (<MenuItem key={index} value={val}>{_strings.tr(val)}</MenuItem>)
                                        })
                                    }

                                </Select>

                                {/* <Avatar
                                alt="Remy Sharp"
                                // src="/images/foyer/no-picture2.jpg"
                                sx={{ width: "140px", height: "140px", marginTop:"24px" }}
                            /> */}

                            </Card>
                        </Col>

                        <Col style={{ width: "69%" }}>
                            <Card style={{ borderRadius: "2px", boxShadow: "0 2px 15px 1px rgba(0, 0, 0, 0.03)" }}>
                                <Card.Title style={{ textAlign: "center", marginTop: "3%", fontSize: "initial", fontWeight: "bold" }}>{_strings.allergens_title}</Card.Title>
                                <Card.Text>
                                    <Row xs={mobileScreen ? 2 : undefined} md={mobileScreen ? undefined : 8} style={{ margin: 0, padding: "2%" }} >
                                        {
                                            
                                            [..._staticData.allergens].map((val: any, index: any) => {
                                                let allergenName = val.name.replace(/ \([\s\S]*?\)/g, '');
                                                const lactoseAllergen = _staticData.allergens.find((allergen) => allergen.name === "Lait");
                        

                                                if (lactoseAllergen) {
                                                    lactoseAllergen.name = "Lactose";
                                                  }
                                                  
                                                
                                                return (
                                                    <div key={index} style={{ marginBottom: "2%" }} >
                                                        <div id={val.id} className={_state.content.activeAllergen[val.id] == true ? "allergen_circle allergen_circle_blocked" : "allergen_circle"} onClick={handleAllergenClicked}>
                                                            <div className={_state.content.activeAllergen[val.id] == true ? "blocked" : undefined}></div>
                                                            <div className="allergen_label">{allergenName}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {/* <div className="allergen_circle allergen_circle_blocked">
                                        <div className="blocked"></div>
                                        <div className="allergen_label">Arachides</div>
                                    </div>
                                    <div className="allergen_circle">
                                        <div className="allergen_label">Anhydride sulfureux</div>
                                    </div>
                                */}

                                    </Row>
                                </Card.Text>
                            </Card>
                        </Col>
                    </Row >

                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <ButtonCustom style={{ backgroundColor: "#8fc740" }} onClick={() => { history.push('/household') }} label={_strings.back_to_household}></ButtonCustom>
                    </div>

                </div>

                <Footer></Footer>
            </div >
        );
    }

    return render();
};

export default MemberDetailPage;