import * as React from "react";
import useStrings from "src/localization/Localization";
import { DeclareState } from "src/helpers/State";
import { ExecuteOnLoad } from "src/helpers/Lifecycle";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as DateFns from "date-fns";

import MessagesInPage from "src/components/bases/MessagesInPage";
import ButtonCustom from "src/components/bases/ButtonCustom";

import { Box, TextField, MenuItem, InputAdornment } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import MenuButton from "src/components/bases/MenuButton";
import Footer from "src/components/bases/Footer";
import {
	Gender,
	GuestGroup,
	GuestsProfile,
	PhysicalActivityLevel,
} from "src/api/dtos/user/UserProfile";
import {
	ApiAddGuest,
	ApiEditGuest,
	ApiGetUserProfile,
} from "src/stores/apis/user/actions";
import { HandleError } from "src/stores/page/actions";
import ErrorCode from "src/api/dtos/ErrorCode";
import { fr } from "date-fns/locale";
import {
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
	FormLabel,
} from "@mui/material";
import { useCheckMobileScreen } from "src/helpers/fonctions";

// import Avatar from 'react-avatar-edit'

export interface ProfileAddPageProps {}

interface CustomizedState {
	dataGuest: GuestsProfile;
}

const MemberAddPage: React.FunctionComponent<ProfileAddPageProps> = (props) => {
	const _strings = useStrings();
	let history = useHistory();
	const location = useLocation();
	const _dispatch = useDispatch();
	const mobileScreen = useCheckMobileScreen();

	const historyState = location.state as CustomizedState;
	let dataGuest = {} as GuestsProfile;
	if (historyState != undefined) {
		dataGuest = historyState.dataGuest;
	}
	const [date, setDate] = React.useState<Date | null>(
		dataGuest.birth_date ? new Date(dataGuest.birth_date) : null
	);

	ExecuteOnLoad(() => {
		_dispatch(ApiGetUserProfile());
	}, true);

	function getLang() {
		if (navigator.languages != undefined) return navigator.languages[0];
		return navigator.language;
	}

	const locale = getLang().startsWith("fr") ? fr : undefined;

	const _state = DeclareState({
		firstName: dataGuest.first_name ? dataGuest.first_name : "",
		lastName: dataGuest.last_name ? dataGuest.last_name : "",
		pregnant: dataGuest.is_pregnant ? dataGuest.is_pregnant : false,
		breastfeeding: dataGuest.is_breast_feeding
			? dataGuest.is_breast_feeding
			: false,
		birthDate: dataGuest.birth_date ? dataGuest.birth_date : "",
		weight: dataGuest.weight ? dataGuest.weight : 0,
		height: dataGuest.height ? dataGuest.height : 0,
		physicalActivity: dataGuest.physical_activity_level
			? dataGuest.physical_activity_level
			: "",
		gender: dataGuest.gender ? dataGuest.gender : "",
		group: dataGuest.group ? dataGuest.group : "",
		activeGuestGroupButton: {
			[GuestGroup.FamilyMember]:
				dataGuest.group == GuestGroup.FamilyMember ? true : false,
			[GuestGroup.RegularGuest]:
				dataGuest.group == GuestGroup.RegularGuest ? true : false,
		},
		activeGenderButton: {
			[Gender.Man]: dataGuest.gender == Gender.Man ? true : false,
			[Gender.Woman]: dataGuest.gender == Gender.Woman ? true : false,
		},
		allergens: dataGuest.allergens ? dataGuest.allergens : [],
		// image: dataGuest.picture_file ? Buffer.from(dataGuest.picture_file, 'base64').toString() : "",
		imageToBase64: dataGuest.picture_file ?? "",
		imageName: "",
	});

	const handlePhysicalActivityChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		event.preventDefault();

		_state.Update({
			physicalActivity: event.target.value as PhysicalActivityLevel,
		});
	};

	function handleFirstNameOnChange(
		event: React.ChangeEvent<HTMLInputElement>
	) {
		event.preventDefault();

		_state.Update({
			firstName: event.target.value,
		});
	}

	function handleLastNameOnChange(
		event: React.ChangeEvent<HTMLInputElement>
	) {
		event.preventDefault();

		_state.Update({
			lastName: event.target.value,
		});
	}

	function handleHeightOnChange(event: React.ChangeEvent<HTMLInputElement>) {
		event.preventDefault();

		_state.Update({
			height: Number(event.target.value),
		});
	}

	function handleWeightOnChange(event: React.ChangeEvent<HTMLInputElement>) {
		event.preventDefault();

		_state.Update({
			weight: Number(event.target.value),
		});
	}

	function handleRadioPregnantChange(
		event: React.ChangeEvent<HTMLInputElement>
	) {
		event.preventDefault();

		_state.Update({
			pregnant: event.target.value === "true",
		});
	}

	function handleRadioBreastfeedingChange(
		event: React.ChangeEvent<HTMLInputElement>
	) {
		event.preventDefault();

		_state.Update({
			breastfeeding: event.target.value === "true",
		});
	}

	const handleGroupGuestClicked = (event: React.SyntheticEvent) => {
		event.preventDefault();

		let id = event.currentTarget.id;
		switch (id) {
			case GuestGroup.FamilyMember:
				_state.Update({
					group: GuestGroup.FamilyMember,
					activeGuestGroupButton: {
						[GuestGroup.FamilyMember]: true,
						[GuestGroup.RegularGuest]: false,
					},
				});
				break;
			case GuestGroup.RegularGuest: {
				_state.Update({
					group: GuestGroup.RegularGuest,
					activeGuestGroupButton: {
						[GuestGroup.FamilyMember]: false,
						[GuestGroup.RegularGuest]: true,
					},
				});
			}
		}
	};

	const handleDayClick = (date: any) => {
		_state.Update({ birthDate: date });
		setDate(date);
	};

	const handleGenderClicked = (event: React.SyntheticEvent) => {
		event.preventDefault();

		let id = event.currentTarget.id;
		switch (id) {
			case Gender.Man:
				_state.Update({
					gender: Gender.Man,
					activeGenderButton: {
						[Gender.Man]: true,
						[Gender.Woman]: false,
					},
				});
				break;
			case Gender.Woman: {
				_state.Update({
					gender: Gender.Woman,
					activeGenderButton: {
						[Gender.Man]: false,
						[Gender.Woman]: true,
					},
				});
			}
		}
	};

	function validateForm() {
		return (
			_state.content.group.length > 0 &&
			_state.content.gender.length > 0 &&
			new Date(_state.content.birthDate) instanceof Date
		);
	}

	function handleSubmitAddMember(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		if (validateForm()) {
			let dataGuestNew = {} as GuestsProfile;
			dataGuestNew.picture_file = _state.content.imageToBase64
				? String(_state.content.imageToBase64)
				: "";
			dataGuestNew.first_name = _state.content.firstName;
			dataGuestNew.last_name = _state.content.lastName;
			dataGuestNew.gender = _state.content.gender as Gender;
			dataGuestNew.is_pregnant = _state.content.pregnant;
			dataGuestNew.is_breast_feeding = _state.content.breastfeeding;
			dataGuestNew.birth_date = DateFns.format(
				new Date(_state.content.birthDate),
				"yyyy-MM-dd"
			);
			dataGuestNew.height = _state.content.height;
			dataGuestNew.weight = _state.content.weight;
			dataGuestNew.physical_activity_level = _state.content
				.physicalActivity as PhysicalActivityLevel;
			dataGuestNew.group = _state.content.group as GuestGroup;
			dataGuestNew.allergens = _state.content.allergens;

			if (Object.keys(dataGuest).length > 0) {
				dataGuestNew.id = dataGuest.id;
				_dispatch(ApiEditGuest(dataGuestNew));
			} else {
				_dispatch(ApiAddGuest(dataGuestNew));
			}

			setTimeout(() => {
				history.push("/household");
			}, 2000);
		} else {
			_dispatch(
				HandleError({
					is_success: false,
					error_code: ErrorCode.EMPTY_FIELDS,
					error_message: "",
				})
			);
		}
	}

	const convertToBase64 = (file: any) => {
		return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);
			fileReader.onload = () => {
				resolve(fileReader.result);
			};
			fileReader.onerror = (error) => {
				reject(error);
			};
		});
	};

	const handleOnImageChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		event.preventDefault();

		if (event.target.files && event.target.files[0]) {
			let img = event.target.files[0];
			let imageToBase64 = await convertToBase64(img);
			// let imageToBase64 = Buffer.from(URL.createObjectURL(img)).toString("base64");

			_state.Update({
				// image: URL.createObjectURL(img),
				imageToBase64: String(imageToBase64),
				imageName: img.name,
			});
		}
	};

	function render(): JSX.Element {
		return (
			<div>
				<MenuButton selected={2}></MenuButton>

				<MessagesInPage></MessagesInPage>

				<div className="marginPages">
					<fieldset>
						<legend
							style={{
								fontSize: "large",
								fontWeight: "bold",
								textAlign: "center",
								marginBottom: "15px",
							}}
						>
							{dataGuest.id
								? _strings.edit_member
								: _strings.add_member}
						</legend>
						<div
							style={{
								margin: "auto",
								width: mobileScreen ? "100%" : "50%",
							}}
						>
							<Box
								component="form"
								sx={{
									"& .MuiTextField-root": { m: 1 },
								}}
								onSubmit={handleSubmitAddMember}
							>
								<div>
									<div style={{ display: "flex" }}>
										<div style={{ width: "50%" }}>
											<span>
												{_strings.profile_photo}
											</span>
											<div
												style={{
													display: "flex",
													marginTop: "10px",
												}}
											>
												<label
													style={{
														border: "1px solid #ccc",
														display: "inline-block",
														padding: "6px 12px",
														cursor: "pointer",
													}}
												>
													<input
														type="file"
														onChange={
															handleOnImageChange
														}
														accept=".jpeg, .png, .jpg"
														style={{
															display: "none",
														}}
													/>
													<i
														className="fa fa-cloud-upload"
														aria-hidden="true"
													/>{" "}
													{_strings.attach}
												</label>

												<div
													style={{ margin: "0 10px" }}
												>
													{_state.content.imageName}
												</div>
											</div>
										</div>
										<div style={{ margin: "auto" }}>
											{String(
												_state.content.imageToBase64
											) ? (
												<img
													src={String(
														_state.content
															.imageToBase64
													)}
													style={{
														width: "140px",
														height: "140px",
														borderRadius: "5%",
													}}
												/>
											) : null}
										</div>
										{/* <Avatar
                                            width={390}
                                            height={295}
                                            src={_state.content.imageSrc}
                                        />
                                        <img src={_state.content.preview} alt="Preview" /> */}
									</div>
									<div
										style={{
											cursor: "pointer",
											padding: 0,
											display: "flex",
											margin: "8px",
										}}
									>
										<span
											onClick={handleGroupGuestClicked}
											id={GuestGroup.FamilyMember}
											className={
												_state.content
													.activeGuestGroupButton[
													GuestGroup.FamilyMember
												]
													? "guestGroupButtonActive"
													: "guestGroupButton"
											}
										>
											{_strings.tr(
												GuestGroup.FamilyMember
											)}
										</span>
										<span
											onClick={handleGroupGuestClicked}
											id={GuestGroup.RegularGuest}
											className={
												_state.content
													.activeGuestGroupButton[
													GuestGroup.RegularGuest
												]
													? "guestGroupButtonActive"
													: "guestGroupButton"
											}
										>
											{_strings.tr(
												GuestGroup.RegularGuest
											)}
										</span>
									</div>
									<div>
										<TextField
											required
											id="first_name"
											label={_strings.first_name}
											onChange={handleFirstNameOnChange}
											value={_state.content.firstName}
											variant="standard"
											fullWidth
										/>
									</div>
									<div>
										<TextField
											id="last_name"
											label={_strings.last_name}
											onChange={handleLastNameOnChange}
											value={_state.content.lastName}
											variant="standard"
											fullWidth
										/>
									</div>
									<div
										style={{
											cursor: "pointer",
											padding: 0,
											display: "flex",
											margin: "8px",
										}}
									>
										<span
											onClick={handleGenderClicked}
											id={Gender.Man}
											className={
												_state.content
													.activeGenderButton[
													Gender.Man
												]
													? "guestGroupButtonActive"
													: "guestGroupButton"
											}
										>
											{_strings.tr(Gender.Man)}
										</span>
										<span
											onClick={handleGenderClicked}
											id={Gender.Woman}
											className={
												_state.content
													.activeGenderButton[
													Gender.Woman
												]
													? "guestGroupButtonActive"
													: "guestGroupButton"
											}
										>
											{_strings.tr(Gender.Woman)}
										</span>
									</div>
									{_state.content.gender == Gender.Woman ? (
										<div>
											<div
												style={{
													margin: "8px",
													display: "inline-block",
													width: "50%",
												}}
											>
												<FormControl component="fieldset">
													<FormLabel component="legend">
														{_strings.pregnant} ?
													</FormLabel>
													<RadioGroup
														row
														aria-label="pregnant"
														name="row-radio-buttons-group-pregnant"
														value={String(
															_state.content
																.pregnant
														)}
														onChange={
															handleRadioPregnantChange
														}
													>
														<FormControlLabel
															value={"true"}
															control={<Radio />}
															label={_strings.yes}
														/>
														<FormControlLabel
															value={"false"}
															control={<Radio />}
															label={_strings.no}
														/>
													</RadioGroup>
												</FormControl>
											</div>
											<div
												style={{
													margin: "8px",
													display: "inline-block",
												}}
											>
												<FormControl component="fieldset">
													<FormLabel component="legend">
														{_strings.breastfeeding}{" "}
														?
													</FormLabel>
													<RadioGroup
														row
														aria-label="breastfeeding"
														name="row-radio-buttons-group-breastfeeding"
														value={String(
															_state.content
																.breastfeeding
														)}
														onChange={
															handleRadioBreastfeedingChange
														}
													>
														<FormControlLabel
															value={"true"}
															control={<Radio />}
															label={_strings.yes}
														/>
														<FormControlLabel
															value={"false"}
															control={<Radio />}
															label={_strings.no}
														/>
													</RadioGroup>
												</FormControl>
											</div>
										</div>
									) : null}
									<div>
										<LocalizationProvider
											dateAdapter={AdapterDateFns}
											adapterLocale={locale}
										>
											<DatePicker
												label={_strings.birth_date}
												value={date}
												onChange={handleDayClick}
												minDate={new Date(1950, 1, 1)}
												maxDate={new Date()}
												renderInput={(params: any) => (
													<TextField
														{...params}
														style={{
															width: "100%",
														}}
													/>
												)}
											/>
										</LocalizationProvider>
									</div>

									<div>
										<TextField
											required
											type="number"
											label={_strings.weight}
											placeholder={_strings.weight}
											onChange={handleWeightOnChange}
											sx={{
												m: 1,
												width: "calc(50% - 16px)",
											}}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														kg
													</InputAdornment>
												),
											}}
											value={_state.content.weight}
											variant="standard"
										/>

										<TextField
											required
											type="number"
											label={_strings.height}
											sx={{
												m: 1,
												width: "calc(50% - 16px)",
											}}
											placeholder={_strings.height}
											onChange={handleHeightOnChange}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														cm
													</InputAdornment>
												),
											}}
											value={_state.content.height}
											variant="standard"
										/>
									</div>

									<div>
										<TextField
											required
											select
											label={_strings.physical_activity}
											value={
												_state.content.physicalActivity
											}
											onChange={
												handlePhysicalActivityChange
											}
											placeholder={
												_strings.physical_activity
											}
											variant="standard"
											fullWidth
											sx={{
												m: 1,
												// width: '25ch',
												"& .MuiSelect-icon": {
													color: "#fd694d",
												},
											}}
											// style={{ color: "#9c9393", textAlign: "center", boxShadow: "0 7px 12px 0 rgba(207, 208, 209, 0.35)" }}
										>
											{Object.keys(
												PhysicalActivityLevel
											).map((val: any, index: any) => {
												return (
													<MenuItem
														key={index}
														value={val}
													>
														{_strings.tr(val)}
													</MenuItem>
												);
											})}
										</TextField>
									</div>
								</div>

								<div
									style={{
										display: "flex",
										justifyContent: "flex-end",
									}}
								>
									<ButtonCustom
										type="submit"
										style={{ backgroundColor: "#8fc740" }}
										label={_strings.validate}
									></ButtonCustom>
								</div>
							</Box>
						</div>
					</fieldset>
				</div>

				<Footer></Footer>
			</div>
		);
	}

	return render();
};

export default MemberAddPage;
