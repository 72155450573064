import * as React from 'react';
import useStrings from "src/localization/Localization";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { Col, Card } from "react-bootstrap";
import { CardActionArea } from '@mui/material';
import { IconButton } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { ApiGetUserProfile, ApiDeleteGuest } from "src/stores/apis/user/actions";
import { GuestGroup, GuestsProfile } from 'src/api/dtos/user/UserProfile';

type CardHouseholdProps = {
    dataGuest?: GuestsProfile,
    fullname?: string
    picture?: string,
    isUser?: boolean,
    add?: GuestGroup | null,
};

const defaultProps: CardHouseholdProps = {
    fullname: "",
    picture: "no-picture.jpg",
    isUser: false,
    add: null,
}

const CardHousehold: React.FunctionComponent<CardHouseholdProps> = props => {
    const _strings = useStrings();
    const _dispatch = useDispatch();
    let history = useHistory();

    // const avatarImg = props.picture ? Buffer.from(props.picture, 'base64').toString() : null
    const avatarImg = props.picture;
    const picture = (avatarImg && props.picture ? avatarImg : "/images/foyer/" + defaultProps.picture);

    function handleDeletePerson(event: React.MouseEvent<Element>, id_guest: number | undefined) {
        event.stopPropagation();

        if (id_guest) {
            _dispatch(ApiDeleteGuest(id_guest));
            _dispatch(ApiGetUserProfile());
        }
    }

    const handleClickAddPerson = (event: React.SyntheticEvent) => {
        event.preventDefault();

        history.push('/memberadd');
    }

    const handleClickGetPerson = (event: React.SyntheticEvent) => {
        event.preventDefault();

        history.push({
            pathname: "/memberdetail",
            state: { dataGuest: props.dataGuest }
        });
    }


    function render(): JSX.Element {
        if (props.add) {
            return (
                <Col style={{ padding: "0", margin: "1% 0" }}>
                    <Card onClick={handleClickAddPerson} style={{ cursor: "pointer", border: "solid 1px #9c9393", color: "#9c9393", fontWeight: "bold", width: '170px', height: '170px', textAlign: "center" }}>
                        <Card.Text as={'div'} style={{ margin: '35px 18px 0 18px' }}>
                            <div>
                                <IconButton style={{ color: '#fd694d' }} size='small' aria-label="add" onClick={handleClickAddPerson}>
                                    <AddCircleOutlineIcon />
                                </IconButton>
                            </div>
                            <div>{props.add == GuestGroup.FamilyMember ? _strings.add_person_household : _strings.add_person_guest}</div>
                        </Card.Text>
                    </Card>
                </Col>
            );
        } else {
            return (
                <Col style={{ padding: "0", margin: "1% 0" }}>
                    <Card style={{ cursor: "pointer", boxShadow: "0 2px 15px 1px rgba(0, 0, 0, 0.1)", width: '170px', height: '170px' }}>
                        <CardActionArea onClick={handleClickGetPerson} >
                            <Card.Img variant="top" src={picture} style={{ height: "170px" }}></Card.Img>

                            <Card.ImgOverlay style={{ padding: 0, left: '72%' }}>
                                <CardActionArea component={"div"} >
                                    <IconButton component={"div"} onClick={(e: React.MouseEvent) => handleDeletePerson(e, props.dataGuest!.id)} style={{ borderRadius: '2px', margin: '5px', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                                        <img src="/images/trash.svg" />
                                    </IconButton>
                                </CardActionArea>
                            </Card.ImgOverlay>

                            <Card.ImgOverlay style={{
                                padding: '0',
                                color: "white",
                                position: "relative"
                            }} >
                                <Card.Title className="CardImgOverlayTitle">
                                    <span style={{ alignSelf: "flex-end", paddingBottom: "10px" }}>{props.fullname}</span>
                                </Card.Title>
                            </Card.ImgOverlay>
                        </CardActionArea>
                    </Card>
                </Col>
            );
        }
    }

    return render();
};

CardHousehold.defaultProps = defaultProps;

export default CardHousehold;