import * as React from "react";

import useStrings from "src/localization/Localization";

import CardMenuDay from "src/components/bases/CardMenuDay";

import { Row } from "react-bootstrap";
import { Meal } from "src/api/dtos/menu/MenuProfile";
import { useCheckMobileScreen } from "src/helpers/fonctions";

type MenuWeekProps = {
    weekNumber?: number,
    dayToStart?: number,
    elements: object
};

const MenuWeek: React.FunctionComponent<MenuWeekProps> = props => {

    const _strings = useStrings();
    const mobileScreen = useCheckMobileScreen();

    let rangeDays = Array.from({ length: 7 }, (_, i) => i + 1);
    // let weekdayNow = [_strings.sunday, _strings.monday, _strings.thuesday, _strings.wednesday,_strings.thursday, _strings.friday, _strings.saturday][new Date().getDay()];
    let weekday = [_strings.sunday, _strings.monday, _strings.thuesday, _strings.wednesday, _strings.thursday, _strings.friday, _strings.saturday, _strings.sunday];
    let headerBgColor = ["#00a2e1", "#096639", "#8fc740", "#f2600d", "#e51109", "#692480", "#0067b2", "#00a2e1"];

    // let d = props.elements[Meal.Lunch] ? new Date(props.elements[Meal.Lunch][0].date) : new Date();
    // const dayToStart = d.getDay();

    function render(): JSX.Element {
        return (
            <div>
                <div>
                    <Row md={mobileScreen ? 2 : 8} style={{ alignItems: "center" }} >
                        <div style={{ width: "110px", textAlign: "center", color: "#9c9393" }} >{_strings.Lunch}</div>
                        {
                            [...rangeDays].map((valDay: number, indexDay: number) => {
                                if(props.elements[Meal.Lunch][valDay]) {
                                    return (<CardMenuDay key={indexDay} weekNumber={props.weekNumber} dayName={weekday[valDay]} dayNumber={valDay} headerBgColor={headerBgColor[valDay]} elements={props.elements[Meal.Lunch][valDay]} ></CardMenuDay>)
                                }else{
                                    return (<CardMenuDay key={indexDay} isEmpty={true} weekNumber={props.weekNumber} dayName={weekday[valDay]} dayNumber={valDay} headerBgColor={headerBgColor[valDay]} ></CardMenuDay>)
                                }
                                // if (props.weekNumber == 1 && valDay < props.dayToStart!) {
                                if (valDay < props.dayToStart!) {
                                    return (<CardMenuDay key={indexDay} isEmpty={true} weekNumber={props.weekNumber} dayName={weekday[valDay]} dayNumber={valDay} headerBgColor={headerBgColor[valDay]} ></CardMenuDay>)
                                } 
                                return;
                            })
                        }
                        {
                            
                                // (props.elements && props.elements[Meal.Lunch] ?
                                //     [...props.elements![Meal.Lunch]].map((val: any, index: number) => {
                                //         let dayNumber = new Date(val.date).getDay();

                                //         return (<CardMenuDay key={dayNumber} weekNumber={props.weekNumber} dayName={weekday[dayNumber]} dayNumber={dayNumber + 1} headerBgColor={headerBgColor[dayNumber]} imagePath={imagePath[dayNumber]} elements={val} ></CardMenuDay>);
                                //         // if (props.weekNumber >= 2 || dayNumber >= props.dayToStart! || dayNumber == 0) {
                                //         //     return (<CardMenuDay key={dayNumber} weekNumber={props.weekNumber} dayName={weekday[dayNumber]} dayNumber={dayNumber + 1} headerBgColor={headerBgColor[dayNumber]} imagePath={imagePath[dayNumber]} elements={val} ></CardMenuDay>);
                                //         // }
                                //         // return;
                                //     })
                                //     : null)
                            
                        }
                    </Row>
                </div>
                <div>
                    <Row md={mobileScreen ? 2 : 8} style={{ alignItems: "center" }} >
                        <div style={{ width: "110px", textAlign: "center", color: "#9c9393" }} >{_strings.Dinner}</div>
                        {
                            [...rangeDays].map((valDay: number, indexDay: number) => {
                                if(props.elements[Meal.Dinner][valDay]) {
                                    return (<CardMenuDay key={indexDay} weekNumber={props.weekNumber} dayName={weekday[valDay]} dayNumber={valDay} elements={props.elements[Meal.Dinner][valDay]} ></CardMenuDay>)
                                }else{
                                    return (<CardMenuDay key={indexDay} isEmpty={true} weekNumber={props.weekNumber} dayName={weekday[valDay]} dayNumber={valDay} ></CardMenuDay>)
                                }
                                // if (props.weekNumber == 1 && valDay < props.dayToStart!) {
                                // if (valDay < props.dayToStart!) {
                                //     return (<CardMenuDay key={indexDay} isEmpty={true} ></CardMenuDay>)
                                // }
                                // return;
                            })
                        }
                        {
                            // (props.elements && props.elements[Meal.Dinner] ?
                            //     [...props.elements![Meal.Dinner]].map((val: any, index: number) => {
                            //         let dayNumber = new Date(val.date).getDay();
                            //         return (<CardMenuDay key={dayNumber} weekNumber={props.weekNumber} dayName={weekday[dayNumber]} dayNumber={dayNumber + 1} imagePath={imagePath[dayNumber]} elements={val} ></CardMenuDay>);
                            //         // if (props.weekNumber >= 2 || dayNumber >= props.dayToStart! || dayNumber == 0) {
                            //         //     return (<CardMenuDay key={dayNumber} weekNumber={props.weekNumber} dayName={weekday[dayNumber]} dayNumber={dayNumber + 1} imagePath={imagePath[dayNumber]} elements={val} ></CardMenuDay>);
                            //         // }
                            //         // return;
                            //     })
                            //     : null)
                        }

                        {
                            // [...rangeDays].map((val, index) => (
                            //     (val >= (props.dayToStart! || 1)) ?
                            //         (<CardMenuDay key={index} weekNumber={props.weekNumber} dayName={weekday[index]} dayNumber={val} imagePath={imagePath[index]} elements={_menu[1]} ></CardMenuDay>)
                            //         :
                            //         (<CardMenuDay key={index} isEmpty={true} ></CardMenuDay>)
                            // )
                            // )
                        }
                    </Row>
                </div>
            </div>
        )
    }

    return render();

};

export default MenuWeek;