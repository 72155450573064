export default interface MenuProfil {
	date: string,
	meal: Meal,
	elements: ElementsMenu[]
}

interface Portions {
	guest_id: number,
	quantity: number,
	has_allergen_alert: boolean,
}

export interface ElementsMenu {
	id: number,
	added_adults: number,
	added_children: number,
	food_id: number,
	food_name: string,
	part: MealType,
	portions: Portions[],
	is_in_shopping_list: boolean,
}

export enum Meal {
	Lunch = "Lunch",
	Dinner = "Dinner"
}

export enum MealType {
	Starter = "Starter",
	Main = "Main",
	Accompaniment = "Accompaniment",
	Bread = "Bread",
	Cheese = "Cheese",
	Dessert = "Dessert",
	// Meal_accompaniment = "Meal accompaniment",
}