import * as React from "react";
import { DeclareState } from "src/helpers/State";
import { enGB, fr } from 'date-fns/locale';
import { DatePickerCalendar } from '@bjarkehs/react-nice-dates';
import '@bjarkehs/react-nice-dates/build/style.css';
import { useHistory } from 'react-router-dom';
import useStrings from "src/localization/Localization";
import ButtonCustom from "src/components/bases/ButtonCustom";

interface PlanningCalendarPickerPageState {
    date: Date | null
}

const PlanningCalendarPicker: React.FunctionComponent = (props) => {
    const _strings = useStrings();
    const history = useHistory();

    const _state = DeclareState<PlanningCalendarPickerPageState>({
        date: new Date(),
    });

    // const modifiers = {
    //     disabled: (date:Date) => date < new Date(), // Disables days before today
    // }

    const [date, setDate] = React.useState();

    const localeMap = {
        "en-US": enGB,
        "fr-FR": fr,
        "fr": fr,
    };

    function getLang() {
        if (navigator.languages != undefined) return navigator.languages[0];
        return navigator.language;
    }

    const handleDayClick = (date: any) => {
        _state.Update({ date: date });
        setDate(date);
    }

    const handleCalendarClicked = () => {
        let date = _state.content.date;

        history.push({
            pathname: "/planning",
            state: { dateStart: date }
        });
    }

    function render(): JSX.Element {
        return (
            <div>
                <div style={{ margin: "0px 10%" }}>
                    <DatePickerCalendar
                        date={date}
                        onDateChange={handleDayClick}
                        locale={localeMap[getLang()]}
                        // modifiers={modifiers}
                        minimumDate={new Date()}
                    />
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                    <ButtonCustom style={{backgroundColor:"#8fc740"}} onClick={handleCalendarClicked} label={_strings.next}></ButtonCustom>
                </div>
            </div>
        )
    }

    return render();
};

export default PlanningCalendarPicker;
