import * as React from "react";

import useStrings from "src/localization/Localization";
import { ExecuteOnLoad } from "src/helpers/Lifecycle";
import { FromStore } from "src/helpers/Store";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { GuestGroup } from "src/api/dtos/user/UserProfile";
import MenuButton from "src/components/bases/MenuButton";
import Footer from "src/components/bases/Footer";
import { Row } from "react-bootstrap";
import Badge from '@mui/material/Badge';
import { ApiGetUserProfile } from "src/stores/apis/user/actions";
import MessagesInPage from "src/components/bases/MessagesInPage";
import CardHousehold from "src/components/bases/CardHousehold";
import ButtonCustom from "src/components/bases/ButtonCustom";
import { useCheckMobileScreen } from "src/helpers/fonctions";
import { styled } from "@mui/material";

export interface HouseholdPageProps { }

const HouseholdPage: React.FunctionComponent<HouseholdPageProps> = (props) => {
    const _dispatch = useDispatch();
    let history = useHistory();
    const _strings = useStrings();
    const mobileScreen = useCheckMobileScreen();

    ExecuteOnLoad(() => {
        _dispatch(ApiGetUserProfile());
    }, true);

    const _persons = FromStore(state => state.apis.profile.user.guests);
    let guestList = new Array();
    if (_persons) {
        Object.entries(_persons).forEach(([index, guestInfo]) => {
            if (!guestList[guestInfo.group]) {
                guestList[guestInfo.group] = new Array();
            }
            guestList[guestInfo.group].push(guestInfo);
        });
    }

    const nbFamilyMember = guestList[GuestGroup.FamilyMember] ? guestList[GuestGroup.FamilyMember].length : 0;
    const nbRegularGuest = guestList[GuestGroup.RegularGuest] ? guestList[GuestGroup.RegularGuest].length : 0;


    const PersonBadge = styled(Badge)(
        ({ theme }) => `
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-variant: tabular-nums;
        list-style: none;
        position: relative;
        display: inline-block;
        line-height: 1;
        & .MuiBadge-badge {
          z-index: auto;
          position: absolute;
          top: 0;
          right: -15px;
          min-width: 22px;
          height: 22px;
          padding: 0 6px;
          color: #fff;
          font-weight: 600;
          font-size: 12px;
          line-height: 22px;
          white-space: nowrap;
          text-align: center;
          border-radius: 12px;
          background: #fd694d;
          box-shadow: 0px 4px 16px ${
            theme.palette.mode === 'dark' ? '#24292f' : '#bdbdbd'
          };
          transform: translate(50%, -50%);
          transform-origin: 100% 0;
        }
        `,
      );
    //     position: "relative",
    //     boxSizing: "border-box",
    //     margin: 0,
    //     padding: 0,
    //     color: "rgba(0, 0, 0, 0.85)",
    //     fontVariant: "tabular-nums",
    //     listStyle: "none",
    //     display: "inline-block",
    //     lineHeight: 1,
    //     "& .MuiBadge-badge": {
    //         zIndex: "auto",
    //         minWidth: "20px",
    //         height: "20px",
    //         padding: "0px 6px",
    //         color: "#fff",
    //         fontWeight: 'bold',
    //         lineHeight: "20px",
    //         whiteSpace: "nowrap",
    //         textAlign: "center",
    //         background: "#fd694d",
    //         borderRadius: "10px",
    //         boxShadow: "0 0 0 1px #fff",
    //     },
    //     "& .MuiBadge-anchorOriginTopRightCircular": {
    //         position: "absolute",
    //         transform: "translate(50%, -50%)",
    //         transformOrigin: '100% 0',
    //     }
    // }));

    function render(): JSX.Element {
        return (
            <div>
                <MenuButton selected={2}></MenuButton>
                <MessagesInPage></MessagesInPage>

                <div className="marginPages" >
                    <section style={{ margin: "20px 0px" }}>
                        <header>
                            <PersonBadge badgeContent={nbFamilyMember} showZero={true} >
                                {_strings.family_member}
                            </PersonBadge>
                        </header>
                        <Row md={mobileScreen ? 2 : 6} >
                            {
                                (guestList != undefined && guestList[GuestGroup.FamilyMember] != undefined) ?
                                    [...guestList[GuestGroup.FamilyMember]].map((val: any, index: any) => {
                                        return (<CardHousehold key={index} dataGuest={val} fullname={val.first_name + ' ' + val.last_name} picture={val.picture_file} isUser={false} />)
                                    })
                                    : null
                            }

                            <CardHousehold add={GuestGroup.FamilyMember} />
                        </Row >
                    </section>

                    <section style={{ margin: "20px 0px" }}>
                        <header>
                            <PersonBadge badgeContent={nbRegularGuest} showZero={true} /*invisible={nbRegularGuest == 0 ? true : false}*/ >
                                {_strings.regular_member}
                            </PersonBadge>
                        </header>
                        <Row md={mobileScreen ? 2 : 6} >
                            {
                                (guestList != undefined && guestList[GuestGroup.RegularGuest] != undefined) ?
                                    [...guestList[GuestGroup.RegularGuest]].map((val: any, index: any) => {
                                        return (<CardHousehold key={index} dataGuest={val} fullname={val.first_name + ' ' + val.last_name} picture={val.picture_file} isUser={false} />)
                                    })
                                    : null
                            }

                            <CardHousehold add={GuestGroup.RegularGuest} />
                        </Row >
                    </section>

                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <ButtonCustom style={{backgroundColor:"#8fc740"}}  onClick={() => { history.push('/eatinghabits') }} label={_strings.view_eating_habits}></ButtonCustom>
                    </div>
                </div>

                <Footer></Footer>
            </div >
        );
    }

    return render();
};

export default HouseholdPage;
