import * as React from "react";
import useStrings from "src/localization/Localization";

import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import CardPlanningDay from "src/components/bases/CardPlanningDay";

import { Row } from "react-bootstrap";
import { Meal } from "src/api/dtos/menu/MenuProfile";
import { useCheckMobileScreen } from "src/helpers/fonctions";

type PlanningWeekProps = {
    weekNumber: number,
    dayToStart?: number,
};

const PlanningWeek: React.FunctionComponent<PlanningWeekProps> = props => {
    const _strings = useStrings();
    const mobileScreen = useCheckMobileScreen();

    function ErrorFallback({ error }: FallbackProps) {
        return (
            <div role="alert">
                <p>Something went wrong:</p>
                <pre style={{ color: 'red' }}>{error.message}</pre>
            </div>
        )
    }

    let rangeDays = Array.from({ length: 7 }, (_, i) => i + 1);
    // let weekdayNow = [_strings.sunday, _strings.monday, _strings.thuesday, _strings.wednesday,_strings.thursday, _strings.friday, _strings.saturday][new Date().getDay()];
    let weekday = [_strings.monday, _strings.thuesday, _strings.wednesday, _strings.thursday, _strings.friday, _strings.saturday, _strings.sunday];
    let headerBgColor = ["#096639", "#8fc740", "#f2600d", "#e51109", "#692480", "#0067b2", "#00a2e1"];

    function render(): JSX.Element {
        return (
            <div>
                <div>
                    <Row md={mobileScreen ? 2 : 8} style={{ alignItems: "center" }} >
                        <div style={{ width: "110px", textAlign: "center", color: "#9c9393" }} >{_strings.Lunch}</div>
                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                            {
                                [...rangeDays].map((val, index) => (

                                    (val >= (props.dayToStart! || 1)) ?
                                        (<CardPlanningDay key={index} isEmpty={false} weekNumber={props.weekNumber} dayName={weekday[index]} dayNumber={val} typeLunch={Meal.Lunch} headerBgColor={headerBgColor[index]} ></CardPlanningDay>)
                                        :
                                        (<CardPlanningDay key={index} isEmpty={true} weekNumber={props.weekNumber} dayName={weekday[index]} dayNumber={val} typeLunch={Meal.Lunch} headerBgColor={headerBgColor[index]} ></CardPlanningDay>)
                                )
                                )
                            }
                        </ErrorBoundary>
                    </Row>
                </div>
                <div>
                    <Row md={mobileScreen ? 2 : 8} style={{ alignItems: "center" }} >
                        <div style={{  width: "110px", textAlign: "center", color: "#9c9393" }} >{_strings.Dinner}</div>
                        {
                            [...rangeDays].map((val, index) => (
                                (val >= (props.dayToStart! || 1)) ?
                                    (<CardPlanningDay key={index} isEmpty={false} weekNumber={props.weekNumber} dayName={weekday[index]} dayNumber={val} typeLunch={Meal.Dinner} ></CardPlanningDay>)
                                    :
                                    (<CardPlanningDay key={index} isEmpty={true} weekNumber={props.weekNumber} dayName={weekday[index]} dayNumber={val} typeLunch={Meal.Dinner} ></CardPlanningDay>)
                            )
                            )
                        }
                    </Row>
                </div>
            </div>
        )
    }

    return render();

};

export default PlanningWeek;