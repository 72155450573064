import * as React from "react";
import { FromStore } from "src/helpers/Store";
import useStrings from "src/localization/Localization";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { ResetPassword } from "src/stores/auth/actions";
import { useHistory } from "react-router-dom";

import MessagesInPage from "src/components/bases/MessagesInPage";

import ButtonCustom from "src/components/bases/ButtonCustom";
import { Stack, TextField, Box, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export interface ResetPasswordPageProps {}

interface ResetPasswordPageState {
	newPassword: string;
	confirmPassword: string;
	hasError: boolean;
	errorText: string;
	showPassword: boolean;
}

const ResetPasswordPage: React.FunctionComponent<ResetPasswordPageProps> = props => {
	const _dispatch = useDispatch();
	const _strings = useStrings();

	let history = useHistory();
	const _isReset = FromStore(state => state.auth.IsReset);

	const [validated, setValidated] = React.useState(true);
	const [values, setValues] = React.useState<ResetPasswordPageState>({
		newPassword: "",
		confirmPassword: "",
		hasError: false,
		errorText: "",
		showPassword: false,
    });

	const token: any = useParams();

	const handleChange =
		(prop: keyof ResetPasswordPageState) => (event: React.ChangeEvent<HTMLInputElement>) => {
			setValues({ ...values, [prop]: event.target.value });
		};

	const handleClickShowPassword = () => {
		setValues({
			...values,
			showPassword: !values.showPassword,
		});
	};

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};			

	function handleResetPasswordResetClicked(
		event: React.FormEvent<HTMLFormElement>
	) {
		event.preventDefault();

		if (validateResetForm()) {
			setValidated(true);
			setValues({
				...values,
				hasError: false,
				errorText: ""
			});
			_dispatch(
				ResetPassword(token.token, values.newPassword, _strings.password_change)
			);
		} else {
			setValidated(false);
			setValues({
				...values,
				hasError: true,
				errorText: _strings.error_reset_password
			});
		}
	}

	function validateResetForm() {
		return (
			values.newPassword.length > 0 &&
			values.newPassword === values.confirmPassword
		);
	}

	function render(): JSX.Element {
		if (_isReset) {
			setTimeout(() => {
				history.push('/')
			}, 3000);
		}

		return (
			<Box>
				<MessagesInPage></MessagesInPage>

				<h1 className="mb-5 text-center text-danger">
					{_strings.reset_password}
				</h1>

				<div style={{ display: "flex", justifyContent: "center" }}>
					<Stack spacing={2} component="form" autoComplete="off" onSubmit={handleResetPasswordResetClicked}>

						<TextField
							required
							error={!validated}
							id="reset-newPassword"
							label={_strings.new_password}
							type={values.showPassword ? 'text' : 'password'}
							autoComplete="off"
							helperText={!validated ? _strings.error_reset_password : ""}
							onChange={handleChange("newPassword")}
							InputProps={{
								endAdornment: <InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													{values.showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
							  }}
							/>

						<TextField
							required
							error={!validated}
							id="reset-confirmPassword"
							label={_strings.confirm_password}
							type={values.showPassword ? 'text' : 'password'}
							autoComplete="off"
							helperText={!validated ? _strings.error_reset_password : ""}
							onChange={handleChange("confirmPassword")}
							InputProps={{
								endAdornment: <InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													{values.showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
							  }}
						/>

						<ButtonCustom 
							type="submit" 
							disabled={!validateResetForm} 
							style={{ textTransform: "capitalize", backgroundColor: "#8fc740", color: "white", borderColor: "#8fc740", borderRadius:"25px", fontSize: "1rem" }} 
							label={_strings.submit}>
						</ButtonCustom>

					</Stack>
				</div>
			</Box>
		);
	}

	return render();
};

export default ResetPasswordPage;
