import EmptyApiResponse from "src/api/dtos/EmptyApiResponse";
import GetUserProfileResponse from "src/api/dtos/user/GetUserProfileResponse";
import SetUserProfileResponse from "src/api/dtos/user/SetUserProfileResponse";
import UpdatePasswordRequest from "src/api/dtos/user/UpdatePasswordRequest";
import UpdateUserProfileRequest from "src/api/dtos/user/UpdateUserProfileRequest";
import UserPreferencesRequest from "src/api/dtos/user/UserPreferencesRequest";
import Urls from "src/configuration/constants";
import ServiceLocator from "./ServiceLocator";

export default class UserProfileService {
	public GetUserProfile(): Promise<GetUserProfileResponse> {
        return ServiceLocator.ApiClient.Get<GetUserProfileResponse>(Urls.GET_USER_PROFILE_URL);
	}

	public UpdateUserProfile(data: UpdateUserProfileRequest): Promise<SetUserProfileResponse> {
		return ServiceLocator.ApiClient.Put<SetUserProfileResponse>(Urls.SET_USER_PROFILE_URL, data);
	}

	public UpdateUserPreferences(data: UserPreferencesRequest): Promise<GetUserProfileResponse> {
		return ServiceLocator.ApiClient.Patch(Urls.SAVE_USER_PREFERENCES, data);
	}

	public UpdatePassword(data: UpdatePasswordRequest): Promise<EmptyApiResponse> {
		return ServiceLocator.ApiClient.Patch<EmptyApiResponse>(Urls.UPDATE_PASSWORD_URL, data);
	}
}
