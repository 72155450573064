import * as React from "react";

import MenuButton from "src/components/bases/MenuButton";
import Footer from "src/components/bases/Footer";

export interface BookmarksPageProps { }

const BookmarksPage: React.FunctionComponent<BookmarksPageProps> = (props) => {

    function render(): JSX.Element {

        return (
            <div>
                <MenuButton selected={3}></MenuButton>

                <div style={{ margin: "35px 0px" }}>
                </div>
                
                <Footer></Footer>
            </div>
        );
    }

    return render();
};

export default BookmarksPage;