export const CATEGORY_RAPID = "CATEGORY_RAPID";
export const CATEGORY_ECO = "CATEGORY_ECO";
export const CATEGORY_TAKE_AWAY = "CATEGORY_TAKE_AWAY";
export const CATEGORY_GOURMET = "CATEGORY_GOURMET";
export const STRUCTURE_STARTER = "STRUCTURE_STARTER";
export const STRUCTURE_MAIN = "STRUCTURE_MAIN";
export const STRUCTURE_CHEESE = "STRUCTURE_CHEESE";
export const STRUCTURE_DESSERT = "STRUCTURE_DESSERT";
export const ADD_PERSON_DAY = "ADD_PERSON_DAY";
export const SET_ENABLED_DAY = "SET_ENABLED_DAY";
export const ADD_GUESTS = "ADD_GUESTS";
export const ADD_START_DATE = "ADD_START_DATE";
export const COPY_WEEK = "COPY_WEEK";
export const SET_PLANNING_SAVE = "SET_PLANNING_SAVE";
export const REMOVE_PLANNING_WEEK_STATE = "REMOVE_PLANNING_WEEK_STATE";
export const LOADING_ACTION = "LOADING_ACTION";

export interface PlannificationState {

    week: {[key: number]: {}} //Map<number, {}> 
    // [week:number]: {
        day: {[key: number]: {}}  //Map<number, {}>
            lunchType: {[key: string]: {}} 
            // [lunchType: string]: {
                rapid: boolean,
                eco: boolean,
                takeAway: boolean,
                gourmet: boolean,
                starter: boolean,
                main: boolean,
                cheese: boolean,
                dessert: boolean,
                isEnabled: boolean,
                nbAdults: number,
                nbChildren: number,
                persons: object
}

export interface PlannificationStartDateState {
    start_date: Date | null
}


export interface PlannificationIsSaveState {
    is_save: boolean
}

interface Plannifications {
    type: typeof CATEGORY_RAPID | typeof CATEGORY_ECO | typeof CATEGORY_TAKE_AWAY | typeof CATEGORY_GOURMET | typeof STRUCTURE_STARTER | typeof STRUCTURE_MAIN | typeof STRUCTURE_CHEESE | typeof STRUCTURE_DESSERT | typeof REMOVE_PLANNING_WEEK_STATE | typeof ADD_PERSON_DAY | typeof ADD_GUESTS | typeof SET_ENABLED_DAY | typeof COPY_WEEK,
    day: number,
    week: number,
    lunchType: string,
    nbAdults?: number,
    nbChildren?: number,
    persons?: object,
    iteration?: number,
    valueChange?: boolean
}

interface Plannifications_Loading {
    type: typeof LOADING_ACTION,
    status: boolean,
}

interface Plannifications_SetSave {
    type: typeof SET_PLANNING_SAVE | typeof REMOVE_PLANNING_WEEK_STATE,
    is_save: boolean,
}

interface Plannifications_StartDate {
    type: typeof ADD_START_DATE | typeof REMOVE_PLANNING_WEEK_STATE,
    start_date: Date,
}

interface Plannifications_CopyWeek {
    type: typeof COPY_WEEK,
    week: number,
    iteration: number,
}

export type PlannificationActionTypes = Plannifications;
export type Plannifications_StartDateActionTypes = Plannifications_StartDate;
export type Plannifications_CopyWeekActionTypes = Plannifications_CopyWeek;
export type Plannifications_SetSaveActionTypes = Plannifications_SetSave;
export type Plannifications_LoadingActionTypes = Plannifications_Loading;
