import ApiClient from "src/api/ApiClient";
import Authenticator from "./Authenticator";
import AuthService from "./AuthService";
import UserProfileService from "./UserProfileService";
import MyEatWellService from "./MyEatWellService";

export default class ServiceLocator
{
    public static ApiClient: ApiClient = new ApiClient();

    public static AuthService: AuthService = new AuthService();

	public static Authenticator: Authenticator = new Authenticator();

	public static UserProfileService: UserProfileService = new UserProfileService();

    public static MyEatWellService: MyEatWellService = new MyEatWellService()
}
