import * as React from "react";
import Footer from "src/components/bases/Footer";
import MenuButton from "src/components/bases/MenuButton";


const GCUPage: React.FunctionComponent = (props) => {

    const contentList = [
        {
            id: "1",
            title: "Définitions",
            content: function () {
                return (
                    <React.Fragment>
                        <div style={{ fontSize: "initial", marginLeft: "2%" }}>
                            <p><strong>« Contrat »</strong> désigne les conditions générales d’utilisation de l’Application MY EAT WELL</p>
                            <p><strong>« Services »</strong> désigne l’ensemble des services disponibles via l’Application.</p>
                            <p><strong>« Support »</strong> désigne le matériel sur lequel vous pouvez installer et utiliser l’Application, notamment les smartphones, à savoir ceux dont le système d’exploitation est ANDROÏD / iOS </p>
                            <p><strong>« Utilisateur »</strong> : désigne toute personne physique utilisant cette application et qui reconnaît disposer des compétences et des moyens nécessaires pour accéder à l’Application et pour l’utiliser et déclare accepter les présentes conditions générales.</p>
                            <p><strong>« Editeur »</strong> : désigne la société My Goodlife SAS comme éditeur de l’application</p>
                        </div>
                    </React.Fragment>
                );
            }
        },
        {
            id: "2",
            title: "Objectifs de l'application",
            content: function () {
                return (
                    <React.Fragment>
                        <div style={{ fontSize: "initial", marginLeft: "2%" }}>
                            <span>L’Application consiste en un ensemble de fonctionnalités permettant de délivrer à l’Utilisateur : </span>
                            <ul>
                                <li>Des plannings de menus adaptés à ses besoins nutritionnels et à ceux de son foyer</li>
                                <li>Des fiches utilisateurs permettant de calculer les besoins nutritionnels de chacun des convives</li>
                                <li>Des fiches informatives sur les plats et aliments proposés dans les menus (informations nutritionnelles, portions, recettes…)</li>
                            </ul>
                        </div>
                    </React.Fragment>
                );
            }
        },
        {
            id: "3",
            title: "Authentification / Accès à l'application",
            children: [
                {
                    id: "3.1",
                    title: "Accès à l’Application",
                    content: () => {
                        return (
                            <React.Fragment>
                                <div style={{ fontSize: "initial", marginLeft: "2%" }}>
                                    L’Application est uniquement disponible par téléchargement à partir d’un magasin d’applications Apple ou Google et/ou tout autre espace autorisé par l’Editeur.
                                </div>
                            </React.Fragment>
                        );
                    }
                },
                {
                    id: "3.2",
                    title: "Authentification",
                    content: () => {
                        return (
                            <React.Fragment>
                                <div style={{ fontSize: "initial", marginLeft: "2%" }}>
                                    <p>Après avoir créé un compte Utilisateur dans l'Application, l’Utilisateur a la possibilité d’utiliser toutes les fonctions de l’application autant de fois qu’il le souhaite une fois le profil rempli.</p>
                                    <p>L'identifiant et le mot de passe sont personnels et confidentiels. L’Utilisateur est seul responsable de cette confidentialité.</p>
                                    <p>En cas de perte ou de prise de connaissance par un tiers non autorisé de l’identifiant et/ou du mot de passe, il est vivement conseillé à l’Utilisateur de procéder à un changement de son mot de passe. En cas d’oubli, l’Utilisateur peut demander une réinitialisation du mot de passe à partir de l’Application.</p>
                                </div>
                            </React.Fragment>
                        );
                    }
                }
            ]
        },
        {
            id: "4",
            title: "Protection des données de l’utilisateur",
            children: [
                {
                    id: "4.1",
                    title: "Généralités",
                    content: () => {
                        return (
                            <React.Fragment>
                                <div style={{ fontSize: "initial", marginLeft: "2%" }}>
                                    <p>La protection des données est au cœur des préoccupations de la société MY GOODLIFE SAS qui, dans le respect de la loi et de la réglementation applicables, s’engage, en sa qualité de responsable de traitement, à :
                                    </p>
                                    <ul>
                                        <li>Respecter les droits de l’Utilisateur tels que prévus par la réglementation en vigueur</li>
                                        <li>Mettre en œuvre tous les moyens techniques et organisationnels à sa disposition pour assurer la sécurité, la confidentialité et l’intégrité des données à caractère personnel traitées.</li>
                                    </ul>

                                    <p>Le responsable de traitement a son siège sis Ilot Quai 8.2 Bâtiment E1 31 rue d’Armagnac 33800 BORDEAUX.</p>
                                    <p>Conformément au Règlement Général sur la Protection des Données (UE) 2016/679 et à la loi « informatique et libertés » du 6 janvier 1978 actuellement en vigueur, vous disposez d’un droit d’accès, droit de rectification et d’effacement, droit à la limitation, droit d’opposition, droit à la portabilité pour toutes les données à caractère personnel qui vous concernent et qui sont recueillies via la présente Application.</p>
                                    <p>Vous disposez également du droit de donner des directives sur le sort de vos données après votre décès.</p>
                                    <p>Lorsqu’une finalité est fondée sur le consentement, vous avez la possibilité de le retirer à tout moment.</p>
                                    <p>Pour exercer l’ensemble des droits sus-décrits, il vous suffit de :</p>
                                    <ul>
                                        <li>Adresser un mail au DPO : <a href="mailto:dpo@my-goodlife.fr"> dpo@my-goodlife.fr</a></li>
                                        <li>Envoyer un courrier recommandé, à l’adresse suivante : MY GOODLIFE SAS – Ilot Quai 8.2 Bâtiment E1 31 rue d’Armagnac - 33800 BORDEAUX.</li>
                                    </ul>
                                    <p>En cas de doute raisonnable sur votre identité, MY GOODLIFE SAS se réserve la possibilité de solliciter la production d’une pièce d’identité avant de donner suite à votre demande d’exercice des droits.</p>
                                    <p>
                                        Vous avez la possibilité d’introduire une réclamation auprès de la CNIL directement sur le site de la CNIL suivant <a href="www.cnil.fr" target={"_blank"}>www.cnil.fr</a> ou à l’adresse postale : 3, Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07.
                                    </p>
                                    <p>Les données personnelles ne sont accessibles que par l’administrateur de la base de données et sont utilisées uniquement dans le cadre strict du fonctionnement de l’application. Les données personnelles ne feront jamais l’objet d’une utilisation commerciale. En cas de suppression du compte, toutes les données personnelles seront supprimées de la base de données, dans un délai raisonnable.</p>
                                </div>
                            </React.Fragment>
                        );
                    }
                },
                {
                    id: "4.2",
                    title: "Nature des données collectées",
                    children: [
                        {
                            id: "4.2.1",
                            title: "Données renseignées par l’Utilisateur (*) :",
                            content: () => {
                                return (
                                    <React.Fragment>
                                        <div style={{ fontSize: "initial", marginLeft: "2%" }}>
                                            <p>
                                                Il est nécessaire d’avoir un compte personnel pour utiliser l’Application. Il faut renseigner les données suivantes pour un bon fonctionnement des Services proposés par l’Application.
                                            </p>
                                            <p>
                                                A défaut, l’Utilisateur ne pourra pas bénéficier des services fournis et/ou d’un calcul précis des besoins nutritionnels par l’Application.
                                            </p>
                                            <ul>
                                                <li>Nom, Prénom </li>
                                                <li>Date de naissance, Sexe</li>
                                                <li>Si l’Utilisateur est une femme : Enceinte ou Allaitante</li>
                                                <li>Poids, Taille, Niveau d’activité physique (3 niveaux : faible - modéré - intense)</li>
                                                <li>Membre du foyer ou convive régulier</li>
                                                <li>Allergènes ou intolérances alimentaires</li>
                                                <li>Aliments non souhaités dans les propositions</li>
                                                <li>Aliments à exclure des propositions pour le foyer (16 catégories comprenant par exemple la viande de cheval)</li>
                                            </ul>
                                            <p>Ces données permettent de sélectionner les aliments répondant aux besoins nutritionnels des convives du repas, en tenant compte des préférences alimentaires individuelles et celles du foyer, mais également des possibles allergènes ou facteurs d’intolérance dans les plats préparés.</p>
                                            <p>
                                                <u>Données de santé :</u> <br />
                                                L’Utilisateur va renseigner s’il est allergique ou intolérant à un aliment (parmi les 15 aliments ou catégories d’aliment reconnus comme allergènes ou facteurs d’intolérance). Les données sont donc hébergées chez un hébergeur certifié HDS par le ministère de la santé (cf chapitre 4.4 : Hébergement des données). Le service ne substitue pas à un avis médical concernant la prise en charge des allergènes.
                                            </p>
                                        </div>
                                    </React.Fragment>
                                );
                            }
                        },
                        {
                            id: "4.2.2",
                            title: "Données générées et stockées par l’application",
                            content: () => {
                                return (
                                    <React.Fragment>
                                        <div style={{ fontSize: "initial", marginLeft: "2%" }}>
                                            <p>L’Application va calculer les besoins énergétiques en kcal de chacun des convives, à partir des données suivantes:
                                            </p>
                                            <ul>
                                                <li>Poids</li>
                                                <li>Taille</li>
                                                <li>Âge</li>
                                                <li>Sexe</li>
                                                <li>Niveau d’activité physique.</li>
                                            </ul>
                                            <p>
                                                Comme la loi le permet, l’Utilisateur peut demander la suppression immédiate de toutes les données le concernant en informant le DPO par mail à l’adresse suivante : <a href="mailto:dpo@my-goodlife.fr">dpo@my-goodlife.fr</a>.
                                            </p>
                                            <p>
                                                Ces données sont collectées et traitées pour l’exécution du présent contrat liant l’Utilisateur à MY GOODLIFE.
                                            </p>
                                        </div>
                                    </React.Fragment>
                                );
                            }
                        }
                    ]
                },
                {
                    id: "4.3",
                    title: "Conservation des données collectées",
                    content: () => {
                        return (
                            <React.Fragment>
                                <div style={{ fontSize: "initial", marginLeft: "2%" }}>
                                    <p>Les données renseignées par l’Utilisateur sont conservées pendant tout le temps de la relation contractuelle.</p>
                                    <p>Les données sont effacées dans un délai de 6 mois maximum après suppression du compte.</p>
                                </div>
                            </React.Fragment>
                        );
                    }
                },
                {
                    id: "4.4",
                    title: "Hébergement des données",
                    content: () => {
                        return (
                            <React.Fragment>
                                <div style={{ fontSize: "initial", marginLeft: "2%" }}>
                                    <p>Conformément à la législation sur l'hébergement des données de santé à caractères personnel - Art. L111-8 du Code de la santé publique, les données sont hébergées par Microsoft Azure, hébergeur HDS agrée par le Ministère de la santé.</p>
                                    <p>
                                        MICROSOFT AZURE <br />
                                        <a href="https://privacy.microsoft.com/fr-fr/privacystatement">https://privacy.microsoft.com/fr-fr/privacystatement</a> <br />
                                        data center en Europe occidentale <br />
                                        Téléphone : N° Cristal - 09 70 01 90 90 - appel non surtaxé
                                    </p>
                                    <p>
                                        MICROSOFT France <br />
                                        RCS Nanterre B 327 733 184 <br />
                                        39, quai du Président Roosevelt <br />
                                        92130 Issy-les-Moulineaux
                                    </p>
                                </div>
                            </React.Fragment>
                        );
                    }
                },
                {
                    id: "4.5",
                    title: "Délégué à la Protection des Données (DPO)",
                    content: () => {
                        return (
                            <React.Fragment>
                                <div style={{ fontSize: "initial", marginLeft: "2%" }}>
                                    <p>Si vous avez d'autres questions sur la collecte, le traitement et l'utilisation de vos données personnelles, veuillez-vous adresser à notre Délégué à la Protection des Données (DPO).</p>
                                    <p>
                                        <strong>Par courrier à l’adresse suivante : </strong> <br />
                                        <p>MY GOODLIFE <br />
                                            A l’attention du délégué à la protection des données (DPO) <br />
                                            Ilot Quai 8.2 Bâtiment E1 <br />
                                            31 rue d’Armagnac <br />
                                            33800 BORDEAUX
                                        </p>
                                    </p>
                                    <p>
                                        <strong>Par mail : </strong><a href="mailto:dpo@my-goodlife.fr">dpo@my-goodlife.fr</a>
                                    </p>
                                </div>
                            </React.Fragment>
                        );
                    }
                },
                {
                    id: "4.6",
                    title: "Magasin d’applications",
                    content: () => {
                        return (
                            <React.Fragment>
                                <div style={{ fontSize: "initial", marginLeft: "2%" }}>
                                    <p>Vous avez téléchargé notre application via un magasin d’applications (Google Play, App store).
                                    </p>
                                    <p>Les présentes informations sur la protection des données personnelles ne s'appliquent qu'à notre application. Il vous appartient de consulter vous-même les informations sur la protection des données personnelles de ces sites.</p>
                                </div>

                            </React.Fragment>
                        );
                    }
                }
            ]
        },
        {
            id: "5",
            title: "Traceurs ou cookies",
            content: () => {
                return (
                    <React.Fragment>
                        <div style={{ fontSize: "initial", marginLeft: "2%" }}>
                            <p>Les traceurs suivants sont utilisés sur votre Support :</p>
                            <p>Actuellement MyGoodlife n’utilise pas de cookie sur son application MyEatWell. Seulement un Token d’identification.</p>
                        </div>
                    </React.Fragment>
                );
            },
        },
        {
            id: "6",
            title: "Fonctionnement de l’application – Finalites au sens du règlement général de protection des données",
            children: [
                {
                    id: "6.1",
                    title: "Planification de semaines de menus équilibrés et adaptés aux besoins nutritionnels fdes convives",
                    content: () => {
                        return (
                            <React.Fragment>
                                <div style={{ fontSize: "initial", marginLeft: "2%" }}>
                                    <p>Toutes les données collectées et traitées par l’Application servent à produire un planning de menus adaptées aux besoins nutritionnels de l’Utilisateur. L’Utilisateur peut personnaliser les tirages de menus en les qualifiant par préférence. Les plats ou les menus préférés seront tirés plus fréquemment selon le niveau de préférence (sur une échelle de 1 à 5). L’Utilisateur peut également choisir des types de menus par critères : économique, rapide à préparer, gourmet, à emporter.</p>
                                    <p>Aucun autre traitement n’est effectué par l’Application à partir des données collectées.</p>
                                </div>
                            </React.Fragment>
                        );
                    }
                }
            ]
        },
        {
            id: "7",
            title: "Coûts",
            content: () => {
                return (
                    <React.Fragment>
                        <div style={{ fontSize: "initial", marginLeft: "2%" }}>
                            <p>MY EAT WELL est une application gratuite.</p>
                            <p>Le coût d'accès au réseau de télécommunications et les frais afférents sont à la charge de l'Utilisateur du Support, conformément aux conditions stipulées par son opérateur de télécommunications et/ou par son fournisseur d’accès à internet.</p>
                            <p>L’Editeur ne peut être tenu pour responsable de dommages résultant du dysfonctionnement ou du mauvais réglage du Support qui rendrait impossible tout accès à l'Application.</p>
                        </div>
                    </React.Fragment>
                );
            }
        },
        {
            id: "8",
            title: "Durée - Résiliation – Modification du contrat",
            children: [
                {
                    id: "8.1",
                    title: "Durée du Contrat",
                    content: () => {
                        return (
                            <React.Fragment>
                                <div style={{ fontSize: "initial", marginLeft: "2%" }}>
                                    <p>Le présent Contrat entre en vigueur à la date à laquelle l’Utilisateur télécharge l’application MY EAT WELL.</p>
                                </div>
                            </React.Fragment>
                        );
                    }
                },
                {
                    id: "8.2",
                    title: "Résiliation du Contrat",
                    content: () => {
                        return (
                            <React.Fragment>
                                <div style={{ fontSize: "initial", marginLeft: "2%" }}>
                                    <p>L’Utilisateur peut mettre fin à l’utilisation du service à tout moment et pour quelque raison que ce soit. L’Utilisateur n’est pas obligé d'informer MY GOODLIFE de cette résiliation. Toutefois, si l’Utilisateur souhaite également que MY GOODLIFE supprime son compte et les informations personnelles qu'il contient, il doit en informer MY GOODLIFE par courrier recommandé (MY GOODLIFE - A l’attention du délégué à la protection des données (DPO) - Ilot Quai 8.2 Bâtiment E1 31 rue d’Armagnac 33800 BORDEAUX) ou par mail ( <a href="mailto:dpo@my-goodlife.fr">dpo@my-goodlife.fr</a> ). Dès la réception de la demande de suppression, MY GOODLIFE mettra en œuvre les moyens à sa disposition pour supprimer ces informations, dans un délai raisonnable. </p>
                                </div>
                            </React.Fragment>
                        );
                    }
                },
                {
                    id: "8.3",
                    title: "Modification du Contrat",
                    content: () => {
                        return (
                            <React.Fragment>
                                <div style={{ fontSize: "initial", marginLeft: "2%" }}>
                                    <p>Toute modification du présent Contrat sera notifiée par un affichage sur l’écran d’accueil de l’Application lors de la première connexion faisant suite aux dites modifications. L’Utilisateur devra accepter le Contrat modifié avant de pouvoir accéder aux Services.</p>
                                    <p>MY GOODLIFE peut, partiellement ou dans son intégralité et sans obligation de préavis, modifier ou adapter l’Application, les fonctionnalités du service, l'interface Utilisateur et la conception, l'étendue et la disponibilité du contenu des Services.</p>
                                    <p>
                                        L’Utilisateur ne pourra former aucune réclamation, ni aucune plainte ou demande à l'encontre de MY GOODLIFE pour avoir appliqué de tels changements.
                                    </p>
                                </div>
                            </React.Fragment>
                        );
                    }
                }
            ],
        },
        {
            id: "9",
            title: "Propriete intellectuelle",
            content: () => {
                return (
                    <React.Fragment>
                        <div style={{ fontSize: "initial", marginLeft: "2%" }}>
                            <p>A compter de la date à laquelle l’Application a été téléchargée puis installée sur le Support, l’Editeur accorde à l’Utilisateur une licence révocable non-exclusive, non-transférable, à des fins strictement personnelles et privées, à l’exclusion de tout usage commercial, l’autorisant à installer et à utiliser une copie de l’Application sur le Support.</p>
                            <p>L'Application doit être considérée comme un tout indivisible. Toute reproduction ou représentation totale ou partielle des pages, des données ou de tout autre élément matériel ou immatériel qui compose l'Application, par un quelconque procédé et sur un quelconque support existant ou à venir, est interdite et constitue, en l'absence d'autorisation de l'Editeur, une infraction au droit d'auteur.</p>
                            <p>La libre utilisation de l’Application conférée par l’Editeur à l’Utilisateur ne saurait constituer une cession totale ou partielle, y compris ponctuelle, des droits détenus par l’Editeur sur l’Application.</p>
                            <p>Les noms de marque et les logos qui figurent sur l'Application sont la propriété de l’Editeur ou sont utilisés par l’Editeur dans le cadre d'un contrat de licence.</p>
                            <p>Tous les droits afférents aux photographies, images, logos et encarts publicitaires, sont la propriété exclusive de l’Editeur.</p>
                        </div>
                    </React.Fragment>
                );
            },
        },
        {
            id: "10",
            title: "Absence de garantie et responsabilités",
            content: () => {
                return (
                    <React.Fragment>
                        <div style={{ fontSize: "initial", marginLeft: "2%" }}>
                            <p>L’Editeur se réserve le droit d'ajouter, de modifier ou de supprimer les règles de fonctionnement susmentionnées à tout moment et sans préavis.</p>
                            <p>L’Editeur ne garantit ni la précision ni l'exhaustivité des informations figurant dans cette Application.</p>
                            <p>L’Editeur ne peut être tenu pour responsable des éventuelles conséquences susceptibles d'affecter le compte de l'Utilisateur qui résultent d'un cas de force majeure ou de circonstances exceptionnelles indépendantes de la volonté de l’Editeur.</p>
                        </div>
                    </React.Fragment>
                );
            },
        },
        {
            id: "11",
            title: "Stipulations diverses",
            content: () => {
                return (
                    <React.Fragment>
                        <div style={{ fontSize: "initial", marginLeft: "2%" }}>
                            <p><strong>Intégralité du Contrat.</strong> Le présent Contrat constitue l’intégralité de l’accord conclu entre les Parties relativement à son objet.</p>

                            <p><strong>Droit applicable et tribunaux compétents.</strong>Les présentes conditions générales et la relation qui lie l'Utilisateur et l’Editeur sont régies et interprétées en vertu du Droit Français et relèvent de la compétence exclusive des tribunaux français dont élection de domicile sera faite dans le ressort du siège social de la SAS MY GOODLIFE.</p>                            
                        </div>
                    </React.Fragment>
                );
            }
        }
    ];

    interface DataList {
        id: string,
        title: string,
        content?(): JSX.Element,
        children?: DataList[],
    }

    function createTitles(data: DataList[]) {
        return (
            <React.Fragment>
                <ol>
                    {
                        Object.entries(data).map(([key, value]) => (
                            <li key={key}>
                                <a href={"#" + value.id}>{value.title}</a>
                                {value.children ? createTitles(value.children) : null}
                            </li>
                        ))
                    }
                </ol>
            </React.Fragment>
        );
    }

    function createParagraphs(data: DataList[]) {
        return (
            <React.Fragment>
                <ol>
                    {
                        Object.entries(data).map(([key, value]) => (
                            <li className="title" key={key} id={value.id} style={{ fontSize: "24px", marginBottom: "4%" }}>
                                {renderParagraph(value.title, value.content ? value.content() : null)}
                                {value.children ? createParagraphs(value.children) : null}
                            </li>
                        ))
                    }
                </ol>
            </React.Fragment>
        );
    }

    function renderParagraph(title: string, content: JSX.Element | null) {
        return (
            <React.Fragment>
                <span style={{ marginBottom: "1%" }}>{title}</span>
                <span style={{ fontSize: "initial" }}>{content ? content : null}</span>
            </React.Fragment>
        );
    }

    return (
        <div>
            <MenuButton></MenuButton>

            <section id="cgu" className="marginPages">
                <header><h3>Conditions générales d’utilisation de l’application My Eat Well</h3></header>
                <br />
                <div className="container-fluid">

                    <div className="tableContents">
                        {createTitles(contentList)}
                    </div>

                    <div className="data">
                        {createParagraphs(contentList)}
                    </div>

                </div>
                <br /><br />
            </section>

            <Footer></Footer>
        </div>
    )
}

export default GCUPage;