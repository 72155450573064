import * as React from 'react';

import { Icon } from "@mui/material";
import { styled } from "@mui/material/styles";
// import { Button } from "@mui/material";


type SvgIconProps = {
    width: number,
    height: number,
    source: string
    sourceHover?: string
};

const IconButtonSvgIcon: React.FunctionComponent<SvgIconProps> = props => {

    const IconMenu = styled(Icon)(({ theme }) => ({       
        backgroundImage: `url(${props.source})`,
        backgroundRepeat : "no-repeat",
        width: props.width,
        height: props.height,
        "&:active, &:hover": {
           /* backgroundImage: `url(${props.sourceHover})`,*/
            backgroundRepeat : "no-repeat",
        },
    }));

    function render(): JSX.Element {
        return (
            <IconMenu />
            // <Button startIcon={<IconMenu />}>toto</Button>
        )
    }

    return render();
};

export default IconButtonSvgIcon;

// import SvgIcon from '@mui/material/SvgIcon';
// type SvgIconProps = {
//     width: number,
//     height: number,
//     svgPathData: string | Array<string>
// };

// const IconButtonSvgIcon: React.FunctionComponent<SvgIconProps> = props => {

//     function render(): JSX.Element {
//         return (
//             <SvgIcon viewBox={`0 0 ${props.width} ${props.height}`}>
//                 {typeof props.svgPathData === 'string' ? (
//                     <path d={props.svgPathData} />
//                 ) : (
//                     props.svgPathData.map((d: string, i: number) => (
//                         <path d={d} />
//                     ))
//                 )}
//             </SvgIcon>
//         );
//     }

//     return render();

// };

// export default IconButtonSvgIcon;
