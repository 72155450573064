enum ErrorCode {
	Unauthorized = "Unauthorized",
	NO_CREDENTIALS = "NO_CREDENTIALS",
	WEAK_PASSWORD = "WEAK_PASSWORD",
	EMAIL_ALREADY_EXISTS = "EMAIL_ALREADY_EXISTS",
	INVALID_PASSWORD = "INVALID_PASSWORD",
	INVALID_API_CLIENT = "INVALID_API_CLIENT",
	INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
	MISSING_GUESTS = "MISSING_GUESTS",
	INVALID_GUEST_ID = "INVALID_GUEST_ID",
	DUPLICATED_GUEST_ID = "DUPLICATED_GUEST_ID",
	FIRST_DAY_OF_THE_WEEK_IS_MONDAY = "FIRST_DAY_OF_THE_WEEK_IS_MONDAY",
	INVALID_DATE_IN_PLANNING = "INVALID_DATE_IN_PLANNING",
	INVALID_SELECTED_MEAL_ITEMS = "INVALID_SELECTED_MEAL_ITEMS",
	NO_PLANNING = "NO_PLANNING",
	INVALID_WEEK_INDEX = "INVALID_WEEK_INDEX",
	PLANNING_ALREADY_FULL = "PLANNING_ALREADY_FULL",
	MENU_GENERATOR_SERVICE_UNAVAILABLE = "MENU_GENERATOR_SERVICE_UNAVAILABLE",

	//CUSTOM
	EMPTY_FIELDS = "EMPTY_FIELDS"
}

export default ErrorCode;
