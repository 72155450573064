
import * as React from 'react';
import useStrings from "src/localization/Localization";
import { DeclareState } from "src/helpers/State";
import { useHistory } from "react-router-dom";
import { FromStore } from "src/helpers/Store";
import { useDispatch } from "react-redux";

import { Card, Row /*, Popover, Overlay*/ } from "react-bootstrap";
import { /*IconButton, */ Button, Box } from "@mui/material";

import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ExpandCircleDown from '@mui/icons-material/ExpandCircleDown';
import MenuProfile, { Meal, MealType } from 'src/api/dtos/menu/MenuProfile';

import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { capitalizeFirstLetter } from 'src/helpers/fonctions';

import Urls, { API_HOST } from "src/configuration/constants";
import { ApiPatchMeals } from 'src/stores/menu/actions';
import { ActiveProductOnShoppingList } from 'src/stores/shoppingList/action';

type CardMenuDayProps = {
    dayName?: string,
    headerBgColor?: string | undefined,
    dayNumber?: number,
    weekNumber?: number,
    isEmpty?: boolean | false,
    imagePath?: string,
    elements?: MenuProfile
};

const defaultProps: CardMenuDayProps = {
    dayName: "",
    headerBgColor: undefined,
    dayNumber: 1,
    weekNumber: 1,
    isEmpty: false,
    imagePath: "default-recipe.png",
    elements: {
        date: "",
        elements: [],
        meal: Meal.Lunch,
    }
}

const CardMenuDay: React.FunctionComponent<CardMenuDayProps> = props => {
    const _dispatch = useDispatch();
    const _strings = useStrings();
    let history = useHistory();

    const _shopping = FromStore(state => state.shopping_list && props.dayNumber ? state.shopping_list[props.dayNumber] : null);

    let allergenAlert = false;
    let elementList = new Array();
    if (props.elements?.elements) {
        Object.entries(props.elements?.elements).forEach(([index, elementInfo]) => {
            elementList[elementInfo.part] = elementInfo;
            Object.entries(elementInfo.portions).forEach(([index2, portions]) => {
                if (portions.has_allergen_alert) {
                    allergenAlert = true;
                }
            });
        });
    }

    let sorting = [MealType.Starter, MealType.Main, MealType.Accompaniment, MealType.Bread, MealType.Cheese, MealType.Dessert];
    let elementsOnDetails = [] as any[];
    for (var i = 0; i < sorting.length; i++) {
        for (var j in elementList) {
            if (j == sorting[i]) {
                elementsOnDetails.push(elementList[j]);
            }
        }
    }

    const _state = DeclareState({
        allergenInfo: {
            Starter: false,
            Main: false,
            Cheese: false,
            Dessert: false,
        }
    });

    const Accordion = styled((props: AccordionProps) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '& .MuiAccordionDetails-root': {
            padding: "2px 3px",
        },
    }));

    const AccordionSummary = styled((props: AccordionSummaryProps) => (
        <MuiAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            {...props}
        />
    ))(({ theme }) => ({
        padding: '3px',
        height: '35px',
        minHeight: 0,
        textAlign: 'center',
        cursor: "default !important",
        '& .MuiAccordionSummary-content': {
            display: 'block',
            margin: "5px 0",
        },
        '& .MuiAccordionSummary-root': {
            cursor: "default !important",
        }
    }));

    // const CardImgOverlayTitle: { [key: string]: React.CSSProperties } = {
    //     mainCard: {
    //         height: "100px",
    //         width: "100%",
    //         backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9))",
    //         padding: "5px 5px 5px 5px",
    //         fontSize: "14px",
    //         fontWeight: "bold",
    //         justifyContent: "center",
    //         textAlign: 'center',
    //         marginTop: "-100px",
    //         marginBottom: "0px",
    //         display: "flex",
    //     },
    //     allgergenCard: {
    //         height: "100px",
    //         width: "100%",
    //         backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(252, 110, 81, 0.8))",
    //         padding: "5px 5px 5px 5px",
    //         fontSize: "14px",
    //         fontWeight: "bold",
    //         justifyContent: "center",
    //         textAlign: 'center',
    //         marginTop: "-100px",
    //         marginBottom: "0px",
    //         display: "flex",
    //     }
    // };

    const DetailMeal = styled('div')({
        textAlign: "center",
        borderTop: "1px solid lightgrey",
        padding: "8px",
    });

    const DetailMealContent = styled('div')({
        marginBottom: "5px",
        '& .MuiIconButton-root': {
            backgroundColor: "rgba(156, 147, 147, 0.3)",
            margin: "15px 3px 0 3px",
        },
        '& .shoppingListActive': {
            backgroundColor: "rgba(84, 191, 172, 0.5)",
        },
        '& .shoppingListRemove': {
            backgroundColor: "rgba(253, 105, 77, 0.5)",
        },
        '& .allergenInfoActive': {
            backgroundColor: "rgba(253, 105, 77, 0.5)",
        }
    });

    const theme = createTheme({
        components: {
            MuiCollapse: {
                styleOverrides: {
                    // container: {},
                    wrapper: {
                        // backgroundColor: "lightgray",
                        // zIndex: 10,
                        // position: 'relative',
                    },
                    // wrapperInner: {}
                },
            },
        },
    });

    function getImage() {

        if (!props.isEmpty) {
            const food_id = elementList[MealType.Main].food_id;

            const imagePath = API_HOST + Urls.GET_RECIPES_PICTURE + '/' + food_id;
            // let file = new Image();
            // file.src = imagePath;

            // if (file.height > 0) {
            //     return imagePath;
            // } else {
            //     return '/images/menu/no-recipe.jpg';
            // }
            if (imagePath) {
                return imagePath;
            } else {
                return '/images/menu/loading-dots.gif';
            }
        } else {
            return '/images/menu/' + defaultProps.imagePath;
        }

        // if (!props.isEmpty) {
        //     const food_id = elementList[MealType.Main].food_id;
        //     if (_recipes && _recipes[food_id] && _recipes[food_id].loadImage) {
        //         return API_HOST + Urls.GET_RECIPES_PICTURE + '/' + food_id;
        //     } else {
        //         return '/images/menu/no-recipe.jpg';
        //     }
        // } else {
        //     return '/images/menu/' + defaultProps.imagePath;
        // }

        // const food_id = elementList[MealType.Main].food_id;
        // ApiGetRecipesPicture(food_id).then(
        //     result => {
        //         if (result.responseURL) {
        //             let paths = imagesPath;
        //             paths[food_id] = result.responseURL;
        //             setImagesPath(paths);
        //         }
        //     }
        // );

        // return imagesPath[food_id];
    }

    const [expanded, setExpanded] = React.useState<string | false>('');
    const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    function handleActionClicked(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        event.stopPropagation();

        let foodId = event.currentTarget.dataset.foodid;
        let idMeal = event.currentTarget.dataset.idmeal;
        let typeMeal = MealType[event.currentTarget.dataset.typemeal!];
        switch (event.currentTarget.id) {
            case "shoppingList":
                _dispatch(ActiveProductOnShoppingList(Number(props.dayNumber), Number(foodId)));
                break;
            case "refreshMeal":
                _dispatch(ApiPatchMeals(Number(idMeal), String(props.elements?.date), String(props.elements?.meal), typeMeal, Number(props.dayNumber)));
                break;
            case "allergenInfo":
                _state.Update({
                    allergenInfo: {
                        Starter: (typeMeal == MealType.Starter ? !_state.content.allergenInfo[MealType.Starter] : _state.content.allergenInfo[MealType.Starter]),
                        Main: (typeMeal == MealType.Main ? !_state.content.allergenInfo[MealType.Main] : _state.content.allergenInfo[MealType.Main]),
                        Cheese: (typeMeal == MealType.Cheese ? !_state.content.allergenInfo[MealType.Cheese] : _state.content.allergenInfo[MealType.Cheese]),
                        Dessert: (typeMeal == MealType.Dessert ? !_state.content.allergenInfo[MealType.Dessert] : _state.content.allergenInfo[MealType.Dessert]),
                    }
                });
                break;
        }
    }

    // const [anchorEl, setAnchorEl] = React.useState({ list: [] });
    // const handleOpenPopoverAllergenClick = (event: any) => {
    //     let typeMeal = MealType[event.currentTarget.dataset.typemeal!];
    //     _state.Update({
    //         allergenInfo: {
    //             Starter: (typeMeal == MealType.Starter ? !_state.content.allergenInfo[MealType.Starter] : _state.content.allergenInfo[MealType.Starter]),
    //             Main: (typeMeal == MealType.Main ? !_state.content.allergenInfo[MealType.Main] : _state.content.allergenInfo[MealType.Main]),
    //             Cheese: (typeMeal == MealType.Cheese ? !_state.content.allergenInfo[MealType.Cheese] : _state.content.allergenInfo[MealType.Cheese]),
    //             Dessert: (typeMeal == MealType.Dessert ? !_state.content.allergenInfo[MealType.Dessert] : _state.content.allergenInfo[MealType.Dessert]),
    //         }
    //     });
    //     // setAnchorEl(event.currentTarget);

    //     setAnchorEl(state => {
    //         let list = Object.assign([], state.list);  // creating copy of state variable jasper
    //         const index = list.indexOf(typeMeal + props.dayNumber as never);
    //         if (index < 0) {
    //             list[typeMeal + props.dayNumber] = event.currentTarget as never;
    //         }
    //         return { list };
    //     });
    //     console.log(anchorEl);
    // };

    // const handlePopoverAllergenClose = (mealType: never) => {
    //     setAnchorEl(state => {
    //         let list = Object.assign([], state.list);  // creating copy of state variable jasper
    //         const index = list.indexOf(mealType);
    //         if (index > -1) {
    //             list.splice(index, 1);
    //         }                  // update the name property, assign a new value                 
    //         return { list };
    //     });
    // };

    // const [show, setShow] = React.useState(false);
    // const [target, setTarget] = React.useState(null);
    // const ref = React.useRef(null);
    // const handleAllergenClicked = (event: any) => {
    //     setShow(!show);
    //     setTarget(event.target);
    // };

    // const handleAllergenOnHideClicked = (event: any) => {
    //     setShow(false);
    //     setTarget(event.target);
    // }

    function render(): JSX.Element {
        console.log(props)

        return (
            <Row style={{ margin: '1% 0px', padding: '0px', justifyContent: "center", pointerEvents: (props.isEmpty ? 'none' : 'all'), opacity: 'inherit' }}>
                <Card style={{ boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)", padding: '0px', maxWidth: '85%', minHeight: (props.headerBgColor ? '234px' : '195px') }}>

                    <div onClick={() => { history.push({ pathname: '/menudetail', state: elementsOnDetails }) }}>
                        {(props.headerBgColor !== undefined) ?
                            (<Card.Header style={{ backgroundColor: `${props.headerBgColor}`, color: "white", textAlign: "center", fontWeight: 800, fontSize: "15px" }}>
                                <span>{props.dayName}</span>
                                {!props.isEmpty && allergenAlert ?
                                    <span style={{ position: 'absolute', right: "5px" }}>{<InfoOutlinedIcon />}</span>
                                    : null
                                }
                            </Card.Header>)
                            : null}

                        {(!props.isEmpty && props.headerBgColor == undefined && allergenAlert) ?
                            <span style={{ position: 'absolute', top: "8px", right: "8px" }}>{<InfoOutlinedIcon />}</span>
                            : null
                        }

                        {/* {_recipes && _recipes[elementList[MealType.Main].food_id] && !_recipes[elementList[MealType.Main].food_id].loadImage && <i className="fa fa-spinner" />} */}
                        <div style={{ height: "160px", display: "flex", alignItems: 'baseline' }}>
                            <Card.Img variant="top" src={getImage()} /*onLoad={imageLoaded}*/ style={{ maxHeight: "160px" }} />
                        </div>

                        <Card.ImgOverlay style={{
                            padding: '0',
                            color: "white",
                            position: "relative",
                        }}>
                            {(!props.isEmpty) ?
                                (<Card.Title className="CardImgOverlayTitle">
                                    <span style={{ alignSelf: "flex-end", marginBottom: "10px" }}>{capitalizeFirstLetter(elementList[MealType.Main].food_name)}</span>
                                </Card.Title>)
                                : null}
                        </Card.ImgOverlay>
                    </div>
                    <Card.Body style={{ padding: "0px" }}>
                        <div>
                            <ThemeProvider theme={theme}>
                                <Accordion expanded={expanded === 'panel' + props.dayName} onChange={handleAccordionChange('panel' + props.dayName)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={"panel" + props.dayName}
                                        id={"panel" + props.dayName}
                                        className='bold'
                                    >
                                        {_strings.menu_informations}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {
                                            Object.keys(MealType).map((value: string, index: number) => {
                                                if (elementList[MealType[value]] && value != MealType.Bread) {
                                                    // const open = Boolean(anchorEl[MealType[value] + props.dayNumber]);

                                                    let bgColorShopping = true;
                                                    if (elementList[MealType[value]].is_in_shopping_list) {
                                                        if (_shopping && _shopping.foods[elementList[MealType[value]].food_id] && !_shopping.foods[elementList[MealType[value]].food_id].is_in_shopping_list) {
                                                            bgColorShopping = false;
                                                        } else {
                                                            bgColorShopping = true;
                                                        }
                                                    } else {
                                                        if (_shopping && _shopping.foods[elementList[MealType[value]].food_id] && _shopping.foods[elementList[MealType[value]].food_id].is_in_shopping_list) {
                                                            bgColorShopping = true;
                                                        } else {
                                                            bgColorShopping = false;
                                                        }
                                                    }

                                                    return (
                                                        <DetailMeal key={index} onClick={() => { history.push({ pathname: '/menudetail', hash: String(elementList[MealType[value]].food_id), state: elementsOnDetails }) }}>
                                                            <DetailMealContent className='bold'>{_strings.tr(value.toLowerCase())}</DetailMealContent>
                                                            <DetailMealContent >{elementList[MealType[value]] ? capitalizeFirstLetter(elementList[MealType[value]].food_name) : "-"}</DetailMealContent>
                                                            <DetailMealContent>
                                                                <div style={{ textAlign: 'center', marginTop: "15px", visibility: (props.isEmpty ? "hidden" : "visible") }}>
                                                                    <Box sx={{
                                                                        "& .MuiButton-root": {
                                                                            color: 'rgb(51 51 51)',
                                                                            borderRadius: "25px",
                                                                            textTransform: "capitalize",
                                                                            padding: "5px 20px",
                                                                            backgroundColor: bgColorShopping ? "rgba(84, 191, 172, 0.5)" : "rgba(253, 105, 77, 0.5)",
                                                                            marginBottom: "10px",
                                                                        }
                                                                    }}
                                                                    >
                                                                        <Button startIcon={<ShoppingCartOutlinedIcon sx={{ fontSize: 14 }} />} onClick={handleActionClicked} id="shoppingList" data-typemeal={MealType[value]} data-foodid={elementList[MealType[value]].food_id} >{_strings.courses}</Button>
                                                                    </Box>
                                                                </div>
                                                                <div>
                                                                    <Box sx={{
                                                                        "& .MuiButton-root": {
                                                                            color: 'rgb(51 51 51)',
                                                                            borderRadius: "25px",
                                                                            textTransform: "capitalize",
                                                                            padding: "5px 20px",
                                                                            backgroundColor: "rgba(156, 147, 147, 0.3)",
                                                                        }
                                                                    }}
                                                                    >
                                                                        <Button startIcon={<AutorenewOutlinedIcon sx={{ fontSize: 14 }} />} onClick={handleActionClicked} id="refreshMeal" data-typemeal={MealType[value]} data-idmeal={elementList[MealType[value]].id} >{_strings.refresh}</Button>
                                                                    </Box>
                                                                </div>
                                                                {/* <span>
                                                                    <IconButton className={_state.content.shoppingList[MealType[value]] ? 'shoppingListActive' : 'shoppingListRemove'} aria-label="shoppingList" data-typemeal={MealType[value]} onClick={handleActionClicked}>
                                                                        <ShoppingCartOutlinedIcon sx={{ fontSize: 14 }} />
                                                                    </IconButton>
                                                                </span>
                                                                <span>
                                                                    <IconButton aria-label="refreshMeal" data-typemeal={MealType[value]} onClick={handleActionClicked}>
                                                                        {<AutorenewOutlinedIcon sx={{ fontSize: 14 }} />}
                                                                    </IconButton>
                                                                </span>
                                                                <span ref={ref}>
                                                                    <IconButton aria-describedby={'popover-' + `${index}`} ref={target} className={_state.content.allergenInfo[MealType[value]] ? 'allergenInfoActive' : ''} id="allergenInfo" data-typemeal={MealType[value]} onClick={handleAllergenClicked}>
                                                                        {<InfoOutlinedIcon sx={{ fontSize: 14 }} />}
                                                                    </IconButton>
                                                                    <Overlay onHide={handleAllergenOnHideClicked} rootClose={true} show={show} target={target} container={ref} placement="right">
                                                                        <Popover id={'popover-' + `${index}`} style={{ width: "250px" }}>
                                                                            <Popover.Title as="h3" style={{ textAlign: 'center' }}>Allergene(s) detecté(s)</Popover.Title>
                                                                            <Popover.Content>
                                                                                <div style={{ textAlign: 'center', backgroundColor: 'rgb(238, 252, 224)' }}>
                                                                                    <div>Recette alternative</div>
                                                                                    <div>
                                                                                        <Card>
                                                                                            <Card.Header style={{ textAlign: 'center' }}>Française</Card.Header>
                                                                                            <Card.Img variant="top" src={`/images/menu/rables-de-lapin-a-estragon-et-tagliatelles-fraiches.jpg`} />
                                                                                            <Card.ImgOverlay style={{ padding: '0px', color: 'white', position: "relative" }}>
                                                                                                <Card.Title style={CardImgOverlayTitle.allgergenCard}><span style={{ alignSelf: "flex-end" }}>Rables de lapin estragon et tagliatelles fraiches</span></Card.Title>
                                                                                            </Card.ImgOverlay>
                                                                                        </Card>
                                                                                    </div>
                                                                                    <div><Button style={{ backgroundColor: "#a0d468", width: "100%" }}>Choisir cette recette</Button></div>
                                                                                </div>
                                                                            </Popover.Content>
                                                                        </Popover>
                                                                    </Overlay>
                                                                    {/* <Popover
                                                                        id={open ? 'popover_'+MealType[value]+'_'+props.dayNumber : undefined}
                                                                        open={open}
                                                                        anchorEl={anchorEl[MealType[value]+props.dayNumber]}
                                                                        onClose={() => handlePopoverAllergenClose(MealType[value]+props.dayNumber as never)}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'right',
                                                                        }}
                                                                    >
                                                                        The content of the Popover.
                                                                    </Popover> 
                                                                </span> 
                                                                */}
                                                            </DetailMealContent>
                                                        </DetailMeal>

                                                    )
                                                }
                                                return;
                                            })
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            </ThemeProvider>

                        </div>

                    </Card.Body>


                </Card>
            </Row >
        )
    }

    return render();
};

CardMenuDay.defaultProps = defaultProps;

export default CardMenuDay;