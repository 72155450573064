import { API_MENU_LOADED, LOADING_ACTION, MenuActionTypes, MenuState, REMOVE_MENU_STATE } from "./types";

const InitialState: MenuState = {
    menu: [],
    loading: false,
    // refresh: []
};

export function MenuReducers(state: MenuState = InitialState, action: MenuActionTypes): MenuState | null {
    if (action.type == REMOVE_MENU_STATE) {
        return null;
    }

    switch (action.type) {
        case LOADING_ACTION:
            return {
                ...state,
                loading: action.status
            }
        case API_MENU_LOADED:
            return {
                ...state,
                menu: action.menu
            }
        // case API_MEAL_REFRESH:

        //     let refresh = state.refresh;
        //     if (!refresh) {
        //         refresh = new Array();
        //         refresh[action.dayNumber] = new Array();
        //         refresh[action.dayNumber][action.mealTime] = new Array();
        //     } else {
        //         if (!refresh[action.dayNumber]) {
        //             refresh[action.dayNumber] = new Array();
        //         }
        //         if (!refresh[action.dayNumber][action.mealTime]) {
        //             refresh[action.dayNumber][action.mealTime] = new Array();
        //         }
        //     }
        //     refresh[action.dayNumber][action.mealTime][action.part] = action.meal;
        //     return {
        //         ...state,
        //         refresh: refresh
        //     }
        default:
            return state;
    }
}