import * as React from "react";

import useStrings from "src/localization/Localization";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";
import { ExecuteOnLoad } from "src/helpers/Lifecycle";
import { FromStore } from "src/helpers/Store";

import { ApiGetUserProfile } from "src/stores/apis/user/actions";
import { LoadingAction, SavePlanning } from "src/stores/plannification/actions";
import { SetEnabledDay, AddStartDate, CopyWeek } from "src/stores/plannification/days/actions";

import { Tab, Box, Alert, IconButton, Collapse, Tabs } from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';

import PlanningWeek from "src/components/bases/PlanningWeek";
import ButtonCustom from "src/components/bases/ButtonCustom";
import MenuButton from "src/components/bases/MenuButton";
import Footer from "src/components/bases/Footer";
import MessagesInPage from "src/components/bases/MessagesInPage";
import { Meal } from "src/api/dtos/menu/MenuProfile";
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';

export interface PlanningPageProps { }

interface CustomizedState {
    dateStart: Date
}

const PlanningPage: React.FunctionComponent<PlanningPageProps> = () => {
    const _dispatch = useDispatch();
    const _strings = useStrings();
    const location = useLocation();
    const _plannification = FromStore(state => state.plannification);
    const _isPlanningSave = FromStore(state => state.plannification.is_save.is_save);
    const _loading = FromStore(state => state.plannification.loading);

    // const _apiError = FromStore(state => state.page.ErrorCode);
    const history = useHistory();

    const historyState = location.state as CustomizedState;
    // const dateStart = historyState.dateStart.toLocaleDateString();
    let startDate = null as unknown as Date;
    let dayNumberToStart = 0;
    if (historyState != undefined) {
        dayNumberToStart = historyState.dateStart.getDay();
        startDate = historyState.dateStart;
    }
    if (_plannification.start_date?.start_date) {
        dayNumberToStart = new Date(_plannification.start_date?.start_date).getDay();
        startDate = new Date(_plannification.start_date?.start_date);
    }

    ExecuteOnLoad(() => {
        _dispatch(ApiGetUserProfile());

        if (startDate == null) {
            setTimeout(() => {
                history.push('/calendar')
            }, 4000);
        }
    }, true);

    ExecuteOnLoad(() => {
        if (startDate) {
            _dispatch(AddStartDate(startDate));
        }
        if (dayNumberToStart != 0) {
            for (let i = 1; i < dayNumberToStart; i++) {
                _dispatch(SetEnabledDay(1, i, Meal.Lunch, false));
                _dispatch(SetEnabledDay(1, i, Meal.Dinner, false));
            }
        }
    }, false);

    const NB_MAX_WEEK = 4;
    const [openAlert, setOpenAlert] = React.useState({});
    const [valueTab, setValueTab] = React.useState(0);
    const [showNbTab, setShowNbTab] = React.useState(0);
    const [realNbTabReal, setRealNbTab] = React.useState(1);
    const [errors, setErrors] = React.useState({
        is_error: false,
        error_message: null as { [key: string]: { [key: string]: string[] } } | null,
    });
    const weekday = { 1: _strings.monday, 2: _strings.thuesday, 3: _strings.wednesday, 4: _strings.thursday, 5: _strings.friday, 6: _strings.saturday, 7: _strings.sunday };

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        event.preventDefault();
        event.stopPropagation();

        setValueTab(newValue);
    };

    const handleRedirect = (event: React.SyntheticEvent, to: string) => {
        history.push('/' + to);
    }

    const handleAddTab = (event: React.SyntheticEvent, nbTabToAdd: number) => {
        event.preventDefault();
        event.stopPropagation();

        const isValid = validateForm();

        console.log(isValid.isValid, isValid.errorList, nbTabToAdd);
        if (isValid.isValid && nbTabToAdd >= 1) {
            setOpenAlert({ ...openAlert, [realNbTabReal]: false });

            setShowNbTab(showNbTab + nbTabToAdd);
            setValueTab(valueTab + nbTabToAdd);
            setRealNbTab(realNbTabReal + nbTabToAdd);
            let weekToCopy = Number(1) + Number(valueTab);
            _dispatch(CopyWeek(weekToCopy, nbTabToAdd));
        } else {
            if (isValid.errorList) {
                Object.entries(isValid.errorList).map(([weekKey, weeksValues]) => {
                    for (let i = 1; i <= NB_MAX_WEEK; i++) {
                        if (i == Number(weekKey)) {
                            let tmpOpenAlert = openAlert;
                            tmpOpenAlert[weekKey] = true;
                            setOpenAlert(tmpOpenAlert);
                        }
                    }
                })
            }

            setErrors({ ...errors, is_error: true, error_message: isValid.errorList })
        }
    }

    const handleSavePlanningAndGenerateMenu = (event: React.SyntheticEvent) => {
        event.preventDefault();

        const isValid = validateForm();
        if (isValid.isValid) {
            setErrors({ ...errors, is_error: false });
            setOpenAlert({ ...openAlert, [realNbTabReal]: false });
            _dispatch(SavePlanning(startDate, realNbTabReal, _plannification.weeks));
        } else {
            // let ul = document.createElement('ul');
            // Object.entries(isValid.errorList).map(([key, value]) => {
            //     let li = document.createElement('li');
            //     li.appendChild(document.createTextNode(key + " - " + value.join(', ')));
            //     ul.appendChild(li);
            // });

            // let span = document.createElement('span');
            // span.appendChild(document.createTextNode("Des convives ont été oubliés pour le "));
            // span.appendChild(ul);
            // console.log(span);

            setOpenAlert({ ...openAlert, [realNbTabReal]: true });
            setErrors({ ...errors, is_error: true, error_message: isValid.errorList });
        }
    }

    function validateForm(): { isValid: boolean, errorList: { [week: string]: { [day: string]: string[] } } | null } {

        let is_valid = true;
        let errorList: { [week: string]: { [day: string]: string[] } } | null = null; // = { "1": { "1": [] } };

        if (_plannification.weeks) {
            Object.entries(_plannification.weeks).map(([weekNumber, days]) => {
                Object.entries(days).map(([dayNumber, meals]) => {
                    Object.entries(meals as { [key: number]: { persons: object, isEnabled: boolean } }).map(([lunchType, value]) => {
                        let tmpIsValid = (Object.keys(value.persons).length > 0);
                        if (is_valid) {
                            is_valid = tmpIsValid;
                        }
                        if (!tmpIsValid && value.isEnabled) {
                            if (errorList === null) {
                                errorList = { [weekNumber]: { [dayNumber]: [] } };
                            }

                            if (!errorList[weekNumber]) {
                                errorList[weekNumber] = { [dayNumber]: new Array() };
                            }
                            if (!errorList[weekNumber][dayNumber]) {
                                errorList[weekNumber][dayNumber] = new Array();
                            }
                            errorList[weekNumber][dayNumber].push(_strings[lunchType]);
                        }
                    })
                })
            })
        }

        if (errorList === null) {
            is_valid = true;
        }
        return { isValid: is_valid, errorList: errorList ?? null };
    }

    function render(): JSX.Element {
        if (_isPlanningSave) {
            setTimeout((e: any) => {
                _dispatch(LoadingAction(false));
                handleRedirect(e, 'menu');
            }, 4000);
        }

        return (
            <div>
                <div>
                    <MenuButton selected={0}></MenuButton>

                    <MessagesInPage></MessagesInPage>

                    {
                        _loading ?
                            <div className="loader">
                                <CircularProgress style={{ color: "#fd694d" }} />
                            </div>
                            : null
                    }


                    <div className={_loading ? 'opacity' : ''} style={{ margin: "35px 0px" }}>
                        <TabContext value={valueTab.toString()}>
                            <Box className="marginPages" sx={{
                                "& .MuiButtonBase-root.MuiTab-root": {
                                    minHeight: "auto !important",
                                    textTransform: "none"
                                },
                                "& > div": {
                                    display: "inline-block",
                                    minHeight: "max-content",
                                    verticalAlign: "middle",
                                    marginRight: "20px"
                                },
                                "& .MuiTabs-root": {
                                    display: "inline-block"
                                },
                                "& .MuiTab-root.Mui-selected": {
                                    color: "white !important",
                                    backgroundColor: "#fd694d",
                                    borderRadius: "5px"
                                },
                                "& .MuiTabs-indicator": {
                                    backgroundColor: "transparent !important"
                                }
                            }}>
                                <div>{_strings.your_weeks} : </div>

                                <Tabs value={valueTab.toString()} onChange={handleChangeTab} className="tabList">
                                    <Tab
                                        value={"0"}
                                        label={<div>{_strings.week + " 1"}</div>}
                                    />
                                    {
                                        [...Array(showNbTab)].map((el, index) => (
                                            <Tab label={`${_strings.week} ${index + 2}`} key={index} value={`${index + 1}`} />
                                        )
                                        )
                                    }
                                </Tabs>
                                {/* 
                                <TabList onChange={handleChangeTab} className="tabList">
                                    <Tab label={`${_strings.week} 1`} value={"0"} />
                                    {
                                        [...Array(showNbTab)].map((el, index) => {
                                            let i = index +1;
                                            console.log(i);
                                            return <Tab label={`${_strings.week} ${index + 2}`} key={index} value={`${i}`} />
                                        }
                                        )
                                    }
                                </TabList> */}

                                {/* <div style={{ color: "red", display: (errors.is_error ? "block" : "none") }} >
                                    {
                                        <div>
                                            {"Des convives ont été oubliés pour : "}
                                            <ul>
                                                {
                                                    Object.entries(errors.error_message).map(([weekKey, weeksValues]) => (
                                                        Object.entries(weeksValues).map(([dayKey, daysValues]) => (
                                                            <li key={dayKey}>{weekKey + " => " + weekday[dayKey] + " - " + daysValues.join(', ')}</li>
                                                        ))
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    }
                                </div> */}
                            </Box>

                            <TabPanel value="0">
                                <div style={{ marginLeft: "8%", display: (errors.is_error ? "block" : "none") }} >
                                    {
                                        errors.error_message && errors.error_message[1] ?
                                            <div>
                                                <Collapse in={openAlert[1]}>
                                                    <Alert severity="error"
                                                        action={
                                                            <IconButton
                                                                aria-label="close"
                                                                color="inherit"
                                                                size="small"
                                                                onClick={() => {
                                                                    setOpenAlert({ ...openAlert, [1]: false });
                                                                }}
                                                            >
                                                                <CloseIcon fontSize="inherit" />
                                                            </IconButton>
                                                        }
                                                    >
                                                        {"Des convives ont été oubliés pour : "}
                                                        <ul>
                                                            {
                                                                Object.entries(errors.error_message[1]).map(([dayKey, daysValues]) => (
                                                                    <li key={dayKey}>{weekday[dayKey] + " - " + daysValues.join(', ')}</li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </Alert>
                                                </Collapse>
                                            </div>
                                            :
                                            null
                                    }
                                </div>

                                <PlanningWeek weekNumber={1} dayToStart={dayNumberToStart} />
                            </TabPanel>
                            {
                                [...Array(showNbTab)].map((el, index) => (
                                    <TabPanel key={index} value={`${index + 1}`}>
                                        <div style={{ marginLeft: "8%", display: (errors.is_error ? "block" : "none") }} >
                                            {
                                                errors.error_message && errors.error_message[index + 2] ?
                                                    <div>
                                                        <Collapse in={openAlert[index + 2]}>
                                                            <Alert severity="error"
                                                                action={
                                                                    <IconButton
                                                                        aria-label="close"
                                                                        color="inherit"
                                                                        size="small"
                                                                        onClick={() => {
                                                                            setOpenAlert({ ...openAlert, [index + 2]: false });
                                                                        }}
                                                                    >
                                                                        <CloseIcon fontSize="inherit" />
                                                                    </IconButton>
                                                                }
                                                            >
                                                                {"Des convives ont été oubliés pour : "}
                                                                <ul>
                                                                    {
                                                                        Object.entries(errors.error_message[index + 2]).map(([dayKey, daysValues]) => (
                                                                            <li key={dayKey}>{weekday[dayKey] + " - " + daysValues.join(', ')}</li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                            </Alert>
                                                        </Collapse>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>

                                        <PlanningWeek weekNumber={index + 2} />
                                    </TabPanel>
                                )
                                )
                            }
                        </TabContext>

                        <div className="marginPages" style={{ display: "flex", justifyContent: "flex-end" }}>
                            {(realNbTabReal <= 3) ? (
                                <ButtonCustom style={{ backgroundColor: "#f2600d" }} label={_strings.plannification_button_1} onClick={(e) => { handleAddTab(e, 1) }} ></ButtonCustom>
                            ) : null}
                            {(realNbTabReal < 2) ? (
                                <ButtonCustom style={{ backgroundColor: "#8fc740" }} label={_strings.plannification_button_2.replace("##NB_WEEKS##", `${(realNbTabReal == 1 ? 4 : (4 - realNbTabReal))}`)}
                                    onClick={(e) => {
                                        let nbWeeks = (realNbTabReal == 1 ? 3 : (4 - realNbTabReal));
                                        handleAddTab(e, nbWeeks);
                                    }}
                                ></ButtonCustom>
                            ) : null}
                            {(realNbTabReal == 1) ? (
                                <ButtonCustom style={{ backgroundColor: "white", color: "#8fc740", borderColor: "#8fc740" }} onClick={(e) => { handleSavePlanningAndGenerateMenu(e) }} label={_strings.plannification_button_3}></ButtonCustom>
                            ) : null}
                            {(realNbTabReal > 1) ? (
                                <ButtonCustom style={{ backgroundColor: "#f2600d" }} onClick={(e) => { handleSavePlanningAndGenerateMenu(e) }} label={_strings.plannification_button_4}></ButtonCustom>
                            ) : null}
                        </div>
                    </div>

                    <Footer></Footer>
                </div>
            </div >

        );
    }

    return render();
};




export default PlanningPage;