import { AsyncAction } from "../types";
import { HandleError, HandleSuccess, ResetError } from "../page/actions";
import ServiceLocator from "../../services/ServiceLocator";
import MenuGenerateResponse from "src/api/dtos/menu/MenuGenerateResponse";
import { API_MEAL_REFRESH, API_MENU_LOADED, MenuActionTypes, REMOVE_MENU_STATE, LOADING_ACTION } from "./types";
import MenuPatchResponse from "src/api/dtos/menu/MenuPatchResponse";
import * as DateFns from 'date-fns';

export function ApiGetMenuGenerate(): AsyncAction {
    return async dispatch => {
        dispatch(ResetError());
        let response = await ServiceLocator.MyEatWellService.GetMenuGenerate();
        if (response.is_success) {
            // dispatch(RemoveMenu());
            dispatch(MenuLoaded(response));
            dispatch(HandleSuccess());
        }
        else {
            dispatch(HandleError(response));
        }

    };
}

export function ApiGetMeals(dateFrom?: string, dateTo?: string): AsyncAction {
    return async dispatch => {
        dispatch(ResetError());
        let response;
        if(dateFrom && dateTo) {
            response = await ServiceLocator.MyEatWellService.GetMeals(dateFrom, dateTo);
        } else {
            response = await ServiceLocator.MyEatWellService.GetMeals();
        }
        if (response.is_success) {
            dispatch(RemoveMenu());
            dispatch(MenuLoaded(response));
            dispatch(HandleSuccess());
        }
        else {
            dispatch(HandleError(response));
        }
    }
}

export function ApiPatchMeals(idMeal: number, date: string, mealTime: string, part: string, dayNumber: number): AsyncAction {
    return async dispatch => {
        dispatch(ResetError());
        dispatch(LoadingAction(true));

        let cardDate = new Date(date);
        let response = await ServiceLocator.MyEatWellService.PatchMeal(idMeal);
        if (response.is_success) {
            // dispatch(RefreshMeal(response, mealTime, part, dayNumber));

            let currentDateFrom = DateFns.startOfWeek(cardDate, { weekStartsOn: 1 });
            let currentDateTo = DateFns.endOfWeek(cardDate, { weekStartsOn: 1 });
            dispatch(ApiGetMeals(DateFns.format(currentDateFrom, "yyyy-MM-dd"), DateFns.format(currentDateTo, "yyyy-MM-dd")));

            dispatch(HandleSuccess());

            dispatch(LoadingAction(false));
        }
        else {
            dispatch(HandleError(response));
        }
    }
}

export function RefreshMeal(response: MenuPatchResponse, mealTime: string, part: string, dayNumber: number): MenuActionTypes {
    return {
        type: API_MEAL_REFRESH,
        mealTime: mealTime,
        part: part,
        dayNumber: dayNumber,
        meal: response.data
    };
}

export function MenuLoaded(response: MenuGenerateResponse): MenuActionTypes {
    return {
        type: API_MENU_LOADED,
        menu: response.data
    };
}

export function RemoveMenu(): MenuActionTypes {
    return {
        type: REMOVE_MENU_STATE
    };
}

export function LoadingAction(status: boolean) {
    return {
        type: LOADING_ACTION,
        status: status,
    }
}