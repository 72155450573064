import LoginResponse from "src/api/dtos/auth/LoginResponse";
import { Constants } from "src/configuration/constants";
import ServiceLocator from "../../services/ServiceLocator";
import { ApiGetUserProfile } from "../apis/user/actions";
import { HandleError, HandleSuccess, ResetError } from "../page/actions";
import { AppDispatcher, AsyncAction, AsyncStateAction } from "../types";
import { AuthActionTypes, AuthState, AUTH_LOGGED_IN, AUTH_LOGGED_OUT, AUTH_REMEMBER_ME } from "./types";
import { ResetActionTypes, FORGOT_PASSWORD, RESET_PASSWORD } from "./types";

export function Login(username: string, password: string, rememberMe: boolean): AsyncAction {
		
	return async dispatch => {
		dispatch(ResetError())
		let response = await ServiceLocator.AuthService.Login({
			login: username, password: password,
			client_id: Constants.CLIENT_ID, client_secret: Constants.CLIENT_SECRET
		});

		if (response.is_success) {
			dispatch(LoggedIn(response));
			if (rememberMe) {
				dispatch(RememberUser(username));
			}
			dispatch(ApiGetUserProfile());
		}
		else {
			dispatch(HandleError(response));
		}
	};
}

export function RefreshTokenIfNeeded(action: AsyncAction): AsyncStateAction {
	return async (dispatch, getState) => {
		let authenticationState = getState().auth;
		let now = new Date(new Date().getTime() + 5 * 60000); //+5 minutes for time sync issues
		let expirationDate = new Date(authenticationState.ExpirationDate);
		if (now >= expirationDate) {
			let response = await ServiceLocator.AuthService.Refresh({
				refresh_token: authenticationState.RefreshToken,
				client_id: Constants.CLIENT_ID,
				client_secret: Constants.CLIENT_SECRET
			});

			if (response.is_success) {
				dispatch(LoggedIn(response));
			}
			else {
				dispatch(Logout());
				dispatch(HandleError(response));
				return;
			}
		}

		dispatch(action);
	};
}

export async function WithRefreshToken<T>(action: () => Promise<T>, dispatch: AppDispatcher, authenticationState: AuthState): Promise<T | undefined> {
	let now = new Date(new Date().getTime() + 5 * 60000); //+5 minutes for time sync issues
	let expirationDate = new Date(authenticationState.ExpirationDate);
	if (now >= expirationDate) {
		let response = await ServiceLocator.AuthService.Refresh({
			refresh_token: authenticationState.RefreshToken,
			client_id: Constants.CLIENT_ID,
			client_secret: Constants.CLIENT_SECRET
		});

		if (response.is_success) {
			dispatch(LoggedIn(response));
		}
		else {
			dispatch(HandleError(response));
			return;
		}
	}

	return await action();
}

export function Logout(): AuthActionTypes {
	return {
		type: AUTH_LOGGED_OUT
	}
}

function LoggedIn(response: LoginResponse): AuthActionTypes {
	return {
		type: AUTH_LOGGED_IN,
		accessToken: response.data.access_token,
		refreshToken: response.data.refresh_token,
		expirationDate: new Date(new Date().getTime() + response.data.expires_in * 1000),
		tokenType: response.data.token_type
	};
}

function RememberUser(login: string): AuthActionTypes {
	return {
		type: AUTH_REMEMBER_ME,
		login: login
	};
}

function ResetPasswordResponse(reset:boolean = false): ResetActionTypes {
	return {
		type: RESET_PASSWORD,
		reset: reset
	};
}

function ForgotPasswordResponse(forgot:boolean = false): ResetActionTypes {
	return {
		type: FORGOT_PASSWORD,
		forgot: forgot
	};
}

export function ForgotPassword(email: string, successMessage: string = ""): AsyncAction {
	return async dispatch => {

		dispatch(ResetError())
		let response = await ServiceLocator.AuthService.ForgotPassword({
			email: email
		});
		
		if (response.is_success) {
			dispatch(ForgotPasswordResponse(true));
			dispatch(HandleSuccess(successMessage));
		}
		else {
			dispatch(HandleError(response));
			return;
		}
	};
}

export function ResetPassword(token: string, password: string, successMessage: string = ""): AsyncAction {
	return async dispatch => {
		dispatch(ResetError())
		let response = await ServiceLocator.AuthService.ResetPassword({
			token: token,
			password: password,
		});

		if (response.is_success) {
			dispatch(ResetPasswordResponse(true));
			dispatch(HandleSuccess(successMessage));
		}
		else {
			dispatch(HandleError(response));
			return;
		}
	};
}

export function ChangePassword(old_password: string, new_password: string, successMessage: string): AsyncAction {
	return async dispatch => {
		dispatch(ResetError());

		let response = await ServiceLocator.AuthService.ChangePassword({
			old_password: old_password,
			new_password: new_password
		})
		if (response.is_success) {
			dispatch(ResetPasswordResponse(true));
			dispatch(HandleSuccess(successMessage));
		}
		else {
			dispatch(HandleError(response));
		}
	};
}