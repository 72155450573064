import * as React from "react";
import { useDispatch } from "react-redux";
import { FromStore } from "src/helpers/Store";
import { ResetMessage } from "src/stores/page/actions";
import Messages from "./Messages";
import MessagesViewModel from "./MessagesViewModel";

const MessagesInPage: React.FunctionComponent = props => {
	const _duration = FromStore(x => x.page.MessageDuration);
	const _errorCode = FromStore(x => x.page.ErrorCode);
	const _call = FromStore(x => x.page.MessageCall);
	const _viewModel = new MessagesViewModel();
	const _dispatch = useDispatch();

	if (_call !== null) {
		_call(_viewModel);
		_dispatch(ResetMessage());
	}

	function render(): JSX.Element {
		return <Messages duration={_duration} state={_viewModel} error_code={_errorCode} />
	}

	return render();
};

export default MessagesInPage;
