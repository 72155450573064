import { AsyncAction } from "../types";
import ServiceLocator from "../../services/ServiceLocator";
import { HandleError, HandleSuccess, ResetError } from "../page/actions";
import GetRecipesResponse from "src/api/dtos/recipes/GetRecipesResponse";
import { API_RECIPES_LOADED, RecipesActionTypes, REMOVE_RECIPES } from "./types";

export function ApiGetRecipes(food_id: number): AsyncAction {

    return async dispatch => {
        dispatch(ResetError());
        let response = await ServiceLocator.MyEatWellService.GetRecipes(food_id);
        if (response.is_success) {            
            // dispatch(ApiGetRecipesPicture(food_id));
            dispatch(RecipesLoaded(food_id, response));
            dispatch(HandleSuccess());
        }
        else {
            dispatch(HandleError(response));
        }
    }
}

export function RecipesLoaded(food_id: number, response: GetRecipesResponse): RecipesActionTypes {
    return {
        type: API_RECIPES_LOADED,
        id_food: food_id,
        recipe: response.data
    };
}

export function RemoveRecipes(): RecipesActionTypes {
    return {
        type: REMOVE_RECIPES
    };
}

// export function LoadingImageStatus(food_id: number, status: boolean) {
//     return {
//         type: LOADING_STATUS,
//         id_food: food_id,
//         loadImage: status,
//     };
// }

// export function ApiGetRecipesPicture(food_id: number): AsyncAction { /*Promise<XMLHttpRequest> {*/
//     return async dispatch => {
//         let response = await ServiceLocator.MyEatWellService.GetRecipePicture(food_id);

//         if (response.status == 200) {
//             dispatch(LoadingImageStatus(food_id, true));
//         }
//     }
// }