import * as React from 'react';
import useStrings from "src/localization/Localization";
import { FromStore } from "src/helpers/Store";

import { ElementsMenu, MealType } from 'src/api/dtos/menu/MenuProfile';

import { Tab, TextareaAutosize } from '@mui/material';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box } from "@mui/system";

import { Row, Col } from "react-bootstrap";

import StarBorderIcon from '@mui/icons-material/StarBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { capitalizeFirstLetter, useCheckMobileScreen } from 'src/helpers/fonctions';

import { GuestGroup } from 'src/api/dtos/user/UserProfile';
import AvatarLetter from './AvatarLetter';
import { Avatar } from '@mui/material';
import Urls, { API_HOST } from "src/configuration/constants";

// import { styled } from "@mui/material/styles";

type CardMenuDetailProps = {
    element: ElementsMenu
};

const defaultProps: CardMenuDetailProps = {
    element: {} as ElementsMenu
}

const CardMenuDetail: React.FunctionComponent<CardMenuDetailProps> = props => {
    const _strings = useStrings();
    const mobileScreen = useCheckMobileScreen();

    const _recipe = FromStore(state => state.recipes![props.element.food_id]);

    let recipeCharacteristics =
        _recipe && _recipe.characteristics ? {
            preparation_time: _recipe.characteristics.preparation_time,
            cuisson_time: _recipe.characteristics.cooking_time,
            cost: _recipe.characteristics.cost,
            difficulty: _recipe.characteristics.difficulty,
        }
            : null;

    let recipeNutritrionalInfo =
        _recipe && _recipe.nutritional_information ? {
            energy: _recipe.nutritional_information.energy,
            fatty_acid: Math.round(_recipe.nutritional_information.fatty_acid * 10) / 10,
            fiber: Math.round(_recipe.nutritional_information.fiber * 10) / 10,
            glucid: Math.round(_recipe.nutritional_information.glucid * 10) / 10,
            lipid: Math.round(_recipe.nutritional_information.lipid * 10) / 10, 
            protein: Math.round(_recipe.nutritional_information.protein * 10) / 10,
            salt: Math.round(_recipe.nutritional_information.salt * 10) / 10,
            sugar: Math.round(_recipe.nutritional_information.sugar * 10) / 10,
        }
            : null;

    // let preparationStartIndex = _recipe.steps.search("Préparation");
    // let ingredientStartIndex = _recipe.steps.search("Ingrédients");
    // let preparationSteps = new Array();
    // let ingredientsSteps = new Array();
    // if (preparationStartIndex != -1) {
    //     let p = _recipe.steps.substring(preparationStartIndex);
    //     preparationSteps = p.split(".").filter(String);

    //     let i = _recipe.steps.substring(ingredientStartIndex, preparationStartIndex);
    //     ingredientsSteps = i.split(",").filter(String);
    //     console.log(ingredientsSteps);
    // }

    const _persons = FromStore(state => state.apis.profile.user.guests);
    let guestPortions = new Array();
    let calorieApport = new Array();
    if (_persons) {
        Object.entries(_persons).forEach(([index, guestInfo]) => {
            Object.entries(props.element.portions).forEach(([index, portionsInfo]) => {
                if (!guestPortions[guestInfo.group]) {
                    guestPortions[guestInfo.group] = new Array();
                }
                if (!calorieApport[guestInfo.group]) {
                    calorieApport[guestInfo.group] = new Array();
                }
                if (portionsInfo.guest_id == guestInfo.id) {
                    let quantity = Math.round((portionsInfo.quantity * 100) * 100) / 100;
                    let indexKcal = recipeNutritrionalInfo?.energy.search("Kcal");                    
                    let energy = 0;
                    if (indexKcal && indexKcal != -1) {
                        energy = Number(recipeNutritrionalInfo?.energy.replace(',','.').substring(0, indexKcal));
                    }
                    guestPortions[guestInfo.group].push(
                        {
                            id: guestInfo.id,
                            picture: guestInfo.picture_file,
                            fullName: guestInfo.first_name + " " + guestInfo.last_name,
                            quantity: quantity,
                            has_allergen_alert: portionsInfo.has_allergen_alert,
                        }
                    )
                    calorieApport[guestInfo.group].push(
                        {
                            id: guestInfo.id,
                            picture: guestInfo.picture_file,
                            fullName: guestInfo.first_name + " " + guestInfo.last_name,
                            calorie: (quantity * energy) / 100,
                        }
                    )
                }
            })
        });
    }

    const [tabValue, setTabValue] = React.useState('1');

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    function getImage(food_id: number) {

        //     ApiGetRecipesPicture(image).then(
        //         result => {
        //             if (result.responseURL) {
        //                 let paths = imagesPath;
        //                 paths[image] = result.responseURL;
        //                 setImagesPath(paths);
        //             }
        //         }
        //     );

        const imagePath = API_HOST + Urls.GET_RECIPES_PICTURE + '/' + food_id;
        if (imagePath) {
            return imagePath;
        } else {
            return '/images/menu/loading-dots.gif';
        }
        // let file = new Image();
        // file.src = imagePath;

        // if (file.height > 0) {
        //     return imagePath;
        // } else {
        //     return '/images/menu/no-recipe.jpg';
        // }

    }

    function createStars(nbStars: number) {
        let stars: any[] = [];
        [1, 2, 3].map((i, index) => (
            (i <= nbStars) ?
                stars.push(<StarBorderIcon key={index} fontSize="small" sx={{ color: "rgb(253, 105, 77)" }} />)
                :
                stars.push(<StarBorderIcon key={index} fontSize="small" />)
        ));
        return stars;
    }

    function render(): JSX.Element {

        let informations =
            recipeCharacteristics ?
                [
                    {
                        title: _strings.preparation,
                        note: <div style={{ color: "rgb(253, 105, 77)" }}>{recipeCharacteristics.preparation_time} min</div>,
                        image: <img src="/images/menu/icons/preparation_icon.png" width="30px" height="30px" />
                    },
                    {
                        title: _strings.cooking,
                        note: <div style={{ color: "rgb(253, 105, 77)" }}>{recipeCharacteristics.cuisson_time} mins</div>,
                        image: <img src="/images/menu/icons/frying_pan_icon.png" width="30px" height="30px" />
                    },
                    {
                        title: _strings.total_time,
                        note: <div style={{ color: "rgb(253, 105, 77)" }}>{recipeCharacteristics.preparation_time + recipeCharacteristics.cuisson_time} mins</div>,
                        image: <img src="/images/menu/icons/total_time_icon.png" width="30px" height="30px" />
                    },
                    {
                        title: _strings.cost,
                        note: createStars(recipeCharacteristics.cost),
                        image: <img src="/images/menu/icons/cost_icon.png" width="30px" height="30px" />
                    },
                    {
                        title: _strings.difficulty,
                        note: createStars(recipeCharacteristics.difficulty),
                        image: ""
                    },

                ]
                : null;


        let favoriteFilled = 2;

        // let preparation = [
        //     "Faire chauffer le concentré de tomate sur feu doux.",
        //     "Casser directement les oeufs dans la casserole.",
        //     "Battre énergiquement sans cesser de tourner."
        // ];

        // let ingredients = [
        //     {
        //         name: "Concentré de tomate",
        //         quantity: "50 g"
        //     },
        //     {
        //         name: "Jambon blanc sans couenne",
        //         quantity: "100 g"
        //     },
        //     {
        //         name: "Lait 1/2 écrémé UHT",
        //         quantity: "150 ml"
        //     }
        // ];


        

        // const DivMealImage = styled('div')({
        //     display: "inline-flex",
        //     width: "100%",
        //     height: "480px",
        //     boxShadow: "0 2px 15px 1px rgba(0, 0, 0, 0.1)",
        //     margin: "3% 0",
        //     position: "relative",
        //     '&::before': {
        //         background: (mobileScreen ? `url(${getImage(props.element.food_id)}) no-repeat center` : ""),
        //         backgroundSize: "cover",
        //         display: 'block',
        //         position: "absolute",
        //         // content: (mobileScreen ? `url(${getImage(props.element.food_id)})` : "''"),
        //         content: "''",
        //         left: '0',
        //         top: '0',
        //         zIndex: -1,
        //         width: '100%',
        //         height: '100%',
        //         opacity: '0.4',
        //     }
        // });

        let listTabToDisplay = [
            {label:_strings.menu_informations, id: 0}, 
            {label: _strings.recipe, id: 0}, 
            {label: _strings.portions, id: 0}, 
            {label:_strings.nutritional_information, id: 0}
        ];
        
        if (props.element.part == MealType.Bread) {
            listTabToDisplay = listTabToDisplay.filter(function(ele){ 
                return ele.label != _strings.menu_informations; 
            });
        }

        if(_recipe && _recipe.steps.length === 0) {
            listTabToDisplay = listTabToDisplay.filter(function(ele){ 
                return ele.label != _strings.recipe; 
            });
        }

        const buttonsTabList = listTabToDisplay.map((content, i) => {
            listTabToDisplay[i].id = i+1; 
            return (
                <Tab key={i} label={`${content.label}`} value={`${i+1}`} />
            )
        });

        const isTabExist = (value: string) => {
            let obj = listTabToDisplay.find(element => element.label === value);

            if(!obj) {
                return "";
            } 

            return obj.id.toString();
        } 

        return (
            <Row md={2} >
            {/* <div style={{ display: "inline-flex", width: "100%", height: "480px", boxShadow: "0 2px 15px 1px rgba(0, 0, 0, 0.1)", margin: "3% 0" }}> */}
            {/* <DivMealImage> */}
                
                <div style={{ justifyContent: "center", textAlign: 'center', minWidth:(mobileScreen ? "30%" : 'inherit' ), maxWidth: (mobileScreen ? 'inherit' : "30%") }}>
                    <img src={getImage(props.element.food_id)} style={{ maxHeight: "350px" }} />
                </div>
                <div style={{ flexDirection: "row", justifyContent: "center", textAlign: "center", minWidth: "70%" }}>
                    <div className="bold">{_strings.tr(props.element.part.toLowerCase())}</div>
                    <div className="margin_1pourcent">{capitalizeFirstLetter(props.element.food_name)}</div>
                    <div>
                        <TabContext value={tabValue}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile onChange={handleTabChange} sx={{
                                    "& .Mui-selected": {
                                        color: "#8fc740 !important",
                                    },
                                    "& .MuiTabs-indicator": {
                                        backgroundColor: "#8fc740 !important",
                                        height: "4px"
                                    },
                                    "& .MuiTab-root": {
                                        textTransform: "none"
                                    },
                                    "& .MuiTabPanel-root": {
                                        padding: "15px"
                                    },
                                    "& .MuiTabs-scrollButtons.Mui-disabled": {
                                        opacity: '0.3'
                                      }
                                }}
                                >
                                    {buttonsTabList}
                                </TabList>
                            </Box>
                            {/* Informations */}
                            
                            <TabPanel value={isTabExist(_strings.menu_informations) }>
                                <Col style={{ display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "space-around", alignItems: "center" }}>
                                    {
                                        informations ?
                                            informations.map((info, index) => {
                                                return (
                                                    <table key={index}>
                                                        <tbody>
                                                            <tr>
                                                                <td rowSpan={2} className="margin_1pourcent" style={{ padding: "10px" }}>{info.image}</td>
                                                                <td>{info.title}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        <React.Fragment>
                                                                            {info.note}
                                                                        </React.Fragment>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                )
                                            })
                                            : null
                                    }
                                </Col>
                                <div style={{ opacity: 0.3, pointerEvents: "none" }}>
                                    <div className="margin_2pourcent" >
                                        <div className="margin_1pourcent">{_strings.note_recipe}</div>
                                        <div>
                                            {
                                                [1, 2, 3, 4, 5].map((i, index) => {
                                                    if (i <= favoriteFilled) {
                                                        return (<FavoriteIcon sx={{ color: "rgb(222 51 51)" }} key={i} />)
                                                    } else {
                                                        return (<FavoriteBorderOutlinedIcon key={i} />)
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <TextareaAutosize minRows={6} maxRows={8} style={{ width: "80%", resize: "none", borderRadius: "5px", backgroundColor: "rgb(245,245,247)", padding: "1%" }} placeholder={_strings.add_note + '...'} />
                                    </div>
                                </div>
                            </TabPanel>     
                            {/* Recette */}
                            <TabPanel value={isTabExist(_strings.recipe)}>
                                <div style={{ textAlign: "left" }}>
                                    {
                                        (_recipe && _recipe.steps != "") ?

                                            <div>
                                                <div className="bold" style={{ margin: "2% 0" }}>{_strings.preparation}</div>
                                                <div style={{ whiteSpace: "pre-wrap"}}>{_recipe.steps}</div>
                                            </div>
                                            : null
                                    }
                                    {/* <div>
                                        <div className="bold" style={{ margin: "2% 0" }}>{_strings.preparation}</div>
                                        <div>
                                            <ol>
                                                {
                                                    preparationSteps.map((info, index) => {
                                                        return (<li key={index}> {info} </li>)
                                                    })
                                                }

                                            </ol>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="bold" style={{ margin: "2% 0" }}>{_strings.ingredients}</div>
                                        {
                                            ingredients.map((info, index) => {
                                                return (
                                                    <div key={index} style={{ display: 'inline-flex', width: '100%' }}>
                                                        <div style={{ width: '50%' }} >{info.name}</div>
                                                        <div style={{ width: '50%', textAlign: "right" }} >{info.quantity}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div> */}
                                </div>
                            </TabPanel>
                            {/* Portions */}
                            <TabPanel value={isTabExist(_strings.portions)}>
                                <div className="bold">{_strings.family_member}</div>
                                <Col style={{ display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "space-between", alignItems: "center" }}>
                                    {
                                        guestPortions[GuestGroup.FamilyMember] ?
                                            [...guestPortions[GuestGroup.FamilyMember]].map((val: any, index: any) => {
                                                return (
                                                    <div key={index} className="margin_2pourcent">
                                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                                            {
                                                                val.picture ?
                                                                    <Avatar variant="rounded" alt={val.fullName} src={val.picture} />
                                                                    :
                                                                    <AvatarLetter variant="rounded" className="" fullName={val.fullName} />
                                                            }
                                                        </div>
                                                        <div className="bold">{val.fullName}</div>
                                                        <div className="color-gray">{val.quantity}g</div>
                                                    </div>
                                                )
                                            })
                                            : null
                                    }
                                </Col>
                                <div className="bold">{_strings.regular_member}</div>
                                <Col style={{ display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "space-around", alignItems: "center" }}>
                                    {
                                        guestPortions[GuestGroup.RegularGuest] ?
                                            [...guestPortions[GuestGroup.RegularGuest]].map((val: any, index: any) => {
                                                return (
                                                    <div key={index} className="margin_2pourcent">
                                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                                            {
                                                                val.picture ?
                                                                    <Avatar variant="rounded" alt={val.fullName} src={val.picture} />
                                                                    :
                                                                    <AvatarLetter variant="rounded" className="" fullName={val.fullName} />
                                                            }
                                                        </div>
                                                        <div className="bold">{val.fullName}</div>
                                                        <div className="color-gray">{val.quantity}g</div>
                                                    </div>
                                                )
                                            })
                                            : null
                                    }
                                </Col>
                            </TabPanel>
                            <TabPanel value={isTabExist(_strings.nutritional_information)} >
                                <div style={{ paddingRight: "20px", display: 'block' }}>
                                    <div style={{ textAlign: 'initial', marginBottom: "20px" }}>
                                        <div style={{ fontWeight: "bold" }}>{_strings.calories_apport}</div>
                                        <div style={{ marginLeft: "20px" }}>
                                            <div className="bold textAlign_center">{_strings.family_member}</div>
                                            <Col style={{ display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "space-around", alignItems: "center", textAlign: 'center' }}>
                                                {
                                                    calorieApport[GuestGroup.FamilyMember] ?
                                                        [...calorieApport[GuestGroup.FamilyMember]].map((val: any, index: any) => {
                                                            return (
                                                                <div key={index} className="margin_2pourcent">
                                                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                                                        {
                                                                            val.picture ?
                                                                                <Avatar variant="rounded" alt={val.fullName} src={val.picture} />
                                                                                :
                                                                                <AvatarLetter variant="rounded" className="" fullName={val.fullName} />
                                                                        }
                                                                    </div>
                                                                    <div className="bold">{val.fullName}</div>
                                                                    <div className="color-gray">{val.calorie} Kcal</div>
                                                                </div>
                                                            )
                                                        })
                                                        : null
                                                }
                                            </Col>
                                            <div className="bold textAlign_center">{_strings.regular_member}</div>
                                            <Col style={{ display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "space-around", alignItems: "center", textAlign: 'center' }}>
                                                {
                                                    calorieApport[GuestGroup.RegularGuest] ?
                                                        [...calorieApport[GuestGroup.RegularGuest]].map((val: any, index: any) => {
                                                            return (
                                                                <div key={index} className="margin_2pourcent">
                                                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                                                        {
                                                                            val.picture ?
                                                                                <Avatar variant="rounded" alt={val.fullName} src={val.picture} />
                                                                                :
                                                                                <AvatarLetter variant="rounded" className="" fullName={val.fullName} />
                                                                        }
                                                                    </div>
                                                                    <div className="bold">{val.fullName}</div>
                                                                    <div className="color-gray">{val.calorie} kcal</div>
                                                                </div>
                                                            )
                                                        })
                                                        : null
                                                }
                                            </Col>
                                        </div>
                                    </div>
                                    <div style={{ textAlign: 'initial' }}>
                                        <div style={{ fontWeight: "bold" }}>{_strings.nutritional_declaration}</div>
                                        <div style={{ color: "rgb(156 147 147)" }}>
                                            <div style={{ display: "flex", margin: "5px 0" }}>
                                                <span style={{ width: "50%" }} >{_strings.energy}</span>
                                                <span style={{ width: "50%", textAlign: "right" }}>{recipeNutritrionalInfo?.energy}</span>
                                            </div>
                                            <div style={{ margin: "5px 0" }}>
                                                <div style={{ display: "flex" }}>
                                                    <span style={{ width: "50%" }} >{_strings.lipid}</span>
                                                    <span style={{ width: "50%", textAlign: "right" }}>{recipeNutritrionalInfo?.lipid}</span>
                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    <span style={{ width: "50%", marginLeft: "5px" }} >{_strings.fatty_acid}</span>
                                                    <span style={{ width: "50%", textAlign: "right" }}>{recipeNutritrionalInfo?.fatty_acid}</span>
                                                </div>
                                            </div>
                                            <div style={{ margin: "5px 0" }}>
                                                <div style={{ display: "flex" }}>
                                                    <span style={{ width: "50%" }} >{_strings.glucid}</span>
                                                    <span style={{ width: "50%", textAlign: "right" }}>{recipeNutritrionalInfo?.glucid}</span>
                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    <span style={{ width: "50%", marginLeft: "5px" }} >{_strings.sugar}</span>
                                                    <span style={{ width: "50%", textAlign: "right" }}>{recipeNutritrionalInfo?.sugar}</span>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", margin: "5px 0" }}>
                                                <span style={{ width: "50%" }} >{_strings.fiber}</span>
                                                <span style={{ width: "50%", textAlign: "right" }}>{recipeNutritrionalInfo?.fiber}</span>
                                            </div>
                                            <div style={{ display: "flex", margin: "5px 0" }}>
                                                <span style={{ width: "50%" }} >{_strings.protein}</span>
                                                <span style={{ width: "50%", textAlign: "right" }}>{recipeNutritrionalInfo?.protein}</span>
                                            </div>
                                            <div style={{ display: "flex", margin: "5px 0" }}>
                                                <span style={{ width: "50%" }} >{_strings.salt}</span>
                                                <span style={{ width: "50%", textAlign: "right" }}>{recipeNutritrionalInfo?.salt}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </TabContext>
                    </div>
                </div>
            {/* </div> */}
            </Row>
            // </DivMealImage>
        )
    }

    return render();
};

CardMenuDetail.defaultProps = defaultProps;

export default CardMenuDetail;