import { combineReducers } from "redux";
import { PlannificationDaysReducers, PlannificationStartDateReducers, PlannificationIsSaveReducers, PlannificationLoadingReducers } from "./days/reducers";

const PlannificationReducers = combineReducers({
	weeks: PlannificationDaysReducers,
	start_date: PlannificationStartDateReducers,
	is_save: PlannificationIsSaveReducers,
	loading: PlannificationLoadingReducers,
});

export default PlannificationReducers;