import { API_STATIC_DATA_LOADED, StaticDataActionTypes, StaticDataState } from "./types";

const InitialState: StaticDataState = {
    allergens: [],
    food_category_exclusions: []
};

export function StaticDataReducers(state: StaticDataState = InitialState, action: StaticDataActionTypes): StaticDataState {
    switch (action.type) {
        case API_STATIC_DATA_LOADED:
            return {
                ...state,
                allergens: action.allergens,
                food_category_exclusions: action.food_category_exclusions
            }
        default:
            return state;
    }
}