
// Page visibility changed

import { useEffect } from "react";
import { DeclareState, State } from "./State";

const hasDocument = typeof document !== 'undefined';

function addEventTo(element: Document | Window, evt: string, state: State<boolean>, valueSwitch: boolean){
	// let elementType = typeof element;
	useEffect(() => {
        if (Boolean(element.addEventListener)) {
			const handler = (arg: any) => {
				// console.log("PageFocus=" + valueSwitch + " (" + evt + ") from " + elementType);
				state.Update(valueSwitch);
			};

			// console.log("PageFocus subscribing " + evt + " on " + elementType);
			element.addEventListener(evt, handler);

			return () => {
				// console.log("PageFocus unsubscribing " + evt + " on " + elementType);
				element.removeEventListener(evt, handler);
			};
		}

		return () => {};
    }, []);
}

function addFocusTo(element: Document | Window, state: State<boolean>){
	addEventTo(element, "focus", state, true);
}

function addBlurTo(element: Document | Window, state: State<boolean>){
	addEventTo(element, "blur", state, false);
}

function addPageShowTo(element: Document | Window, state: State<boolean>){
	addEventTo(element, "pageshow", state, true);
}

function addPageHideTo(element: Document | Window, state: State<boolean>){
	addEventTo(element, "pagehide", state, false);
}

const usePageFocus = () => {
	const initiallyFocused: boolean = false;
	const _isFocused = DeclareState<boolean>(initiallyFocused);

	if(hasDocument) {
		addFocusTo(document, _isFocused);
		addBlurTo(document, _isFocused);
		addPageShowTo(document, _isFocused);
		addPageHideTo(document, _isFocused);
	}

	addFocusTo(window, _isFocused);
	addBlurTo(window, _isFocused);
	addPageShowTo(window, _isFocused);
	addPageHideTo(window, _isFocused);

    return _isFocused.content;
};

export default usePageFocus;
