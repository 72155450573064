import MessagesViewModel  from "src/components/bases/MessagesViewModel";
import { HANDLE_ERROR, HANDLE_SUCCESS, PageActionTypes, PageState, RESET_ERROR, RESET_MESSAGE } from "./types";

const InitialState: PageState = {
	ErrorCode: "",
	MessageCall: null,
	MessageDuration: 3000
};

export function GeneralAppReducer(state: PageState = InitialState, action: PageActionTypes) : PageState {
	switch(action.type) {
        case HANDLE_ERROR:
            return {
                ...state,
                ErrorCode: action.response.error_code,
				MessageCall: (message: MessagesViewModel) => message.Warning(action.message ? action.message : action.response.error_message ),   
                //TODO: add real Error = string
			}
		case HANDLE_SUCCESS:
			return {
				...state,
				MessageCall: action.message ? (message: MessagesViewModel) => message.Success(action.message ? action.message : "") : null,
				MessageDuration: action.duration
			}
        case RESET_ERROR:
            return {
                ...state,
                ErrorCode: ""
			}
		case RESET_MESSAGE :
			return {
				...state,
				MessageCall: null,
			}
		default:
			return state;
    }
}
