import { API_HOST } from "src/configuration/constants";
import ServiceLocator from "src/services/ServiceLocator";


export default class ApiClient {
    private _host: string;

    public constructor() {
        this._host = API_HOST;
    }

    public Get<T>(relativeUrl: string, authenticate: boolean = true): Promise<T> {
        return this.Send(relativeUrl, null, "GET", authenticate);
    }

    public Post<T>(relativeUrl: string, body: any, authenticate: boolean = true): Promise<T> {
        return this.Send(relativeUrl, body, "POST", authenticate);
    }

    public Put<T>(relativeUrl: string, body: any, authenticate: boolean = true): Promise<T> {
        return this.Send(relativeUrl, body, "PUT", authenticate);
    }

    public Patch<T>(relativeUrl: string, body: any, authenticate: boolean = true): Promise<T> {
        return this.Send(relativeUrl, body, "PATCH", authenticate);
    }

    public Delete<T>(relativeUrl: string, body: any, authenticate: boolean = true): Promise<T> {
        return this.Send(relativeUrl, body, "DELETE", authenticate);
    }

    private Send<T>(relativeUrl: string, body: any, method: string, authenticate: boolean = true): Promise<T> {
        // console.log("HTTP call " + method + " " + relativeUrl + " => " + new Date().toUTCString());
        let request = this.CreateRequest(method, relativeUrl, authenticate);
        this.SendRequest(request, body);

        return this.CreateResponsePromise<T>(request);
    }

    private CreateRequest(method: string, relativeUrl: string, authenticate: boolean = true): XMLHttpRequest {
        let request = new XMLHttpRequest();
        let url = this._host + relativeUrl;
        request.open(method, url, true);

        request.setRequestHeader("Accept", "application/json");
        // request.setRequestHeader('Access-Control-Allow-Origin', '*');
        // request.setRequestHeader("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
        // request.setRequestHeader("Access-Control-Allow-Methods", "POST, GET, PUT, DELETE, OPTIONS");
        // request.setRequestHeader('Access-Control-Allow-Credentials', 'true');

        if (method != "GET") {
            request.setRequestHeader("Content-Type", "application/json");
        }

        if (authenticate) {
            ServiceLocator.Authenticator.Authenticate(request);
        }

        return request;
    }

    private SendRequest(request: XMLHttpRequest, body: any = null) {
        if (body === null) {
            request.send();
        }
        else {
            request.send(JSON.stringify(body));
        }
    }

    private CreateResponsePromise<T>(request: XMLHttpRequest): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            request.onreadystatechange = () => {
                if (request.readyState == 4) {
                    // console.log("Response received for ")
                    // console.log(request.getResponseHeader("Date"));
                    var jsonResult;
                    if (request.status >= 200 && request.status < 300) {
                        try {
                            jsonResult = JSON.parse(request.responseText);
                        } catch (e) {
                            jsonResult = request;
                        }
                        resolve(jsonResult);
                    }
                    else if (request.status >= 400 && request.status < 500) {
                        var jsonResult = JSON.parse(request.responseText);
                        resolve(jsonResult);
                    }
                    else {
                        reject(request.status);
                    }
                }
            };
        });
    }
}
