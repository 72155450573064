import { ApiUserProfileActionTypes, API_USER_PROFILE_LOADED, UserProfileState } from "./types";

const InitialState: UserProfileState = {
	user: {
		email: "",
		first_name: "",
		last_name: "",
		guests: null,
		food_category_exclusions: [],
		week_dinner: null,
		week_lunch:  null,
		weekend_lunch:  null,
		weekend_dinner: null,
	},
	isLoaded: false,
};

export default function UserProfileReducer(state: UserProfileState = InitialState, action: ApiUserProfileActionTypes): UserProfileState {
	switch (action.type) {
		case API_USER_PROFILE_LOADED:
			return {
				...state,
				user: action.user,
				isLoaded: true
			}
		default:
			return state;
	}
}
