import { DeclareState, State } from "src/helpers/State";

export type MessageLevel = 'success' | 'info' | 'warning' | 'error';

interface MessagesState {
	open: boolean,
	message: string,
	type: MessageLevel
}

export default class MessagesViewModel {
	private _state: State<MessagesState>;

	constructor() {
		this._state = DeclareState<MessagesState>({
			message: "",
			open: false,
			type: "success"
		});
	}

	public Open(message: string, type: MessageLevel) {
		this._state.Update({
			message: message,
			open: true,
			type: type,
		});
	}

	public Success(message: string) {
		this.Open(message, "success");
	}

	public Info(message: string) {
		this.Open(message, "info");
	}

	public Warning(message: string) {
		this.Open(message, "warning");
	}

	public Error(message: string) {
		this.Open(message, "error");
	}

	public CloseHandler() {
		this._state.Update({
			open: false
		});
	}

	public IsOpen(): boolean {
		return this._state.content.open;
	}

	public Message(): string {
		return this._state.content.message;
	}

	public Type(): MessageLevel {
		return this._state.content.type;
	}
}
