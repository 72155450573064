export interface ConfigurationState {
    language: string
}

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

interface ChangeLanguageAction {
    type: typeof CHANGE_LANGUAGE,
    language: string
}

export type ConfigurationActionTypes = ChangeLanguageAction;
