import * as React from "react";
import { useDispatch } from "react-redux";
import { ExecuteOnLoad } from "src/helpers/Lifecycle";
import useStrings from "src/localization/Localization";
import { ApiGetUserProfile } from "src/stores/apis/user/actions";
import { RemovePlanningWeekStore } from "src/stores/plannification/days/actions";

import { Link } from "@mui/material";

import MenuButton from "src/components/bases/MenuButton";
import PlanningCalendarPicker from "src/components/bases/PlanningCalendarPicker";
import Footer from "src/components/bases/Footer";
import { RemoveMenu } from "src/stores/menu/actions";
import { ApiGetStaticData } from "src/stores/static_data/action";
import { useCheckMobileScreen } from "src/helpers/fonctions";
// import { RemoveRecipes } from "src/stores/recipes/action";


export interface DashboardPageProps { }

const DashboardPage: React.FunctionComponent<DashboardPageProps> = (props) => {
	const _dispatch = useDispatch();
	const _strings = useStrings();
	const mobileScreen = useCheckMobileScreen();

	ExecuteOnLoad(() => {
		_dispatch(ApiGetUserProfile());
		_dispatch(ApiGetStaticData());
		_dispatch(RemovePlanningWeekStore());
		_dispatch(RemoveMenu());
		// _dispatch(RemoveRecipes());
	}, true);

	function render(): JSX.Element {

		return (
			<div>
				<MenuButton selected={0}></MenuButton>

				<div style={{ display: 'flex', flexDirection: (mobileScreen ? "column": "row" )}}>
					<div style={{ width: (mobileScreen ? "inherit" : "40%") }}>
						<div className="head-text">
							<img src="/images/img_dashboard@3x.jpg"
								srcSet="/images/img_dashboard@2x.jpg 2x, img/img_dashboard@3x.jpg 3x"
								className="img_dashbord" />

							<span className='Planification-de-vos-menu'>
								{_strings.week_plannification}
							</span>
							<span className="Organisez-vos-semaines">
								{_strings.organize_week}
							</span>
							<span className='Besoin-daide'>
								<Link color="inherit">{_strings.need_help}</Link>
							</span>
						</div>

					</div>
					<div style={{ width: (mobileScreen ? "inherit" : "60%")}}>
						<div style={{ color: "#9c9393", textAlign: "center", fontWeight: "bold", marginTop: "32px" }}>
							{_strings.choose_your_start_date}
						</div>
						<div><PlanningCalendarPicker></PlanningCalendarPicker></div>
					</div>
				</div>

				<Footer></Footer>

			</div>
		);
	}

	return render();
};

export default DashboardPage;
