import * as React from "react";
import useStrings from "src/localization/Localization";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { Logout } from "src/stores/auth/actions";
import { RemovePlanningWeekStore } from "src/stores/plannification/days/actions";

import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import IconButtonSvgIcon from "src/components/bases/IconButtonSvgIcon";
// import SvgIcon from '@mui/material/SvgIcon';
// import { ReactComponent as CalendarSvgIcon } from './icons/calendar.svg';
// import CalendarSvgIcon from './icons/calendar.svg';

import {
	MapOutlined,
	// StarBorder,
	SettingsOutlined
} from "@mui/icons-material";

import LogoutIcon from "@mui/icons-material/Logout";

type MenuButtonProps = {
	selected?: number,
};

const MenuButton: React.FunctionComponent<MenuButtonProps> = (props) => {
	const _dispatch = useDispatch();
	const _strings = useStrings();
	let history = useHistory();

	// const CalendarSvgIcon = require('./icons/calendar.svg') as string;
	// const CalendarIcon2 = () => <CalendarSvgIcon />;
	// const CalendarIcon = <SvgIcon component={CalendarSvgIcon} viewBox="0 0 14 15" />

	const FoyerIcon = <IconButtonSvgIcon width={20} height={17} source="/images/menu_bar/foyer.svg" sourceHover="/images/menu_bar/foyer-hover.svg" />
	const FoyerIconSelected = <IconButtonSvgIcon width={20} height={17} source="/images/menu_bar/foyer-hover.svg" />
	const CalendarIcon = <IconButtonSvgIcon width={20} height={17} source="/images/menu_bar/calendar.svg" sourceHover="/images/menu_bar/calendar-hover.svg" />
	const CalendarIconSelected = <IconButtonSvgIcon width={20} height={17} source="/images/menu_bar/calendar-hover.svg" />
	const ShoppingIcon = <IconButtonSvgIcon width={20} height={17} source="/images/menu_bar/shopping-cart.svg" sourceHover="/images/menu_bar/shopping-cart-hover.svg" />
	const ShoppingIconSelected = <IconButtonSvgIcon width={20} height={17} source="/images/menu_bar/shopping-cart-hover.svg" />
	// const ParameterIcon = <IconButtonSvgIcon width={20} height={20} source="/images/menu_bar/settings.png"  sourceHover="/images/menu_bar/settings-hover.png" />
	// const ParameterIconSelected = <IconButtonSvgIcon width={20} height={20} source="/images/menu_bar/settings-hover.png" />

	// const CalendarIcon = (
	// 	<img alt="calendar" id="calendar-icon" src={CalendarSvgIcon} width="14px" height="15px" />
	// );

	// const CalendarIcon = (
	// 	<Icon>
	// 		<img alt="calendar" src="/images/calendar.svg" width="14px" height="15px" />
	// 	</Icon>
	// );

	const ButtonMenu = styled(Button)(({ theme }) => ({
		// width: 300,
		// color: theme.palette.success.main,
		// "& .MuiSlider-thumb": {
		// 	"&:hover, &.Mui-focusVisible": {
		// 		color: "#fd694d"
		// 		// boxShadow: `0px 0px 0px 8px ${alpha(
		// 		// 	theme.palette.success.main,
		// 		// 	0.16
		// 		// )}`,
		// 	},
		// 	// 	"&.Mui-active": {
		// 	// 		boxShadow: `0px 0px 0px 14px ${alpha(
		// 	// 			theme.palette.success.main,
		// 	// 			0.16
		// 	// 		)}`,
		// 	// 	},
		// },
		color: theme.palette.common.black,
		textTransform: "none",
		marginRight: "16px",
		"&:hover": {
			color: "#fd694d",
		},
	}));

	const menuOptions = [
		{
			name: _strings.plannification,
			icon: CalendarIcon,
			iconSelected: CalendarIconSelected,
			onClick: function () {
				history.push('/calendar');
			},
		},
		{
			name: _strings.menus,
			icon: <MapOutlined />,
			iconSelected: <MapOutlined />,
			onClick: function () {
				history.push('/menu');
			},
		},
		{
			name: _strings.foyer,
			icon: FoyerIcon,
			iconSelected: FoyerIconSelected,
			onClick: function () {
				history.push('/household');
			},
		},
		// {
		// 	name: _strings.favoris,
		// 	icon: <StarBorder />,
		// 	iconSelected: <StarBorder />,
		// 	onClick: function () {
		// 		history.push('/bookmarks');
		// 	},
		// },
		{
			name: _strings.courses,
			icon: ShoppingIcon,
			iconSelected: ShoppingIconSelected,
			onClick: function () {
				history.push('/shopping');
			},
		},
		{
			name: _strings.account_parameters,
			icon: <SettingsOutlined />,
			iconSelected: <SettingsOutlined />,
			onClick: function () {
				history.push('/account');
			},
		},
		{
			name: _strings.logout,
			color: "#9c9393",
			icon: <LogoutIcon />,
			iconSelected: <LogoutIcon />,
			onClick: handleLogoutClicked,
		},
	];

	function handleLogoutClicked(event: React.MouseEvent<HTMLButtonElement>) {
		event.preventDefault();
		_dispatch(RemovePlanningWeekStore());
		_dispatch(Logout());
	}

	function render(): JSX.Element {

		return (
			<div style={{ padding: "0.5% 0 0.5% 10%",/* padding: "6.4px 0px 4.6px 135.3px",*/ boxShadow: "0 2px 15px 1px rgba(0, 0, 0, 0.1)" }}>
				<div style={{ width: "25%", display: 'inline-block' }} onClick={() => history.push('/calendar')}>
					<img src="images/logo-menu-top@3x.jpg"
						srcSet="images/logo-menu-top@2x.jpg 2x,
						images/logo-menu-top@3x.jpg 3x"
						className="logo-menu-top" />
				</div>
				<div className="menuItems" style={{ display: 'inline-block' }}>
					{menuOptions.map((element, i) => {
						return (
							<ButtonMenu
								style={{ fontSize: "14px", color: (props.selected == i ? '#fd694d' : (element.color ? element.color : 'inherit')) }} key={i} variant="text" startIcon={props.selected == i ? element.iconSelected : element.icon} onClick={(e) => { element.onClick(e) }} >
								{element.name}
							</ButtonMenu>
						);
					})}
				</div>
			</div>
		);
	}

	return render();
};

export default MenuButton;
