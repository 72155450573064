import { ADD_PRODUCT_SHOPPING_LIST, REMOVE_PRODUCT_SHOPPING_LIST, Food, ShoppingListActionTypes, ACTIVE_PRODUCT_SHOPPING_LIST } from "./types";


export function AddProductsOnShoppingList(id_category: number, foods: Food[]/*food: Food*/) : ShoppingListActionTypes {
    return {
        type: ADD_PRODUCT_SHOPPING_LIST,
        id_category: id_category,
        // food: food
        foods: foods
    };
}

export function ActiveProductOnShoppingList(id_category: number, food_id: number) : ShoppingListActionTypes {
    return {
        type: ACTIVE_PRODUCT_SHOPPING_LIST,
        id_category: id_category,
        food_id: food_id,
    };
}

export function RemoveProductOnShoppingList(id_category: number, foods: Food[] /*id_food: number*/) : ShoppingListActionTypes {
    return {
        type: REMOVE_PRODUCT_SHOPPING_LIST,
        id_category: id_category,
        // id_food: id_food
        foods: foods
    };
}