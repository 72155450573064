
import GetUserProfileResponse from "src/api/dtos/user/GetUserProfileResponse";
import { GuestsProfileRequest } from "src/api/dtos/user/GuestProfileRequest";
import UserPreferencesRequest, { WeekProfile } from "src/api/dtos/user/UserPreferencesRequest";
import { GuestsProfile } from "src/api/dtos/user/UserProfile";
import { FromStore } from "src/helpers/Store";
import ServiceLocator from "src/services/ServiceLocator";
import { RefreshTokenIfNeeded, Logout } from "src/stores/auth/actions";
import { ResetError, HandleError, HandleSuccess } from "src/stores/page/actions";

import { AppDispatcher, AsyncAction } from "src/stores/types";
import { ApiUserProfileActionTypes, API_USER_PROFILE_LOADED } from "./types";

export function ApiGetUserProfile(): AsyncAction {
    return RefreshTokenIfNeeded(async dispatch => {

        let response = await ServiceLocator.UserProfileService.GetUserProfile();

        if (response.is_success) {
            dispatch(UserProfileLoaded(response));
            dispatch(HandleSuccess());
        }
        else {
            dispatch(Logout());
            dispatch(HandleError(response));
        }
    });
}

export function LoadUserProfileIfNotAvailable(dispatch: AppDispatcher): void {
    const isLoaded = FromStore(x => x.apis.profile.isLoaded)
    if (isLoaded == false) {
        dispatch(ApiGetUserProfile());
    }
}

export function UserProfileLoaded(response: GetUserProfileResponse): ApiUserProfileActionTypes {
    return {
        type: API_USER_PROFILE_LOADED,
        user: response.data
    };
}

export function ApiEditUserPreferences(foodExclusions?: number[], week_lunch?: WeekProfile | null, week_dinner?: WeekProfile | null, weekend_lunch?: WeekProfile | null, weekend_dinner?: WeekProfile | null): AsyncAction {
    return async dispatch => {
        dispatch(ResetError());

        const defaultData = {
            is_enabled: false,
            added_adults: 0,
            added_children: 0,
            has_starter: false,
            has_main: true,
            has_cheese: false,
            has_dessert: false,
            is_quick: false,
            is_economic: false,
            is_take_away: false,
            is_gourmet: false,
            guest_ids: [],
        } as WeekProfile;

        let request = {} as unknown as UserPreferencesRequest;
        request.food_category_exclusion_ids = foodExclusions ? foodExclusions : [];

        request.week_lunch = week_lunch ? week_lunch : defaultData;
        request.week_dinner = week_dinner ? week_dinner : defaultData;           
        request.weekend_lunch = weekend_lunch ? weekend_lunch : defaultData;
        request.weekend_dinner = weekend_dinner ? weekend_dinner : defaultData;

        let response = await ServiceLocator.UserProfileService.UpdateUserPreferences(request);
        if (response.is_success) {
            UserProfileLoaded(response);            
            dispatch(HandleSuccess());
        } else {
            dispatch(HandleError(response));
        }

    };
}

export function ApiAddGuest(dataGuest: GuestsProfile): AsyncAction {
    return async dispatch => {
        dispatch(ResetError());

        let request = {} as unknown as GuestsProfileRequest;

        request.picture_file = dataGuest.picture_file;
        request.first_name = dataGuest.first_name;
        request.last_name = dataGuest.last_name;
        request.gender = dataGuest.gender;
        request.birth_date = dataGuest.birth_date;
        request.height = dataGuest.height;
        request.weight = dataGuest.weight;
        request.physical_activity_level = dataGuest.physical_activity_level;
        request.group = dataGuest.group;
        request.is_pregnant = false;
        request.is_breast_feeding = false;
        request.allergen_ids = [];

        let response = await ServiceLocator.MyEatWellService.AddGuest(request);
        if (response.is_success) {
            ApiGetUserProfile();
            dispatch(HandleSuccess('Success to save ' + dataGuest.first_name + " " + dataGuest.last_name));
        } else {
            dispatch(HandleError(response));
        }

    };
}

export function ApiEditGuest(dataGuest: GuestsProfile, allergensList?: number[]): AsyncAction {
    return async dispatch => {
        dispatch(ResetError());

        let request = {} as unknown as GuestsProfileRequest;
        request.picture_file = dataGuest.picture_file;
        request.first_name = dataGuest.first_name;
        request.last_name = dataGuest.last_name;
        request.gender = dataGuest.gender;
        request.birth_date = dataGuest.birth_date;
        request.height = dataGuest.height;
        request.weight = dataGuest.weight;
        request.physical_activity_level = dataGuest.physical_activity_level;
        request.is_pregnant = dataGuest.is_pregnant;
        request.is_breast_feeding = dataGuest.is_breast_feeding;
        request.group = dataGuest.group;
        if (allergensList) {
            request.allergen_ids = allergensList;
        }

        let response = await ServiceLocator.MyEatWellService.EditGuest(request, dataGuest.id);
        if (response.is_success) {
            ApiGetUserProfile();
            dispatch(HandleSuccess('Success to save ' + dataGuest.first_name + " " + dataGuest.last_name));
        } else {
            dispatch(HandleError(response));
        }
    };
}

export function ApiDeleteGuest(id: number): AsyncAction {
    return async dispatch => {
        dispatch(ResetError());

        let response = await ServiceLocator.MyEatWellService.DeleteGuest(id);
        if (response.is_success) {
            dispatch(HandleSuccess('Convive ' + id +' supprimé avec succès'));
        } else {
            dispatch(HandleError(response));
        }
    };
}