export const ADD_PRODUCT_SHOPPING_LIST = "ADD_PRODUCT_SHOPPING_LIST";
export const ACTIVE_PRODUCT_SHOPPING_LIST = "ACTIVE_PRODUCT";
export const REMOVE_PRODUCT_SHOPPING_LIST = "REMOVE_PRODUCT_SHOPPING_LIST";

export interface Food {
    id: number,
    name: string,
    quantity: number,
    is_in_shopping_list: boolean,
    is_manually_added: boolean,
}

export interface ShoppingListState {
    [id_category: number]: {
        foodsManually: Food[],
        foods: Food[],
    }
}
interface AddProductShoppingListAction {
    type: typeof ADD_PRODUCT_SHOPPING_LIST,
    id_category: number,
    // food: Food,
    foods: Food[],
}

interface ActiveProductShoppingListAction {
    type: typeof ACTIVE_PRODUCT_SHOPPING_LIST,
    id_category: number,
    food_id: number
}

interface RemoveProductShoppingListAction {
    type: typeof REMOVE_PRODUCT_SHOPPING_LIST,
    id_category: number,
    // id_food: number,
    foods: Food[],
}

export type ShoppingListActionTypes = AddProductShoppingListAction | ActiveProductShoppingListAction | RemoveProductShoppingListAction;