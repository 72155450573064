import * as React from 'react';
import Avatar from '@mui/material/Avatar';

type AvatarLetterProps = {
    fullName: string,
    className?: string,
    variant?: "circular" | "rounded",
    style?: {width: string, height: string},
    onClick?: (e:any) => void,
}

const InitialProps: AvatarLetterProps = {
    fullName: "",
    className: "avatar_inactive",
    variant: "circular"
}


const AvatarLetter: React.FunctionComponent<AvatarLetterProps> = props => {

    function stringToColor(string: string) {
        let hash = 0;
        let i;
    
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
    
        let color = '#';
    
        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.substr(-2);
        }
        /* eslint-enable no-bitwise */
    
        return color;
    }

    function stringAvatar(name: string) {        
        let nameTmp = name.split(' ').filter(n => n);
        let finalName = nameTmp[0][0];
        if(nameTmp[1]) {
            finalName += nameTmp[1][0];
        }

        return {
            sx: {
                bgcolor: stringToColor(name),
                display: "inline-flex",
                margin: "2px 2px",
                width: props.style ? props.style.width : "30px",
                height: props.style ? props.style.height : "30px"
            },
            children: finalName,
        };
    }

    function getInitiale(name: string) {
        let nameTmp = name.split(' ').filter(n => n);
        let finalName = nameTmp[0][0];
        if(nameTmp[1]) {
            finalName += nameTmp[1][0];
        }
        return finalName;
    }

    function render(): JSX.Element {
        return (
            <Avatar title={props.fullName} alt={getInitiale(props.fullName)} aria-label={props.fullName} className={ (props.className != undefined) ? props.className : InitialProps.className} {...stringAvatar(props.fullName)} onClick={props.onClick} variant={props.variant} />
        );
    }

    return render();
};

export default AvatarLetter;

