import * as React from 'react';
import MessagesViewModel from "./MessagesViewModel";
import useStrings from "src/localization/Localization";

import { Snackbar, SnackbarCloseReason, AlertProps } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import ErrorCode from 'src/api/dtos/ErrorCode';

export interface MessagesProps {
	state: MessagesViewModel;
	duration?: number;
	error_code?: ErrorCode | string;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref,) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Messages: React.FunctionComponent<MessagesProps> = props => {
	const _strings = useStrings();

	function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
		return (
			<div role="alert">
				<p>Something went wrong:</p>
				<pre style={{ color: 'red' }}>{error.message}</pre>
				<button onClick={resetErrorBoundary}>Try again</button>
			</div>
		)
	}

	function onClose(
		event: React.SyntheticEvent<any>,
		reason: SnackbarCloseReason
	): void {
		if (reason === "clickaway") {
			return;
		}
		props.state.CloseHandler();
	}

	function onAlertClose(event: React.SyntheticEvent<any>) {
		props.state.CloseHandler();
	}

	function render(): JSX.Element {
		if (props.state.IsOpen()) {
			return (
				<ErrorBoundary FallbackComponent={ErrorFallback}>
					<Snackbar
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						open={props.state.IsOpen()}
						autoHideDuration={props.duration ?? 6000}
						onClose={onClose}
					>
						
						<Alert onClose={onAlertClose} severity={props.state.Type()}>
							{_strings.tr( props.error_code ? props.error_code : props.state.Message())}
						</Alert>
					</Snackbar>
				</ErrorBoundary>
			);
		}

		return <div />;
	}

	return render();
};

export default Messages;
