import EmptyApiResponse from "src/api/dtos/EmptyApiResponse";
import MessagesViewModel from "src/components/bases/MessagesViewModel";

export interface PageState {
	ErrorCode: string,
	MessageCall: null | ((message: MessagesViewModel) => void),
	MessageDuration: number,
}

export const HANDLE_ERROR = "HANDLE_ERROR";
export const HANDLE_SUCCESS = "HANDLE_SUCCESS";
export const RESET_ERROR = "RESET_ERROR";
export const RESET_MESSAGE = "RESET_MESSAGE";


interface HandleErrorAction {
    type: typeof HANDLE_ERROR,
    response: EmptyApiResponse,
	message: string | undefined,
}

interface HandleSuccessAction {
	type: typeof HANDLE_SUCCESS,
	duration: number,
	message?: string,
}

interface ResetErrorAction {
    type: typeof RESET_ERROR,
}

interface ResetMessageAction {
	type: typeof RESET_MESSAGE
}


export type PageActionTypes =
	HandleErrorAction |
	ResetErrorAction |
	HandleSuccessAction |
	ResetMessageAction;
