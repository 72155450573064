import * as React from "react";
import * as DateFns from 'date-fns';
import { FromStore } from "src/helpers/Store";
import { useHistory } from "react-router-dom";
import useStrings from "src/localization/Localization";
import { useDispatch } from "react-redux";
// import { ExecuteOnLoad } from "src/helpers/Lifecycle";

import { Row, Col, Card } from "react-bootstrap";
import { IconButton, Avatar } from '@mui/material';

import { ApiEditUserPreferences, ApiGetUserProfile } from "src/stores/apis/user/actions";
import ButtonCustom from "src/components/bases/ButtonCustom";
import MenuButton from "src/components/bases/MenuButton";
import Footer from "src/components/bases/Footer";
import CardPlanningDay from "src/components/bases/CardPlanningDay";
import { useCheckMobileScreen } from "src/helpers/fonctions";

export interface EatingHabitsPageProps { }

const EatingHabitsPage: React.FunctionComponent<EatingHabitsPageProps> = (props) => {

    const _staticData = FromStore(state => state.static_data);
    const _user = FromStore(state => state.apis.profile.user);
    const _persons = FromStore(state => state.apis.profile.user.guests);

    const _dispatch = useDispatch();
    let history = useHistory();
    const _strings = useStrings();
    const mobileScreen = useCheckMobileScreen();

    // const delay = (ms: number | undefined) => new Promise(res => setTimeout(res, ms));

    const [activeExclusion, setActiveExclusion] = React.useState([] as boolean[]);

    React.useEffect(() => {

        setTimeout(function () {
            _dispatch(ApiGetUserProfile());
            const data = window.sessionStorage.getItem('activeExclusion');

            if(activeExclusion.length == 0 || data?.length == 0) {

                let activeExclusionList = new Array();
                // [..._staticData.food_category_exclusions].map((val: any, index: any) => {
                //     activeExclusionList[val.id] = false;
                    [..._user.food_category_exclusions].map((valFoyerExclusion: any, indexFoyerExclusion: any) => {                    
                        activeExclusionList[valFoyerExclusion.id] = true;
                    })
                // });
                                
                if(activeExclusionList.length > 0) {
                    setActiveExclusion(activeExclusionList);
                    window.sessionStorage.setItem('activeExclusion', JSON.stringify(activeExclusionList));
                }
            }
        }, 2000);

        const data = window.sessionStorage.getItem('activeExclusion');
        if ( data !== null ) {
            setActiveExclusion(JSON.parse(data));
        }
        
    }, []);

    // function loadFromLocalStorage() {
    //     try {
    //         const stateStr = window.sessionStorage.getItem('activeExclusion');
    //         return stateStr ? JSON.parse(stateStr) : undefined;
    //     } catch (e) {
    //         console.error(e);
    //         return undefined;
    //     }
    // };

    // ExecuteOnLoad(() => {
    //     _dispatch(ApiGetUserProfile());

    //     if(activeExclusion.length == 0) {
    //         let activeExclusionList = new Array();
    //         [..._staticData.food_category_exclusions].map((val: any, index: any) => {
    //             activeExclusionList[val.id] = false;
    //             [..._user.food_category_exclusions].map((valFoyerExclusion: any, indexFoyerExclusion: any) => {
    //                 if (valFoyerExclusion.id === val.id) {
    //                     activeExclusionList[val.id] = true;
    //                 }
    //             })
    //         });
    //         setActiveExclusion(activeExclusionList);
    //         window.sessionStorage.setItem('activeExclusion', JSON.stringify(activeExclusionList));
    //     }  
    // }, false);

    const handleExclusionClicked = (event: React.SyntheticEvent) => {
        event.preventDefault();

        let exlusionId: number = Number(event.currentTarget.id);

        // Set data
        let active = activeExclusion;
        active[exlusionId] = !active[exlusionId];     
        setActiveExclusion(active);        

        // Save
        let exclusionToSave: number[] = [];
        [...active].map((val: any, index: any) => {
            if (val == true) {
                exclusionToSave.push(index);
            }
        });

        _dispatch(ApiEditUserPreferences(exclusionToSave.filter(e => e), _user.week_lunch, _user.week_dinner, _user.weekend_lunch, _user.weekend_dinner ));

        window.sessionStorage.setItem('activeExclusion', JSON.stringify(active));

        _dispatch(ApiGetUserProfile());
    }

    function render(): JSX.Element {

        return (
            <div>
                <MenuButton selected={2}></MenuButton>

                <div className="marginPages" >
                    <Row md={2} style={{ alignItems: "flex-start" }} >
                        <Col style={{ width: mobileScreen ? "100%" : "30%" }}>
                            <Card style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "2px", padding: "25px", boxShadow: "0 2px 15px 1px rgba(0, 0, 0, 0.03)" }}>

                                <Card.ImgOverlay style={{ padding: 0, left: '88%' }}>
                                    <IconButton style={{ borderRadius: '2px', margin: '5px', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                                        <img width="14px" height="14px" src="/images/edit_profile.png" />
                                    </IconButton>
                                </Card.ImgOverlay>

                                <div>
                                    <Avatar
                                        alt="Family"
                                        style={{ width: "140px", height: "140px" }}
                                        src="/images/foyer/foyer.jpg"
                                    ></Avatar>
                                </div>

                                <Row md={6} style={{ cursor: "pointer", justifyContent: "center", padding: 0, margin: "15px 8%", width: "-webkit-fill-available" }} >
                                    <span className="guestGroupButtonActive">{_strings.foyer}</span>
                                </Row>

                                <div style={{ color: "#9c9393" }}>{_strings.favourite_recipes}</div>
                                <div style={{ color: "#9c9393" }}>{_strings.validated_weeks}</div>
                                <div>
                                    {
                                        _persons ?
                                            [..._persons].map((guestInfo: any, index: any) => {
                                                return (
                                                    <div key={index} style={{ margin: "15px 0px" }}>
                                                        <span style={{ width: "100px" }}>{guestInfo.first_name} {guestInfo.last_name}</span>
                                                        <span style={{ backgroundColor: "#e3e1e1", padding: "8px 10px", marginLeft: "12px" }}>{_strings.born_on} {DateFns.format(new Date(guestInfo.birth_date), "dd/MM/yyyy")}</span>
                                                    </div>
                                                );
                                            })
                                            : null

                                    }
                                </div>
                            </Card>
                        </Col>

                        <Col style={{ width: "69%" }}>
                            <Card style={{ borderRadius: "2px", boxShadow: "0 2px 15px 1px rgba(0, 0, 0, 0.03)" }}>
                                <Card.Title as="span" style={{ textAlign: "center", marginTop: "3%", fontSize: "initial", fontWeight: "bold" }}>{_strings.foods_exclusions}</Card.Title>
                                <Card.Text as="span" >
                                    <Row xs={mobileScreen ? 2 : undefined} md={mobileScreen ? undefined : 8} style={{ margin: 0, padding: "2%" }} >
                                        {
                                            [..._staticData.food_category_exclusions].map((val: any, index: any) => {
                                                return (
                                                    <div key={index} style={{ marginBottom: "2%" }}>
                                                        <div id={val.id} style={{ width: "60px", height: "60px" }} className={activeExclusion[val.id] == true ? "allergen_circle allergen_circle_blocked" : "allergen_circle"} onClick={handleExclusionClicked}>
                                                            <div style={activeExclusion[val.id] == true ? { width: "60px", top: "25px" } : undefined} className={activeExclusion[val.id] == true ? "blocked" : undefined}></div>
                                                            <div className="allergen_label"><img width="30px" height="30px" src={`/images/foyer/exclusions/${val.id}@3x.png`} /></div>
                                                        </div>
                                                        <div className="allergen_label" style={{ marginTop: "10px" }} >{val.name}</div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </Row>
                                </Card.Text>
                            </Card>

                            <Row md={2} style={{ margin: 0, padding: "2%" }} >
                            <Col>
                                <Row style={{ margin: 0, padding: "2%"}} >
                                <Card.Title as="span" style={{ textAlign: "center", width:"100%" }}> {_strings.menu_type_week} </Card.Title>
                                    <CardPlanningDay from={"eatingHabits"} dayName={ `${_strings.Lunch} ${_strings.week.toLowerCase()}` } headerBgColor="#E3E1E1" dayNumber={1} weekNumber={1} typeLunch={"week_lunch"} />
                                    <CardPlanningDay from={"eatingHabits"} dayName={`${_strings.Dinner} ${_strings.week.toLowerCase()}`} headerBgColor="#E3E1E1" dayNumber={1} weekNumber={1} typeLunch={"week_dinner"} />
                                </Row>
                            </Col>
                            <Col>
                                <Row style={{ margin: 0, padding: "2%" }} >
                                    <Card.Title as="span" style={{ textAlign: "center", width:"100%" }}> {_strings.menu_type_weekend} </Card.Title>
                                    <CardPlanningDay from={"eatingHabits"} dayName={`${_strings.Lunch} ${_strings.week_end}`} headerBgColor="#E3E1E1" dayNumber={6} weekNumber={1} typeLunch={"weekend_lunch"} />
                                    <CardPlanningDay from={"eatingHabits"} dayName={`${_strings.Dinner} ${_strings.week_end}`} headerBgColor="#E3E1E1" dayNumber={6} weekNumber={1} typeLunch={"weekend_dinner"} />
                                </Row>
                            </Col>
                            </Row>
                        </Col>


                    </Row >

                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <ButtonCustom style={{ backgroundColor: "#8fc740" }} onClick={() => { history.push('/household') }} label={_strings.back_to_household}></ButtonCustom>
                    </div>
                </div>

                <Footer></Footer>
            </div>
        );
    }

    return render();
};

export default EatingHabitsPage;