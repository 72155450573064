import { useState } from "react";

export class State<T>
{
    content: T;
    private setter: React.Dispatch<React.SetStateAction<T>>;

    private constructor(content: T, setter: React.Dispatch<React.SetStateAction<T>>) {
        this.content = content;
        this.setter = setter;
    }

    public static From<T>(input: [T, React.Dispatch<React.SetStateAction<T>>]): State<T> {
        return new State<T>(input[0], input[1]);
    }

    public Update(newPartialState: Partial<T>) {
		if(typeof newPartialState === 'object') {
			this.setter({
				...this.content,
				...newPartialState
			});
		}
		else {
			this.setter(newPartialState);
		}
    }
}

export function DeclareState<T>(initialState: T): State<T> {
    return State.From<T>(useState<T>(initialState));
}
