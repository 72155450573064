import ServiceLocator from "src/services/ServiceLocator";
import { AuthActionTypes, AuthState, AUTH_LOGGED_IN, AUTH_LOGGED_OUT, AUTH_REMEMBER_ME } from "./types";
import { ResetActionTypes, RESET_PASSWORD, FORGOT_PASSWORD } from "./types";
const InitialState: AuthState = {
    IsLogged: false,
    RefreshToken: "",
    AccessToken: "",
    ExpirationDate: new Date(),
    TokenType: "",
    RememberedLogin: "",
    IsReset: false,
    IsForgot: false
};

export function AuthReducer(state: AuthState = InitialState, action: AuthActionTypes | ResetActionTypes) : AuthState {
    switch(action.type) {
		case AUTH_LOGGED_IN:
			ServiceLocator.Authenticator.UseTokens(action.tokenType, action.accessToken)
            return {
                ...state,
                AccessToken: action.accessToken,
                RefreshToken: action.refreshToken,
                ExpirationDate: action.expirationDate,
                TokenType: action.tokenType,
                IsLogged: true
            }
        case AUTH_LOGGED_OUT:
            return {
                ...state,
                IsLogged: false,
                AccessToken: "",
                RefreshToken: "",
                ExpirationDate: new Date(),
                TokenType: ""
            };
        case AUTH_REMEMBER_ME:
            return {
                ...state,
                RememberedLogin: action.login
			};
        case RESET_PASSWORD:
                return {
                    ...state,
                    IsLogged: false,
                    IsForgot: false,
                    IsReset: action.reset
                };
        case FORGOT_PASSWORD:
            return {
                ...state,
                IsLogged: false,
                IsForgot: action.forgot,
                IsReset: false
            };                            
		default:
            state.IsReset = false;
            // return {
            //     state,
            //     IsReset: false
            // };
            return state;
    }
}
