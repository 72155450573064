import LoginResponse from "src/api/dtos/auth/LoginResponse";
import Urls from "src/configuration/constants";
import ServiceLocator from "./ServiceLocator";
import LoginRequest from "src/api/dtos/auth/LoginRequest";
import RefreshRequest from "src/api/dtos/auth/RefreshRequest";
import EmptyApiResponse from "src/api/dtos/EmptyApiResponse";
import ForgotPasswordRequest from "src/api/dtos/auth/ForgotPasswordRequest";
import ResetPasswordRequest from "src/api/dtos/auth/ResetPasswordRequest";
import ChangePasswordRequest from "src/api/dtos/auth/ChangePasswordRequest";

export default class AuthService {
    
    public Login(request: LoginRequest): Promise<LoginResponse> {
        return ServiceLocator.ApiClient.Post<LoginResponse>(Urls.LOGIN_URL, request, false);
    }

    public Refresh(request: RefreshRequest): Promise<LoginResponse> {
        return ServiceLocator.ApiClient.Post<LoginResponse>(Urls.REFRESH_URL, request, false);
    }

    public ForgotPassword(request: ForgotPasswordRequest): Promise<EmptyApiResponse> {
        return ServiceLocator.ApiClient.Post<LoginResponse>(Urls.RESET_PASSWORD_URL, request, false);
    }

    public ResetPassword(request: ResetPasswordRequest): Promise<EmptyApiResponse> {
        return ServiceLocator.ApiClient.Patch<LoginResponse>(Urls.RESET_PASSWORD_URL, request, false);
    }

    public ChangePassword(request: ChangePasswordRequest): Promise<EmptyApiResponse> {
        return ServiceLocator.ApiClient.Patch(Urls.UPDATE_PASSWORD_URL, request);
    }
}